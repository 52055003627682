/**
 * Objet Paramètre renvoyé par l'api Taylorim
 */
export class Parametre {

  constructor(
    public nomParam : string,            // Identifiant du paramètre
    public valeur: string = "",          // Valeur du paramètre
    public commentaire: string = "",     // Commentaire du paramètre
    // Champs calcules non renvoyé par l'api
    public specifique: string = "",
    public touche: string = "",          // Pour touche de raccourci
    public tooltip: string = "",          // Pour aide
    public services: any[5] = ["", "", "", "",""],     // Liste des servives chargés paramétré dans valeur
    public fonctions: string[] = ["", "", "", "", ""], // Liste des nom de fonction correspondant au service
  ) {}

}