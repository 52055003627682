<div #div1 class="col-md-12 zones-saisies">
  <div class="row">
    <div class="col-md-6">
      <app-bouton 
        [btnClass]="clsBtnRecherche()" 
        [iconClass]="'d-inline, icon-map'" 
        [libelle]="'[F10] Rechercher'" 
        (clic)="onRechercherClic($event)" 
        [tooltip]="'Rechercher le compte par le nom'"
      ></app-bouton>
      <app-recherche-popup
        #recherchePopup
        [estAffiche]="false"
        [siteId]="siteId"
        [traitementId]="traitementId"
        [atelier]="atelier"
        [mapParamWdx]="mapParamWdx"
        [mapListe]="mapCentresRegatte"
        (selection)="onRechSelection($event)"
        (action)="onRechAction($event)"
      ></app-recherche-popup>
      <app-menu-popup
        [posX]="divPosX"
        [posY]="90"
        [mapOptions]="mapCentres"
        [afficher]="afficherCodesCentres"
        (select)="onCentresSelect($event)"
      ></app-menu-popup>
    </div>
    <div class="col-md-3 champ-compte ">
      <p class="libelle">No Compte</p>
      <input type="text" 
        #inputSaisie1
        name="saisie1" 
        class="form-control alignement espacement largeur" 
        autofocus="true"
        tabindex="0"
        mask="LLLLLLLLLLLL"
        [patterns]="comptePatterns"
        [(ngModel)]="saisie1"
        (ngModelChange)="majuscule($event)"
        [maxLength]="12"
        inputMaskDirective
        [anticipation]="atelier.anticipation"
        (yaSaisie)="yaSaisie()"
        (imageSuivante)="onImageSuivante($event)"
        (valider)="onTraiterEntree($event)"
        (echappe)="onTraiterEchappe($event)"
        (slash)="onTraiterSlash($event)"
        (espace)="onTraiterEspace($event)"
        (f1)="onTraiterF1($event)"
        (f4)="onTraiterF4($event)"
        (f5)="onTraiterF5($event)"
        (f10)="onTraiterF10($event)"
        (f12)="onTraiterF12($event)"
        (flecheHaut)="onTraiterFlecheHaut($event)"
        (flecheBas)="onTraiterFlecheBas($event)"
        [ngbTooltip]="cptToolTip"
        tooltipClass="tooltip-saisie"
        triggers="hover"
      />
    </div>
    <ng-template #cptToolTip>
      Compte sous forme numérique ou alpha, La saisie du centre en trois lettres est acceptée.<br> Espace pour afficher la liste des centres
    </ng-template>
  </div>

</div>
