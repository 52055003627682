import { Component, ComponentRef, Injector, Input, OnInit, Type, ViewChild, ViewContainerRef, AfterViewInit, Renderer2, ElementRef, Output, EventEmitter } from '@angular/core';
import { HistoriqueComponent } from "../../autres/historique/historique.component";
import { ImageStdComponent } from "../../autres/image-std/image-std.component";
import { SaisieMontantStdComponent } from '../../saisies/saisie-montant-std/saisie-montant-std.component';
import { Atelier } from '../../../../../models/Atelier.model';
import { DynamiqueService } from '../../../../../services/dynamique.service';
import { ApiImage } from '../../../../../models/ApiImage.model';
import { WebIndexService } from '../../../../../services/web-index.service';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { Logger } from '../../../../../models/Logger.model';
import { BoutonComponent } from "../../../../bouton/bouton.component";
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { catchError, finalize, noop, of } from 'rxjs';
import { DialoguesService } from '../../../../dialogues/dialogues.service';
import { Router } from '@angular/router';
import { IMAGE_ANNULE, IMAGE_COURANTE, IMAGE_SUIVANTE, NOM_PROJET, OPERATEUR } from '../../../../../constantes';
import { BoutonInfoModel } from '../../../../../models/BoutonInfo.model';
import { StandardSaisiesService } from '../../standard-saisies.service';



/**
 * Composant pour ateliers 3 zones, avec des boutons dans la zone de saisie
 */
@Component({
    selector: 'app-atl-trois-zones-btn',
    standalone: true,
    templateUrl: './atl-trois-zones-btn.component.html',
    styleUrl: './atl-trois-zones-btn.component.scss',
    providers: [],
    imports: [
      NgbTooltip,
      BoutonComponent
    ]
})
export class AtlTroisZonesBtnComponent implements OnInit, AfterViewInit {
  /** id du site choisi */
  @Input({required: true}) siteId: string = "";
  /** id du traitement choisi */
  @Input({required: true}) traitementId: string = "";
  /** id de l'atelier choisi */
  @Input({required: true}) atelier!: Atelier;
  /** Date de traitement choisi yyyyMMdd */
  @Input({required: true}) dateTraitement: string = "";   
  /** Evenement changemnet d'atelier */
  @Output() changerAtelier: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour mise à jour du stock */
  @Output() afficherStock: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour decrementer le stock */
  @Output() decStock: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour demander le stock réel restant */
  @Output() getStockReel: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('historiqueTpl', { read: ViewContainerRef, static: true})
  historiqueView!: ViewContainerRef;
  historiqueRef!: ComponentRef<any> | null;
  @ViewChild('imageTpl', { read: ViewContainerRef, static: true})
  imageView!: ViewContainerRef;
  imageRef!: ComponentRef<any> | null;
  @ViewChild('zoneSaisieTpl', { read: ViewContainerRef, static: true})
  zoneSaisieView!: ViewContainerRef;
  zoneSaisieRef!: ComponentRef<any> | null;
  @ViewChild('zoneBoutonTpl', { read: ViewContainerRef, static: true})
  zoneBoutonView!: ViewContainerRef;
  zoneBoutonRef!: ComponentRef<any> | null;

  logger: Logger = new Logger(this);
  image: ApiImage | null = null;  // Image en cours de saisie
  imageSuivante: ApiImage | null = null;  // Image suivante préchargée
  modeSaisie: string = "";  // M pour modification
  message: string = "";
  /** Compteur d'image saisi avant de lancer requete stock */
  cptSaisie: number = 0;

  /** Service chargé dynamiquement pour initialisation boutons */
  initService: any;
  /** Fonction de traitement d'initialisation des boutons */
  initFn: string = "";
  /** Service chargé dynamique pour gestion des boutons */
  boutonService: any;
  /** Fonction de traitement des clic sur boutons */
  boutonFn: string = "";
  /** Service chargé dynamiquement pour gestion enregistrement */
  enregService: any;
  /** Fonction de traitement de l'enregiqtrement de la saisie */
  enregFn: string = "";

  constructor(
    private router: Router,
    private dialoguesService: DialoguesService,
    private element: ElementRef,
    private dynamiqueService: DynamiqueService,
    private webIndexService: WebIndexService,
    private fonctionsService: FonctionsService,
    private stdSaisieService: StandardSaisiesService,
  ) {
  }

  ngOnInit() {
    this.logger.info("ngOnInit");
    // Préchargement du service init
    let srvFn = this.prechargerSrvFn("atelier.fonction_init", this.atelier.fonctionInit, "rienAFaire");
    if(!srvFn.service) {
      return;
    } else {
      this.initService = srvFn.service;
      this.initFn = srvFn.fonction;
    }

    // Préchargement du service bouton clic
    srvFn = this.prechargerSrvFn("atelier.fonction1", this.atelier.fonction1, "rienAFaire");
    if(!srvFn.service) {
      return;
    } else {
      this.boutonService = srvFn.service;
      this.boutonFn = srvFn.fonction;
    }
    // Préchargement du service d'enregistrement
    srvFn = this.prechargerSrvFn("atelier.fonction_enreg", this.atelier.fonctionEnreg, "EnregistrerSaisie1");
    if(!srvFn.service) {
      return;
    } else {
      this.enregService = srvFn.service;
      this.enregFn = srvFn.fonction;
    }

    this.historiqueRef = this.dynamiqueService.chargerComposant(this.atelier.param1, this.historiqueView);
    this.imageRef = this.dynamiqueService.chargerComposant(this.atelier.param2, this.imageView);
    this.imageRef!.instance.atelier = this.atelier;

    this.zoneSaisieRef = this.dynamiqueService.chargerComposant(this.atelier.param3, this.zoneSaisieView);
    if(this.zoneSaisieRef != null) {
      this.zoneSaisieRef.instance.siteId = this.siteId
      this.zoneSaisieRef.instance.traitementId = this.traitementId
      this.zoneSaisieRef.instance.atelier = this.atelier;
    }
    this.zoneSaisieRef!.instance.onSlash.subscribe((event: string) => {
      this.historiqueRef?.instance.clicSurDernierElement();
    });

    this.zoneBoutonRef = this.dynamiqueService.chargerComposant(this.atelier.param4, this.zoneBoutonView);
    if(this.zoneBoutonRef != null) {
      this.zoneBoutonRef.instance.siteId = this.siteId
      this.zoneBoutonRef.instance.traitementId = this.traitementId
      this.zoneBoutonRef.instance.atelier = this.atelier;
    }

  }

  prechargerSrvFn(srvFnNom: string, srvFn: string, fnDefaut: string) {
    const { service: service, fonction: fonction } = this.fonctionsService.litParametreDeService(srvFn, fnDefaut);
    const res = this.fonctionsService.testServiceFonction(
        srvFnNom + "=" + srvFn,
        service, fonction
    );
    if(!res.res) {
      setTimeout(() => { 
        this.affErreur(res.err)
      }, 0 );
      return {
        service: null,
        fonction: ""
      };
    }
    return { 
      service: service,
      fonction: fonction
    }
  }


  ngAfterViewInit(): void {
    this.logger.info("ngAfterViewInit()");
    this.afficherStock.emit(true);
    this.chargerImageSuivante(0, 
      (image: ApiImage) => {
        this.logger.info("ngAfterViewInit chargerImageSuivante callback", image);
        this.image = image;
        this.image!.saisieDate = this.fonctionsService.maintenant();
        this.imageRef!.instance.image = this.image;
        this.specifiqueApresChargement(IMAGE_COURANTE);
        const element = this.element.nativeElement.querySelector('[name="saisie1"]');
        element.focus();
      },
      (error: any) => {
        //this.stdSaisieService.afficherPlusdImageStd(this);
        this.stdSaisieService.afficherErreurApiStd(this, error, "Aucune image à vidéocoder");
      }
    );   
    this.historiqueRef!.instance.onElementClick.subscribe((id: number) => {
      this.saisieModifier(id);
    });

    this.zoneSaisieRef!.instance.onImageChange.subscribe((bSuivante: boolean) => {
      if(bSuivante) {
        this.changerImage(IMAGE_SUIVANTE);
      } else {
        this.imageRef!.instance.image = this.image;
      }
    });
    this.zoneSaisieRef!.instance.onValidation.subscribe((ok: boolean) => {
      this.enregistrerSaisie();
    });
    this.zoneSaisieRef!.instance.onMessage.subscribe((message: string) => {
      this.message = message;
    });
    this.zoneSaisieRef!.instance.onEchappe.subscribe((event: string) => {
      this.changerAtelier.emit(true);
    });

    this.zoneBoutonRef!.instance.boutonClic.subscribe((event: BoutonInfoModel) => {
      this.boutonService[this.boutonFn](this, event);
    });
  }

  /**
   * Clic sur bouton Image suivante
   * @param event 
   */
  onClic(event: MouseEvent) {
    this.stdSaisieService.imgSuivOnClicStd(this, event);
  }

  /**
   * Demande de la prochaine image libre
   * @param callBackImageOk Callback de traiement de l'image reçue
   */
  chargerImageSuivante(exclusion: number, callBackImageOk: Function, callBackImageKo: Function) {
    this.stdSaisieService.chargerImageSuivanteStd(this, exclusion, callBackImageOk, callBackImageKo);
  }

  /**
   * Changement de l'image
   * @param mode 0 = image courante, 1 = imageSuivante, 3 = abandon courante
   */
  changerImage(mode: number) {
    this.stdSaisieService.changerImageStd(this, mode);
  }

  /**
   * Enregistrement de la saisie
   */
  enregistrerSaisie() {
    this.logger.info("enregistrerSaisie");
    this.message = this.enregService[this.enregFn](this);
    this.cptSaisie ++;
    if(this.cptSaisie >= 10) {  // Rafraichissement du stock
      this.getStockReel.emit(true);
      this.cptSaisie = 0;
    }
  }

  /**
   * Callback appelé quand l'enregistrement échoue
   * @param err Callback en cas d'enregistrement ko
   */
  callBackEnregKo(err: any) {
    this.stdSaisieService.callBackEnregKoStd(this, err);
  }

  /**
   * Callback appelé quand l'enregistrement réussi
   * @param image 
   */
  callBackEnregOk(image: ApiImage) {  // TODO image vient d'ù
    this.stdSaisieService.callBackEnregOkStd(this);
  }

  /**
   * Gestion d'une demande de modification d'une image saisie
   * @param id id de l'image à modifier
   */
  saisieModifier(id: number) {
    this.stdSaisieService.saisieModifierStd(this, id);
  }
  /** 
   * Action après libération de l'image préchargée
   * @param id id de l'image à modifier
  */
  saisieModifier2(id: number) {
    this.stdSaisieService.saisieModifier2Std(this, id);
  }

  /**
   * Action après libération de l'image en cours
   * @param id id de l'image à modifier
   */
  saisieModifier3(id: number) {
    this.stdSaisieService.saisieModifier3Std(this, id);
  }

  /**
   * Libération des images chargées
   */
  imagesLiberer() {
    this.stdSaisieService.imagesLibererStd(this);
  }

  /**
   * Action après chargment de l'image: Maj boutons
   */
  specifiqueApresChargement(mode: number) {
    this.initService[this.initFn](this, mode);
  }

  /**
   * Affiche une erreur dans une boite de dialogue
   * @param erreur 
   */
  affErreur(erreur: string) {
    this.dialoguesService.messageBox(
      NOM_PROJET,
      "Oups ! Il y a une erreur de paramétrage de l'atelier: <br>" + erreur,
      () => { 
        this.imagesLiberer();
        this.router.navigate(["choix"]);    
      },
      -1
    );
  }

}
