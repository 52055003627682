import { NgClass } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-api-statut',
  standalone: true,
  imports: [
    NgClass,
    NgbTooltip
  ],
  templateUrl: './api-statut.component.html',
  styleUrl: './api-statut.component.scss'
})
export class ApiStatutComponent implements OnChanges {
  @Input() version: string = "??.??.??";
  @Input() toolTip: string = "";
  @Input() classe: string = "taille-1";

  constructor(
    public elementRef: ElementRef,    
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["version"]) {
      if(!this.version) {
        this.version = "??.??.??";
      }
    }    
  }

  clsVersion() {
    const cls = "badge badge-pill";
    if(this.version == "??.??.??" || !this.version) {
      return cls + " badge-danger " + this.classe;
    } else {
      return cls + " badge-success " + this.classe;
    }
  }

}
