import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Inject, Injector, Input, OnInit, Output, inject } from '@angular/core';
import { FonctionsService } from '../../../services/fonctions.service';
import { Logger } from '../../../models/Logger.model';
import { MontantDirectiveService } from './montant-directive.service';
import { tap, withLatestFrom } from 'rxjs';
import { PARENT_COMPONENT_TOKEN, StandardSaisieInterface } from './standard-saisie-interface';

/**
 * Directive pour input de saisie de montant en centimes avec affichage en euros
 */
@Directive({
  selector: '[montantDirective]',
  standalone: true,
})
export class MontantDirective implements AfterViewInit {

  montantDirService = inject(MontantDirectiveService, {optional: true});

  @Input() anticipation: number = -1; // Nb de caractère avant chargement img suivante
  @Input() maxLength: number = 12; // Longueur max
  @Output() yaSaisie: EventEmitter<boolean> = new EventEmitter();
  @Output() imageSuivante: EventEmitter<boolean> = new EventEmitter();
  @Output() valider: EventEmitter<string> = new EventEmitter();
  @Output() echappe: EventEmitter<string> = new EventEmitter();
  @Output() slash: EventEmitter<string> = new EventEmitter();
  @Output() f1: EventEmitter<string> = new EventEmitter();
  @Output() f2: EventEmitter<string> = new EventEmitter();
  @Output() f3: EventEmitter<string> = new EventEmitter();
  @Output() f4: EventEmitter<string> = new EventEmitter();
  @Output() f5: EventEmitter<string> = new EventEmitter();
  @Output() f6: EventEmitter<string> = new EventEmitter();
  @Output() f7: EventEmitter<string> = new EventEmitter();
  @Output() f8: EventEmitter<string> = new EventEmitter();
  @Output() f9: EventEmitter<string> = new EventEmitter();
  @Output() f10: EventEmitter<string> = new EventEmitter();
  @Output() f11: EventEmitter<string> = new EventEmitter();
  @Output() f12: EventEmitter<string> = new EventEmitter();

  toucheF1: boolean = false;
  toucheF2: boolean = false;
  toucheF3: boolean = false;
  toucheF4: boolean = false;
  toucheF5: boolean = false;
  toucheF6: boolean = false;
  toucheF7: boolean = false;
  toucheF8: boolean = false;
  toucheF9: boolean = false;
  toucheF10: boolean = false;
  toucheF11: boolean = false;
  toucheF12: boolean = false;

  logger: Logger = new Logger(this);
  constructor(
    private elementRef: ElementRef,
    @Inject(PARENT_COMPONENT_TOKEN) private parent: StandardSaisieInterface,
    private fonctionsService: FonctionsService
  ) {
    if(this.montantDirService) {  // Enregistrement de la directive dans le service qui l'expose
      this.montantDirService.setMontantDir(this);
    }
  }

  ngAfterViewInit(): void {
    if (this.f1.observed) {
      this.toucheF1 = true;
    }
    if (this.f2.observed) {
      this.toucheF2 = true;
    }
    if (this.f3.observed) {
      this.toucheF3 = true;
    }
    if (this.f4.observed) {
      this.toucheF4 = true;
    }
    if (this.f5.observed) {
      this.toucheF5 = true;
    }
    if (this.f6.observed) {
      this.toucheF6 = true;
    }
    if (this.f7.observed) {
      this.toucheF7 = true;
    }
    if (this.f8.observed) {
      this.toucheF8 = true;
    }
    if (this.f9.observed) {
      this.toucheF9 = true;
    }
    if (this.f10.observed) {
      this.toucheF10 = true;
    }
    if (this.f11.observed) {
      this.toucheF11 = true;
    }
    if (this.f12.observed) {
      this.toucheF12 = true;
    }      
      
  }

  @HostListener('keydown', ['$event']) 
  onkeyDown(event: KeyboardEvent) {
    this.logger.info("onkeyDown", event);
    if(event.key == "Backspace") {
      this.traiterBack(event);    
      event.preventDefault();
      event.stopPropagation();
    } else if(event.key == "Delete") {
      this.traiterDelete(event);
      event.preventDefault();
      event.stopPropagation();
    } else if(event.key == "Enter") {
      this.traiterEnter(event);
      event.preventDefault();
      event.stopPropagation();
    } else if(event.key == "Escape") {
      this.traiterEchap(event);
      event.preventDefault();
      event.stopPropagation();
    } else if(event.key == "+") {
      this.traiterZero(event, 3);
      event.preventDefault();
      event.stopPropagation();
    } else if(event.key == ".") {
      this.traiterZero(event, 2);
      event.preventDefault();
      event.stopPropagation();
    } else if(event.key == "/") {
      this.traiterSlash(event);
      event.preventDefault();
      event.stopPropagation();
    } else if(event.key === 'F1' && this.toucheF1) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF1(event);
    } else if(event.key === 'F2' && this.toucheF2) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF2(event);
    } else if(event.key === 'F3' && this.toucheF3) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF3(event);
    } else if(event.key === 'F4' && this.toucheF4) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF4(event);
    } else if(event.key === 'F5' && this.toucheF5) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF5(event);
    } else if(event.key === 'F6' && this.toucheF6) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF6(event);
    } else if(event.key === 'F7' && this.toucheF7) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF7(event);
    } else if(event.key === 'F8' && this.toucheF8) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF8(event);
    } else if(event.key === 'F9' && this.toucheF9) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF9(event);
    } else if(event.key === 'F10' && this.toucheF10) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF10(event);
    } else if(event.key === 'F11' && this.toucheF11) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF11(event);
    } else if(event.key === 'F12' && this.toucheF12) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF12(event);
    } else {
    }
  
  }

  @HostListener('keypress', ['$event']) 
  onKeyPress(event: KeyboardEvent) {
    this.logger.info("onKeyPress", event);
    if(event.key == "*") {
      this.parent.saisie1 = "";
      this.elementRef.nativeElement.value = "";
      event.preventDefault();
      event.stopPropagation();
      this.imageSuivante.emit(false);
    } else {
      if(this.parent.saisie1.length < this.maxLength) {
        if("0123456789".indexOf(event.key) > -1) { 
          let curPosNew: number = 0;
          let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
          let valueLength: number = this.elementRef.nativeElement.value.length;
          if(curPos == valueLength) {
            this.parent.saisie1 += event.key;
            curPosNew = this.parent.saisie1.length;
            if(this.parent.saisie1.length > 2) {
              curPosNew ++
            }
          } else if(curPos < valueLength - 2) {
            this.parent.saisie1 = this.parent.saisie1.slice(0, curPos) + event.key + this.parent.saisie1.slice(curPos);
            curPosNew = curPos + 1;
          } else if(curPos >= valueLength - 2) {
            this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 1) + event.key + this.parent.saisie1.slice(curPos - 1);
            curPosNew = curPos + 1;
          }
          this.mettreAjour(event, curPosNew);
          if(valueLength == 0) {
            this.yaSaisie.emit();
          }
        } else {
          event.preventDefault();
          event.stopPropagation();
        }
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  @HostListener('keyup', ['$event']) 
  onkeyUp(event: KeyboardEvent) {
    this.logger.info("onkeyUp", event);
  }

  /**
   * Traitement de la touche back
   * @param event event à traiter
   */
  traiterBack(event: KeyboardEvent) {
    this.logger.info("traiterBack", event);
    let curPosNew: number = 0;
    let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
    let valueLength: number = this.elementRef.nativeElement.value.length;

    if(curPos == 0) { 
      // Début on ne fait rien
    } else if(curPos == valueLength) { // Fin de la valeur
      this.parent.saisie1 = this.parent.saisie1.slice(0, this.parent.saisie1.length - 1);
      if(this.parent.saisie1.length > 2) {
        curPosNew = this.parent.saisie1.length + 1;
      } else {
        curPosNew = this.parent.saisie1.length;
      }
    } else if(this.parent.saisie1.length >= 3 && curPos >= valueLength - 2) {  // Apres la virgule
      this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 2) + this.parent.saisie1.slice(curPos - 1);
      if(curPos == valueLength - 2) {
        if(this.parent.saisie1.length > 2) {
          curPosNew = this.parent.saisie1.length - 1;
        } else {
          curPosNew = 0;
        }
      } else {
        if(this.parent.saisie1.length > 2) {
          curPosNew = this.parent.saisie1.length;
        } else {
          curPosNew = this.parent.saisie1.length - 1;
        }
      }
    } else if(this.parent.saisie1.length < 3) {  // Pas de virgule
      this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 1) + this.parent.saisie1.slice(curPos);
      curPosNew = curPos - 1;
    } else {
      this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 1) + this.parent.saisie1.slice(curPos);
      curPosNew = curPos - 1;
    }
    this.mettreAjour(event,curPosNew);
  }

  /**
   * Traitement de la touche Supp.
   * @param event event à traiter
   */
  traiterDelete(event: KeyboardEvent) {
    this.logger.info("traiterDelete", event);
    let curPosNew: number = 0;
    let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
    let valueLength: number = this.elementRef.nativeElement.value.length;
    if(curPos == valueLength) {
      // rien à faire
    } else if(curPos == 0) {  // au début
      this.parent.saisie1 = this.parent.saisie1.slice(curPos + 1);
      curPosNew = curPos;
    } else if(this.parent.saisie1.length > 2) {  // au milieu
      if(curPos < valueLength - 2) { // Avant ou virgule
          this.parent.saisie1 = this.parent.saisie1.slice(0, curPos) + this.parent.saisie1.slice(curPos + 1);
          curPosNew = curPos;
      } else {
        this.parent.saisie1 = this.parent.saisie1.slice(0, curPos-1) + this.parent.saisie1.slice(curPos);
        if(this.parent.saisie1.length == 2) {
          curPosNew = 1;
        } else {
          curPosNew = curPos;
        }
      }
    } else {
      if(curPos == 0) {
        this.parent.saisie1 = this.parent.saisie1.slice(curPos + 1);
        curPosNew = curPos;
      } else {
        this.parent.saisie1 = this.parent.saisie1.slice(0, curPos);
        curPosNew = 1;
      }
    }
    this.mettreAjour(event,curPosNew);
  }

  /**
   * Traitement de la touche Echap
   * @param event 
   */
  traiterEchap(event: KeyboardEvent) {
    this.logger.info("traiterEchap", event);
    event.preventDefault();
    event.stopPropagation();
    this.echappe.emit("");
  }

  /**
   * Traitement de la touche Entrée
   * @param event 
   */
  traiterEnter(event: KeyboardEvent) {
    this.logger.info("traiterEnter", event);
    this.valider.emit(this.parent.saisie1);
  }

  /**
   * Traitement des touches . et + pour ajouter des 0
   * @param event 
   * @param nb 
   */
  traiterZero(event: KeyboardEvent, nb: number) {
    this.logger.info("traiterZero", [event, nb]);
    for(let i = 1; i <= nb; i++) {
      let curPosNew: number = 0;
      let curPos: number = (this.elementRef?.nativeElement?.selectionStart) != null ? this.elementRef.nativeElement.selectionStart : 0;
      let valueLength: number = this.elementRef.nativeElement.value.length;
      if(curPos == valueLength) {
        this.parent.saisie1 += "0";
        curPosNew = this.parent.saisie1.length;
        if(this.parent.saisie1.length > 2) {
          curPosNew += nb
        }
      } else if(curPos < valueLength - 2) {
        this.parent.saisie1 = this.parent.saisie1.slice(0, curPos) + "0" + this.parent.saisie1.slice(curPos);
        curPosNew = curPos + nb;
      } else if(curPos >= valueLength - 2) {
        this.parent.saisie1 = this.parent.saisie1.slice(0, curPos - 1) + "0" + this.parent.saisie1.slice(curPos - 1);
        curPosNew = curPos + nb;
      }
      this.mettreAjour(event, curPosNew);
    }
  }

  /**
   * Traitement de la touche /
   * @param event 
   */
  traiterSlash(event: KeyboardEvent) {
    this.logger.info("traiterSlash", event);
    event.preventDefault();
    event.stopPropagation();
    this.slash.emit("");
  }

  /**
   * Mise à jour f1.observed en cas de late binding
   * @param valeur
   */
  f1Observed(valeur: boolean) {
    this.toucheF1 = true;
  }

  /**
   * Mise à jour f2.observed en cas de late binding
   * @param valeur
   */
  f2Observed(valeur: boolean) {
    this.toucheF2 = true;
  }

  /**
   * Mise à jour f3.observed en cas de late binding
   * @param valeur
   */
  f3Observed(valeur: boolean) {
    this.toucheF3 = true;
  }

  /**
   * Mise à jour f4.observed en cas de late binding
   * @param valeur
   */
  f4Observed(valeur: boolean) {
    this.toucheF4 = true;
  }

  /**
   * Mise à jour f5.observed en cas de late binding
   * @param valeur
   */
  f5Observed(valeur: boolean) {
    this.toucheF5 = true;
  }

  /**
   * Mise à jour f5.observed en cas de late binding
   * @param valeur
   */
  f5bserved(valeur: boolean) {
    this.toucheF5 = true;
  }

  /**
   * Mise à jour f6.observed en cas de late binding
   * @param valeur
   */
  f6Observed(valeur: boolean) {
    this.toucheF6 = true;
  }

  /**
   * Mise à jour f7.observed en cas de late binding
   * @param valeur
   */
  f7Observed(valeur: boolean) {
    this.toucheF7 = true;
  }

  /**
   * Mise à jour f8.observed en cas de late binding
   * @param valeur
   */
  f8Observed(valeur: boolean) {
    this.toucheF8 = true;
  }

  /**
   * Mise à jour f9.observed en cas de late binding
   * @param valeur
   */
  f9Observed(valeur: boolean) {
    this.toucheF9 = true;
  }

  /**
   * Mise à jour f10.observed en cas de late binding
   * @param valeur
   */
  f10Observed(valeur: boolean) {
    this.toucheF10 = true;
  }

  /**
   * Mise à jour f11.observed en cas de late binding
   * @param valeur
   */
  f11Observed(valeur: boolean) {
    this.toucheF11 = true;
  }

  /**
   * Mise à jour f12.observed en cas de late binding
   * @param valeur
   */
  f12Observed(valeur: boolean) {
    this.toucheF12 = true;
  }

  /**
   * Traitement de F1
   * @param event 
   */
  traiterF1(event: KeyboardEvent) {
    if(this.toucheF1) {
      this.logger.info("traiterF1", event);
      event.preventDefault();
      event.stopPropagation();
      this.f1.emit("");
    }
  }

  /**
   * Traitement de F2
   * @param event 
   */
  traiterF2(event: KeyboardEvent) {
    if(this.toucheF2) {
      this.logger.info("traiterF2", event);
      event.preventDefault();
      event.stopPropagation();
      this.f2.emit("");
    }
  }

  /**
   * Traitement de F3
   * @param event 
   */
  traiterF3(event: KeyboardEvent) {
    if(this.toucheF3) {
      this.logger.info("traiterF3", event);
      event.preventDefault();
      event.stopPropagation();
      this.f3.emit("");
    }
  }

  /**
   * Traitement de F4
   * @param event 
   */
  traiterF4(event: KeyboardEvent) {
    if(this.toucheF4) {
      this.logger.info("traiterF4", event);
      event.preventDefault();
      event.stopPropagation();
      this.f4.emit("");
    }
  }

  /**
   * Traitement de F5
   * @param event 
   */
  traiterF5(event: KeyboardEvent) {
    if(this.toucheF5) {
      this.logger.info("traiterF5", event);
      event.preventDefault();
      event.stopPropagation();
      this.f5.emit("");
    }
  }

  /**
   * Traitement de F6
   * @param event 
   */
  traiterF6(event: KeyboardEvent) {
    if(this.toucheF6) {
      this.logger.info("traiterF6", event);
      event.preventDefault();
      event.stopPropagation();
      this.f6.emit("");
    }
  }

  /**
   * Traitement de F7
   * @param event 
   */
  traiterF7(event: KeyboardEvent) {
    if(this.toucheF7) {
      this.logger.info("traiterF7", event);
      event.preventDefault();
      event.stopPropagation();
      this.f7.emit("");
    }
  }

  /**
   * Traitement de F8
   * @param event 
   */
  traiterF8(event: KeyboardEvent) {
    if(this.toucheF8) {
      this.logger.info("traiterF8", event);
      event.preventDefault();
      event.stopPropagation();
      this.f8.emit("");
    }
  }

  /**
   * Traitement de F9
   * @param event 
   */
  traiterF9(event: KeyboardEvent) {
    if(this.toucheF9) {
      this.logger.info("traiterF9", event);
      event.preventDefault();
      event.stopPropagation();
      this.f9.emit("");
    }
  }

  /**
   * Traitement de F10
   * @param event 
   */
  traiterF10(event: KeyboardEvent) {
    if(this.toucheF10) {
      this.logger.info("traiterF10", event);
      event.preventDefault();
      event.stopPropagation();
      this.f10.emit("");
    }
  }

  /**
   * Traitement de F11
   * @param event 
   */
  traiterF11(event: KeyboardEvent) {
    if(this.toucheF11) {
      this.logger.info("traiterF11", event);
      event.preventDefault();
      event.stopPropagation();
      this.f11.emit("");
    }
  }

  /**
   * Traitement de F12
   * @param event 
   */
  traiterF12(event: KeyboardEvent) {
    if(this.toucheF12) {
      this.logger.info("traiterF12", event);
      event.preventDefault();
      event.stopPropagation();
      this.f12.emit("");
    }
  }


  /**
   * Mise à jour de la valeur de l'input et arrêt event
   * @param event event traité
   * @param curPosNew Nouvelle position sur curseur ou -1 = inchangée
   */
  mettreAjour(event: KeyboardEvent, curPosNew: number) {
    this.logger.info("mettreAjour", [event, curPosNew]);
    this.elementRef.nativeElement.value = this.fonctionsService.formatterMontantSaisi(this.parent.saisie1);
    if(curPosNew >= 0) {
      this.elementRef.nativeElement.setSelectionRange(curPosNew, curPosNew);
    }
    event.preventDefault();
    event.stopPropagation();
    // export du montant
    // Gestion anticipation affichage image
    if(this.anticipation > -1 && this.parent.saisie1.length == this.anticipation + 1) {
      this.logger.info("anticipation enclenchée", [this.anticipation, this.parent.saisie1.length])
      // Chargement image suivante
      this.imageSuivante.emit(true);
    } else if(this.parent.saisie1.length == this.anticipation) {
      // Reaffichage image en cours
      this.imageSuivante.emit(false);
    }
  }

  /**
   * Réinitialisation du input contenant la directive
   */
  razMontant() {
    this.parent.saisie1 = "";
    this.elementRef.nativeElement.value = "";
  }

}