import { Injectable } from '@angular/core';
import { FonctionsService } from '../../../services/fonctions.service';
import { WebIndexService } from '../../../services/web-index.service';
import { DialoguesService } from '../../dialogues/dialogues.service';
import { IMAGE_COURANTE, OPERATEUR } from '../../../constantes';
import { TYPECHQ, TYPEREM, TYPESEP, TYPETKL } from './ateliers/atl-taylorim/TimCconstantes';
import { Atelier } from '../../../models/Atelier.model';
import { RechercheBase } from '../../../models/RechercheBase';

@Injectable({
  providedIn: 'root'
})
export class StandardTaylorimService {
  [key: string]: any; // Signature pour appel methode par tableau, pour paramétrage dans Atelier

  constructor(
    private fonctionsService: FonctionsService,
    private webIndexService: WebIndexService,
    private dialoguesService: DialoguesService,
  ) { }

  /****************************************************************************
  * Fonctions pour atelier cmc7 standard
  ****************************************************************************/
  /**
   * Initialisation d'un atelier cmc7 standard
   * @param objAtlSaisie Composant atelier de saisie
   * @param mode 
   */
  cmc7Init(objAtlSaisie: any, mode: number) {
    if(mode == IMAGE_COURANTE) {
      objAtlSaisie.zoneSaisieRef!.instance.saisie4 = objAtlSaisie.image?.saisie4.replace("!", "?"); 
      objAtlSaisie.zoneSaisieRef!.instance.saisie3 = objAtlSaisie.image?.saisie3.replace("!", "?"); 
      objAtlSaisie.zoneSaisieRef!.instance.saisie2 = objAtlSaisie.image?.saisie2.replace("!", "?"); 
      objAtlSaisie.zoneSaisieRef!.instance.saisie1 = objAtlSaisie.image?.saisie1.replace("!", "?"); 
      objAtlSaisie.zoneSaisieRef!.instance.saisie9 = objAtlSaisie.image?.saisie9; 
      objAtlSaisie.zoneSaisieRef!.instance.setFocus();
    } else {
      console.log("**** image en cours pas modifié, on ne fait rien")
    }
  }

  /* 
    Fonctions traduites de Taylorim pour utiliser le même format de paramètres
  */ 
  masqueCorrespond(sValeur: string, sMasqueRef: string): boolean {
    let lPos1: number, lPos2: number, lLong: number;
    let bOk: boolean;
    let asMasques: string[];
    let i: number;
    let sMasque: string;
    let sValeurSAV: string;

    asMasques = sMasqueRef.split("#");
    bOk = false;
    sValeurSAV = sValeur;
    lLong = 0;
    lPos2 = 0;
    if (sMasqueRef.length > 0 && asMasques.length > 0) {
      for (i = 0; i < asMasques.length; i++) {
        if (asMasques[i] !== "") {
          sMasque = asMasques[i];
          sValeur = sValeurSAV;
          do {
            lPos1 = sMasque.indexOf("[");
            if (lPos1 > 0) {
              if (lPos1 > 1) {
                // Test constantes
                if (this.testeConstante(sMasque.substring(0, lPos1), sValeur.substring(0, lPos1))) {
                  lPos2 = lPos1 - 1;
                  lLong = 0;
                  bOk = true;
                } else {
                  bOk = false;
                }
              } else {
                lPos2 = sMasque.indexOf("]", lPos1 + 1);
                if (lPos2 > 0) {
                  // Premier caractere
                  const res = this.testeMasque(sMasque.substring(lPos1 + 1, lPos2), sValeur, lPos1);
                  bOk = res.bOk;
                  lLong = res.lLong;
                } else {
                  bOk = false;
                }
              }
              if (bOk) {
                sMasque = sMasque.substring(lPos2 + 1);
                sValeur = sValeur.substring(lPos1 + lLong);
                if (sMasque.length === 0) {
                  break;
                }
              } else {
                break;
              }
            } else {
              // Test constantes
              if (this.testeConstante(sMasque, sValeur)) {
                bOk = true;
              } else {
                bOk = false;
              }
              break;
            }
          } while (true);
          if (bOk) {
            break;
          }
        }
      }
    } else {
      if (sValeur === "" && sMasqueRef === "") {
        bOk = true;
      }
    }
    return bOk;
  }

  /**
   * Teste un masque 
   * @param sMasque 
   * @param sValeur 
   * @param lPos 
   * @returns 
   */
  testeMasque(sMasque: string, sValeur: string, lPos: number): any {
    let bOk: boolean;
    let lLong = this.longueurMax(sMasque);
    bOk = ("," + sMasque + ",").indexOf("," + sValeur.substring(lPos, lPos + lLong) + ",") > -1;
    return { bOk, lLong};
  }

  /**
   * Teste un masque constant
   * @param sMasque 
   * @param sValeur 
   * @returns 
   */
  testeConstante(sMasque: string, sValeur: string): boolean {
    let lPos: number = 0;
    let bOk: boolean;
    do {
      lPos = sMasque.indexOf("?", lPos);
      if (lPos > -1) {
        sMasque = sMasque.substring(0, lPos) + sValeur.charAt(lPos) + sMasque.substring(lPos + 1);
        lPos++;
      }
    } while (lPos > -1);

    bOk = (sMasque === sValeur);
    return bOk;
  }

  longueurMax(sMasqueValeurs: string): number {
    let lLong: number = 0;
    let asValeurs: string[] = sMasqueValeurs.split(",");

    for (let i = 0; i < asValeurs.length; i++) {
        if (asValeurs[i].length > lLong) {
            lLong = asValeurs[i].length;
        }
    }

    return lLong;
  }

  /**
   * Libellé code enreg
   * @param codeEnreg 
   * @returns 
   */
  libCodeEnreg(codeEnreg: string | null) {
    if(codeEnreg == "2") {
      return "TKL"
    }
    if(codeEnreg == "4") {
      return "REM"
    }
    if(codeEnreg == "3") {
      return "SEP"
    }
    return "CHQ"
  }

  /**
   * Détermine le type de document standard en fonction de la cmc7
   * @param objZoneSaisieRef Objet contenu dans zoneSaisieRef
   */
  typeDocument(objZoneSaisieRef: any): string {
    let bLot4, bLot3, bLot2, bRem4, bRem3, bRem2, bSep4, bSep3, bSep2;

    if(objZoneSaisieRef.maskTklZ4.length > 0) {
      bLot4 = this.masqueCorrespond(
        objZoneSaisieRef.saisie4, 
        objZoneSaisieRef.maskTklZ4
      );
    } else {
      bLot4 = true;
    }
    if(objZoneSaisieRef.maskTklZ3.length > 0) {
      bLot3 = this.masqueCorrespond(
        objZoneSaisieRef.saisie3, 
        objZoneSaisieRef.maskTklZ3
      );
    } else {
      bLot3 = true;
    }
    if(objZoneSaisieRef.maskTklZ2.length > 0) {
      bLot2 = this.masqueCorrespond(
        objZoneSaisieRef.saisie2, 
        objZoneSaisieRef.maskTklZ2
      );
    } else {
      bLot2 = true;
    }
    if(bLot4 && bLot3 && bLot2) {
      return TYPETKL;
    }
    if(objZoneSaisieRef.maskRemZ4.length > 0) {
      bRem4 = this.masqueCorrespond(
        objZoneSaisieRef.saisie4, 
        objZoneSaisieRef.maskRemZ4
      );
    } else {
      bRem4 = true;
    }
    if(objZoneSaisieRef.maskRemZ3.length > 0) {
      bRem3 = this.masqueCorrespond(
        objZoneSaisieRef.saisie3, 
        objZoneSaisieRef.maskRemZ3
      );
    } else {
      bRem3 = true;
    }
    if(objZoneSaisieRef.maskRemZ2.length > 0) {
      bRem2 = this.masqueCorrespond(
        objZoneSaisieRef.saisie2, 
        objZoneSaisieRef.maskRemZ2
      );
    } else {
      bRem2 = true;
    }
    if(bRem4 && bRem3 && bRem2) {
      return TYPEREM;
    }

    if(objZoneSaisieRef.maskSepZ4.length > 0) {
      bSep4 = this.masqueCorrespond(
        objZoneSaisieRef.saisie4, 
        objZoneSaisieRef.maskSepZ4
      );
    } else {
      bSep4 = true;
    }
    if(objZoneSaisieRef.maskSepZ3.length > 0) {
      bSep3 = this.masqueCorrespond(
        objZoneSaisieRef.saisie3, 
        objZoneSaisieRef.maskSepZ3
      );
    } else {
      bSep3 = true;
    }
    if(objZoneSaisieRef.maskSepZ2.length > 0) {
      bSep2 = this.masqueCorrespond(
        objZoneSaisieRef.saisie2, 
        objZoneSaisieRef.maskSepZ2
      );
    } else {
      bSep2 = true;
    }
    if(bSep4 && bSep3 && bSep2) {
      return TYPESEP;
    } else {
      return TYPECHQ;
    }
  }
  
  /**
   * Récupération d'une face de l'iamge
   * @param objAtl Atelier contenant l'image
   * @param id id de l'image
   * @param face Face à récupérer r ou v
   * @param callBack Fonction appelée une fois l'image récupérée
   */
  getImageFace(
    objAtl: any,
    id: number, 
    face: string,
    callBack: Function
  ) {
    let mapParam = new Map<string, string>();
    mapParam.set("imageId", id.toString());
    mapParam.set("face", face);
    this.webIndexService.appelBackendExterneTm(
      "imageFace", 
      objAtl.siteId, objAtl.traitementId, objAtl.atelier, 
      objAtl.dateTraitement, 
      mapParam,
      5000
    ).subscribe({
      error: (err) => {
        console.error(err);
      },
      next: (res: any) => {
        callBack(objAtl, res.image64);
      },
      complete: () => { }  
    });
  }

  /**
   * Exploration des images dans une remise
   * @param objAtl Atelier contenant l'image
   * @param id id de l'image
   * @param face Face à récupérer r ou v
   * @param callBack Fonction appelée une fois l'image récupérée
   */
  remExplorer(
    objAtl: any,
    id: number, 
    sens: number,
    face: string,
    callBack: Function,
    callBackErr: Function
  ) {
    let mapParam = new Map<string, string>();
    mapParam.set("imageId", id.toString());
    mapParam.set("sens", sens.toString());
    mapParam.set("face", face);
    this.webIndexService.appelBackendExterneTm(
      "remImgExplorer", 
      objAtl.siteId, objAtl.traitementId, objAtl.atelier, 
      objAtl.dateTraitement, 
      mapParam,
      5000
    ).subscribe({
      error: (err) => {
        console.error(err);
        callBackErr(objAtl, err);
      },
      next: (res: any) => {
        callBack(objAtl, res.imageId, res.image64);
      },
      complete: () => { }  
    });
  }


  /****************************************************************************
   * Fonction pour recherche standard
   */
  /**
   * Bouton 1 Localiser
   * @param objRecherche Popup de recherche
   */
  rechBouton1(objRecherche: any) {
    if(objRecherche.rechercheTplRef!.instance.listRechBase.length > 0) {
      objRecherche.divInput2.nativeElement.classList.remove("zone-cache");
      objRecherche.renderer.setAttribute(objRecherche.input2.nativeElement, "data-id", "1");
      objRecherche.renderer.setAttribute(objRecherche.input2.nativeElement, "place-holder", "Localiser...");
      objRecherche.renderer.setStyle(objRecherche.divInput2.nativeElement, "top", objRecherche.bouton1.nativeElement.getBoundingClientRect().top + "px");
      objRecherche.renderer.setStyle(objRecherche.divInput2.nativeElement, "left", objRecherche.bouton1.nativeElement.getBoundingClientRect().left/2 + "px");
      objRecherche.input2.nativeElement.focus();
    }
  }

  /**
   * Action localiser dans le résultat
   * @param objRecherche 
   */
  rechBouton1Localiser(objRecherche: any) {
    const liste = objRecherche.rechercheTplRef!.instance.listRechBase;
    const rech = objRecherche.input2.nativeElement.value.toLowerCase();
    if(liste.length > 0) {
      for(let i = 0; i < liste.length; i++) {
        if((liste[i].libelle + liste[i].libelle2).toLowerCase().indexOf(rech) > 0) {
          objRecherche.rechercheTplRef?.instance.select(i);
          objRecherche.onInput2Fermer();
          break;
        }
      }
    }
  }

  /**
   *  Action sur bouton 2 = Suivant
   */
  rechBouton2(objRecherche: any) {
    const liste = objRecherche.rechercheTplRef!.instance.listRechBase;
    const rech = objRecherche.input2.nativeElement.value.toLowerCase();
    if(liste.length > 0 && objRecherche.rechercheTplRef?.instance.selectIndex < liste.length -1) {
      for(let i = objRecherche.rechercheTplRef?.instance.selectIndex + 1; i < liste.length; i++) {
        if((liste[i].libelle + liste[i].libelle2).toLowerCase().indexOf(rech) > 0) {
          objRecherche.rechercheTplRef?.instance.select(i);
          break;
        }
      }
    }
    setTimeout(() => {
      objRecherche.inputRecherche.nativeElement.focus();
    }, 0);
  }


}
