import { Component, ElementRef, EventEmitter, inject, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { InputMaskDirective } from '../../input-mask.directive';
import { WebIndexService } from '../../../../../services/web-index.service';
import { InputMaskDirectiveService } from '../../input-mask-directive.service';
import { withLatestFrom, tap } from 'rxjs';
import { Atelier } from '../../../../../models/Atelier.model';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { DynamicPipePipe } from '../../dynamic.pipe';
import { LowerCasePipe, UpperCasePipe } from '@angular/common';

/**
 * Composant pour saisie dans le champ saisie1
 * avec masque paramétrable
 * champMaskShow dans "param5[3]"
 * champLength dans "param5[4]"
 * champCase dans "param5[5]"
 */
@Component({
  selector: 'app-saisie-std',
  standalone: true,
  imports: [
    NgxMaskDirective,
    FormsModule,
    //NgbTooltip,
    InputMaskDirective, 
    DynamicPipePipe,
  ],
  providers: [
    provideNgxMask(),
    UpperCasePipe,
    LowerCasePipe,
    WebIndexService,
  ],
  templateUrl: './saisie-std.component.html',
  styleUrl: './saisie-std.component.scss'
})
export class SaisieStdComponent implements OnInit {
  inputMaskDirService = inject(InputMaskDirectiveService);

  @Input({required: true}) siteId: string = "";
  @Input({required: true}) traitementId: string = "";
  @Input({required: true}) atelier: Atelier = {} as Atelier;
  @Input({required: false}) objAtl: Map<string, any> = new Map();
  @Output() onImageChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onValidation: EventEmitter<boolean> = new EventEmitter();
  @Output() onMessage: EventEmitter<string> = new EventEmitter();
  @Output() onEchappe: EventEmitter<string> = new EventEmitter();
  @Output() onSlash: EventEmitter<string> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputMaskDirective') inputMaskDirective!: InputMaskDirective;
  @ViewChild('inputSaisie1', { static: true }) inputSaisie1: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  
  champLib: string = "param5-1";
  champMask: string = "param5-2";
  champMaskShow: boolean = false; // "param5-3"
  champLength: number = 12; // "param5-4"
  champCase: number = 0;  // "param5-5"
  saisie1: string = "";
  controleService: any = null;
  controleFn: string = "";
  
  constructor(
    private fonctionsService: FonctionsService,
  ) {
  }

  ngOnInit(): void {
    // Préchargement des services
    const { service: controleService, fonction: controleFn } = this.fonctionsService.litParametreDeService(this.atelier.fonctionCtrl, "rienAFaireStr");
    this.controleService = controleService;
    this.controleFn = controleFn;
    const res = this.fonctionsService.testServiceFonction(
        "atelier.fonction_ctrl=" + this.atelier.fonctionCtrl,
        controleService, controleFn);
    if(!res.res) {
      setTimeout(() => { 
        this.onError.emit(res.err)
      }, 0 );
      return;
    } else {
      // Masque de saisie
      const [ champLib, champMask, champMaskShow, champLength, champCase ] = this.atelier.param5.split("|");
      this.champLib = champLib;
      this.champMask = champMask;
      this.champMaskShow = champMaskShow == "true" ? true : false;
      this.champLength = Number(champLength);
      this.champCase = Number(champCase);
    }

  }

  nomPipe(): string {
    if(this.champCase == 1) {
      return "minuscule";
    } else if(this.champCase == 2) {
      return "majuscule";
    } else {
      return "none";
    }
  }

  /**
   * Traitement modification d'image affiché
   * @param bSuivante True pour afficher l'image suivante, false pour afficher l'image courante
   */
  onImageSuivante(bSuivante: boolean) {
    this.onImageChange.emit(bSuivante);
  }

  /**
   * Traitement touche Esc 
   * @param event (pas utilisé)
   */
  onTraiterEchappe(event: string) {
    this.onEchappe.emit(event);
  }

  /**
   * Traitement après appui sur Entrée
   * @param valeur Valeur saisie 
   */
  onTraiterEntree(valeur: string) {
    const msg: string = this.controleService[this.controleFn](this);
    if(msg.length == 0) {
      this.onValidation.emit(true);
    } else {
      this.onImageChange.emit(false); // On réaffiche l'image
      this.onMessage.emit(msg);
    }  
  }

  /**
   * Traitement après appui sur / pour revenir au document précédent
   */
  onTraiterSlash(event: any) {
    this.onSlash.emit("");
  }

  focus() {
    this.inputSaisie1.nativeElement.focus();
  }

  /**
   * Mise à jour des objets de paramétrage par le parent
   * @param objAtl 
   */
  setObjAtl(objAtl: Map<string, any>) {
    this.objAtl = objAtl;
  }

  razSaisie() {
    this.inputMaskDirService?.inputMaskDir$.pipe(
      withLatestFrom(this.inputMaskDirService?.inputMaskDir$),
      tap(([_, inpDir]) => {
        inpDir?.razValeur();
      })
    ).subscribe();    
  }

  /**
   * Fonction appelé lors de l'insertion dans l'historique
   * @returns Valeur saisie affiché dans l'historique
   */
  historique(): string {
    return this.saisie1;
  }

}
