<div class="container-fluid">
  <div class="row align-items-start">
    <!-- Zone de la colonne à gauche -->
    <div class="col-md-3">
      <ng-template #historiqueTpl/>
    </div>
    <!-- Zone de l'image -->
    <div 
      [class]="[atelier.style1, 'zone'].join(' ')"
    >
      <div class="row id-image">Image {{ image?.id}}</div>

      <ng-template #imageTpl/>
      <!-- Zone de saisie -->
      <div class="row">
        <div class="col-md-12 zones-saisies">
          <ng-template #zoneSaisieTpl/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 message" [innerText]="message">
      </div>
      <div class="zone-technique col-md-12">
        <div 
          class="form-check form-switch  custom-switch"
          [ngClass]="clsBtnPhysique()"
          ngbTooltip="Cocher pour envoyer en atelier physique"
          tabindex="20"
        >
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" 
            [(ngModel)]="topPhysique"
            (ngModelChange)="changementTopPhysique()"
          >
          <label class="form-check-label" for="flexSwitchCheckDefault">[F12] Physique</label>
        </div>
        <div 
          class="col-bouton"
          [ngClass]="clsBtnAtlSuivant()"
        >
          <app-bouton 
            [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini'" 
            [iconClass]="'d-inline'" 
            [libelle]="'Atl Suivant'"
            [tooltip]="'Laisser passer le document pour être traité dans l\'atelier suivant'"
            (clic)="onAtlSuivantClic($event)"
          ></app-bouton>
        </div>        
        <div 
          class="col-bouton"
          [ngClass]="clsBtnTeteBeche()"
        >
          <app-bouton 
            [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini'" 
            [iconClass]="'d-inline, icon-refresh-cwv'" 
            [libelle]="'[F5] Tête Bêche'"
            [tooltip]="'Retourner l\'image de 180°'"
            (clic)="onTeteBecheClic($event)"
          ></app-bouton>
        </div>        
        <div 
          class="col-bouton"
          [ngClass]="clsBtnRectoVerso()"
        >
          <app-bouton 
            [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini'" 
            [iconClass]="'d-inline, icon-refresh-cw'" 
            [libelle]="'[F4] Recto/Verso'"
            [tooltip]="'Afficher l\'autre face de l\'image'"
            (clic)="onRectoVersoClic($event)"
          ></app-bouton>
        </div>        
        <div 
          class="col-bouton"
          [ngClass]="clsBtnExplorer()"
        >
          <app-bouton 
            [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini'" 
            [iconClass]="'d-inline, icon-eye'" 
            [libelle]="'[F1] Explorer'"
            [tooltip]="'Rechercher sur les autres documents'"
            (clic)="onExplorerClic()"
          ></app-bouton>
        </div>        
        <app-bouton [btnClass] = "'btn btn-secondary btn-mini btn-droite'" 
          [iconClass]="'d-inline, icon-video-off'" 
          [libelle]="'Suivante'" 
          (clic)="clicSuivante($event)" 
          [tooltip]="'Passer à l\'image suivante (N\'enregistre pas la saisie en cours)'"
          tabindex="50"
        ></app-bouton>
      </div>
  </div>
</div>
<div [ngClass]="clsExplorer">
  <app-explorer-popup 
    #explorerPopup
    [objAtl]="this"
    [explorerSrv]="explorerService"
    [explorerFn]="explorerFn"
    [face]="faceAff"
    (fermer)="explorerPopupFermer()"
  />
</div>