import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Atelier } from '../../../../../models/Atelier.model';
import { InputMaskDirective } from '../../../standard/input-mask.directive';
import { DynamiqueService } from '../../../../../services/dynamique.service';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { FormsModule, NgModel } from '@angular/forms';
import { NgbDropdown, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BoutonComponent } from '../../../../bouton/bouton.component';
import { tap, withLatestFrom } from 'rxjs';
import { InputMaskDirectiveService } from '../../../standard/input-mask-directive.service';
import { LBP_CPT_PARKIN, LBP_CPT_RECHER, LBP_CPT_SAISIE, LbpSaisiesService } from '../lbp-saisies.service';
import { WebIndexService } from '../../../../../services/web-index.service';
import { MenuPopupComponent } from "../../../standard/autres/menu-popup/menu-popup.component";
import { Agence } from '../../../../../models/Agence';
import { RecherchePopupComponent } from "../../../standard/autres/recherche-popup/recherche-popup.component";
import { Parametre } from '../../../../../models/Parametre.model';

@Component({
  selector: 'app-lbp-saisie-cpt',
  standalone: true,
  templateUrl: './lbp-saisie-cpt.component.html',
  styleUrl: './lbp-saisie-cpt.component.scss',
  imports: [
    NgxMaskDirective,
    FormsModule,
    NgbTooltip,
    InputMaskDirective,
    BoutonComponent,
    MenuPopupComponent,
    RecherchePopupComponent
],
  providers: [
    provideNgxMask(),
    WebIndexService,
  ],
})

export class LbpSaisieCptComponent implements AfterViewInit {
  inputMaskDirService = inject(InputMaskDirectiveService);

  @Input({required: true}) siteId: string = "";
  @Input({required: true}) traitementId: string = "";
  @Input({required: true}) atelier: Atelier = {} as Atelier;
  /** Paramètres chargés par le composant parent */
  @Input({required: true}) objAtl: Map<string, any> = new Map();

  @Output() onImageChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onRectoVerso: EventEmitter<boolean> = new EventEmitter();
  @Output() onTeteBeche: EventEmitter<boolean> = new EventEmitter();
  @Output() onValidation: EventEmitter<boolean> = new EventEmitter();
  @Output() onMessage: EventEmitter<string> = new EventEmitter();
  @Output() onEchappe: EventEmitter<string> = new EventEmitter();
  @Output() onSlash: EventEmitter<string> = new EventEmitter();
  @Output() traiterF1: EventEmitter<string> = new EventEmitter();
  @Output() traiterF12: EventEmitter<string> = new EventEmitter();
  @Output() traiterFlecheHaut: EventEmitter<string> = new EventEmitter();
  @Output() traiterFlecheBas: EventEmitter<string> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputMaskDirective') inputMaskDirective!: InputMaskDirective;
  @ViewChild('inputSaisie1', { static: true }) inputSaisie1: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  @ViewChild('div1', { static: true }) div1: ElementRef<HTMLDivElement>= {} as ElementRef<HTMLDivElement>;
  @ViewChild('recherchePopup') recherchePopup!: RecherchePopupComponent;
  
  mapCentres: Map<string, string> = new Map();
  mapCentresRegatte: Map<string, string> = new Map(); // Pour sélection critère regatte = centre
  idCodeSup: string | null = null;
  modeSaisie: string = "";
  saisie1: string = "";
  saisie2: string = "";
  afficherCodesCentres: boolean = false;
  controleService: any = null;
  controleFn: string = "";
  focusService: any = null; // Fonction pour calcul focus
  focusFn: string = "";

  comptePatterns = { 'L': { pattern: new RegExp('[0123456789ABCDEFGHJKLMNPRSTUVWXYZabcdefghjklmnprstuvwxyz]') } };
  
  divPosX: number = 0;
  initOk: boolean = true;

  /** Paramètres de l'atelier enregistré dans Webindex.Parametre pour recherche */
  mapParamWdx: Map<string, Parametre> = new Map();

  /** Paramètres Atelier.param10 transformé en map */
  mapParamAtl: Map<string, string> = new Map();

  constructor(
    private webIndexService: WebIndexService,
    private fonctionsService: FonctionsService,
    private lbpSaisiesService: LbpSaisiesService,
  ) { }

  ngOnInit(): void {
    // Préchargement des service focus
    const { service: focusService, fonction: focusFn } = this.fonctionsService.litParametreDeService(this.atelier.fonction2, "focus1");
    this.focusService = focusService;
    this.focusFn = focusFn;
    let res = this.fonctionsService.testServiceFonction(
        "atelier.fonction2=" + this.atelier.fonction2,
        focusService, focusFn
    )
    if(!res.res) {
      setTimeout(() => { 
        this.onError.emit(res.err);
      }, 0);
      return
    }
    // Préchargement controle
    const { service: controleService, fonction: controleFn } = this.fonctionsService.litParametreDeService(this.atelier.fonctionCtrl, "rienAFaireStr");
    this.controleService = controleService;
    this.controleFn = controleFn;
    res = this.fonctionsService.testServiceFonction(
        "atelier.fonction_ctrl=" + this.atelier.fonctionCtrl,
        controleService, controleFn
    )
    if(!res.res) {
      setTimeout(() => { 
        this.onError.emit(res.err);
      }, 0);
      return
    }
    this.lireParametresWdx();
  }

  ngAfterViewInit(): void {
    const element = this.div1.nativeElement;
    const rect = element.getBoundingClientRect();
    setTimeout( () => { this.divPosX = rect.left; }, 0); // Timeout nécessaire pour éviter NG0100
  }

  /**
   * Mise à jour des objets de paramétrage par le parent
   * @param objAtl 
   */
  setObjAtl(objAtl: Map<string, any>) {
    this.objAtl = objAtl;
    this.objAtl.get("lstCentres").forEach(
      (centre: Agence) => {
        this.mapCentres.set(centre.codeGuichet, centre.codeGuichet + "- (" + centre.infoGuichet1 + ") " + centre.libelle); 
        this.mapCentresRegatte.set(centre.infoGuichet2, centre.codeGuichet + "- (" + centre.infoGuichet1 + ") " + centre.libelle);      
    });
  }

  /**
   * Traitement modification d'image affiché
   * @param bSuivante True pour afficher l'image suivante, false pour afficher l'image courante
   */
  onImageSuivante(bSuivante: boolean) {
    this.onImageChange.emit(bSuivante);
  }

  /**
   * Traitement touche Esc
   * @param event (pas utilisé)
   */
  onTraiterEchappe(event: string) {
    this.onEchappe.emit(event);
  }

  /**
   * Traitement après appui sur Entrée
   * @param montant Montant saisie en centimes
   */
  onTraiterEntree(compte: string) {
    const regex = /^[A-Z]+$/;
    if(this.saisie1.length == 0 && this.atelier.param10.indexOf("cptVide=true") > -1) {  // On laisse passer pour prise en compte dans ateliers suivants
      this.saisieOk();
    } else {
      this.saisie1 = this.fonctionsService.padL(this.saisie1, 12, "0");
      if(regex.test(this.fonctionsService.right(this.saisie1, 3))) {
        // Conversion lib centre en chiffres
        this.saisie1 = this.lbpSaisiesService.lbpConvertirCentre(this.saisie1, this.objAtl.get("lstCentres"));
      }

      if(this.controleFn.length > 0) {
        this.controleService[this.controleFn](this);
      } else {
        this.saisieOk();
      }    
    }
  }

  majuscule(event: any) {
    this.saisie1 = event.toUpperCase();
  }

  /**
   * Fonction appelée dans la fonction de contrôle si la saisie est bonne
   */
  saisieOk() {
    this.onValidation.emit(true);
  }

  /**
   * Fonction appelée dans la fonction de contrôle quand la saisie est ko
   * @param msg Message de l'erreur
   */
  saisieKo(msg: string) {
    this.onImageChange.emit(false); // On réaffiche l'image
    this.onMessage.emit(msg);
  }

  /**
   * Fonction appelée dans la fonction de contrôle quand la saisie doit être confirmée
   * @param msg Message de l'erreur
   */
  saisieConfirmer(msg: string) {
    this.onConfirm.emit(
      {
        titre: "Saisie compte Lbp",
        message: msg + " Confirmez-vous la saisie ?",
        defaut: 2,
        callBackOui: () => { this.onValidation.emit(true); },
        callBackNon: () => { this.saisieKo(msg); },
        delai: 0
      }
    );  
  }

  /**
   * Traitement après appui sur / pour revenir au document précédent
   */
  onTraiterSlash(event: any) {
    this.onSlash.emit("");
  }

  /**
   * Traitement après appui sur / pour revenir au document précédent
   */
  onTraiterEspace(event: any) {
    this.afficherCodesCentres = !this.afficherCodesCentres;
  }

  onTraiterF1(event: any) {
    this.traiterF1.emit();
  }

  onTraiterF4(event: any) {
    this.onRectoVerso.emit();
  }

  onTraiterF5(event: any) {
    this.onTeteBeche.emit();
  }

  onTraiterF10(event: any) {
    this.onRechercherClic(null);
  }

  onTraiterF12(event: any) {
    this.traiterF12.emit();
  }

  onTraiterFlecheHaut(event: any) {
    this.traiterFlecheHaut.emit();
  }

  onTraiterFlecheBas(event: any) {
    this.traiterFlecheBas.emit();
  }

  /**
   * Sélection d'un code centre dans la liste
   * @param codeCentre 
   */
  onCentresSelect(codeCentre: string) {
    const inputVal = this.inputSaisie1.nativeElement.value;
    const posDeb = this.inputSaisie1.nativeElement.selectionStart ?? 0;
    const posFin = this.inputSaisie1.nativeElement.selectionEnd ?? 0;
    if(posDeb != posFin) {
      // on supprime la sélection
      this.inputSaisie1.nativeElement.value = 
          inputVal.substring(0, posDeb)
          + inputVal.substring(posFin);
      this.inputSaisie1.nativeElement.setSelectionRange(posDeb, posDeb)
    }
    const valeur = 
      this.inputSaisie1.nativeElement.value.substring(0, posDeb)
      + codeCentre 
      + this.inputSaisie1.nativeElement.value.substring(posDeb);

    this.saisie1 = valeur;  
    setTimeout( () => { // Pour laisser du temps au binding
      this.afficherCodesCentres =false;
      this.focus();
    }, 0);

  }

  /**
   * Action clic sur le bouton Recherche
   * @param any 
   */
  onRechercherClic(any: Event | null) {
    this.recherchePopup.saisie = this.inputSaisie1.nativeElement.value;
    this.recherchePopup.afficher();
  }

  /**
   * 
   * @param selection Element sélectionné noCompte|IdCompte
   */
  onRechSelection(selection: string) {
    if(selection) {
      const infos = selection.split("|");
      this.saisie1 = infos[0];
      this.saisie2 = infos[1];
    }
    this.inputSaisie1.nativeElement.focus();
  }

  /** Réponse à une action demandée par recherchePopup*/
  onRechAction(action: string) {
    if(action === "changeFaceImage") {
      this.onRectoVerso.emit();
    }
  }

  /**
   * Action quand un caractère est saisi
   */
  yaSaisie() {
    // On efface le message d'erreur
    this.onMessage.emit("");
  }

  /**
   * Donne le focus
   */
  focus() {
    if(this.focusFn.length > 0) {
      this.focusService[this.focusFn](this, 0);
    }
  }

  /**
   * Effacement des champs
   */
  razSaisie() {
    this.inputMaskDirService?.inputMaskDir$.pipe(
      withLatestFrom(this.inputMaskDirService?.inputMaskDir$),
      tap(([_, inpDir]) => {
        inpDir?.razValeur();
      })
    ).subscribe();    
  }

  /**
   * Calcule la classe du bouton recherche (visibilité)
   * @returns 
   */
  clsBtnRecherche(): string {
    let cls = "";
    if(this.modeSaisie == LBP_CPT_RECHER || this.modeSaisie == LBP_CPT_PARKIN) {
      cls = "btn btn-secondary btn-fond-neutre";
    } else {
      cls = "btn-cache";
    }
    return cls;
  }

  /**
   * Calcule la classe du bouton Explorer (visibilité)
   * @returns 
   */
  clsBtnExplorer(): string {
    let cls = "";
    if(this.modeSaisie == LBP_CPT_RECHER) {
      cls = "btn btn-secondary btn-fond-neutre btn-mini";
    } else {
      cls = "btn-cache";
    }
    return cls;
  }

  /**
   * Lecture des paramètres stockés dans webIndex.Parametre
   */
  lireParametresWdx() {
    this.mapParamAtl = this.fonctionsService.str2Map(this.atelier.param10);
    let param = this.mapParamAtl.get("mode");
    if(param == undefined) {
      this.modeSaisie = LBP_CPT_SAISIE;
    } else {
      this.modeSaisie = param;
    }

    if(this.modeSaisie == LBP_CPT_RECHER || this.modeSaisie == LBP_CPT_PARKIN) {
      // Lecture paramètres nommés pour recherche compte
      let mapParamWdx: Map<string, Parametre> = new Map();
      const nomParams = 
        "LBP-REM-CPT-R_INIT#"
        + "LBP-REM-CPT-R_RECHERCHER#"
        + "LBP-REM-CPT-R_BOUTON1#"
        + "LBP-REM-CPT-R_BOUTON2#"
        + "LBP-REM-CPT-R_BOUTON3#"
        + "LBP-REM-CPT-R_BOUTON4#"
        + "LBP-REM-CPT-R_BOUTON5#"
        + "LBP-REM-CPT-R_BOUTON6#"
        + "LBP-REM-CPT-R_SELECTION#"
        + "LBP-REM-CPT-R_ELEMENT#"
        + "LBP-REM-CPT-R_AIDE#";		
      this.webIndexService.getParametres(nomParams).subscribe({
        error: (err) => {
          this.onError.emit("Erreur de chargement des paramètres pour recherche")
        },
        next: (res: any) => {
          const mpWdx: Map<string, Parametre> = new Map(Object.entries(res));
          this.initOk = true;
          for( let [nom, param] of mpWdx) {
            if(!this.initOk) {
              break;
            }
            let paramWdx: Parametre = new Parametre(param.nomParam, param.valeur, param.commentaire);
            if(nom.indexOf("_BOUTON") > -1) {
              const no = parseInt(nom.substring(nom.length - 1, nom.length));
              const mapVal = this.fonctionsService.str2Map(param.valeur);
              paramWdx.specifique = mapVal.get("libelle") ?? "";
              paramWdx.touche = mapVal.get("touche") ?? "";
              paramWdx.tooltip = mapVal.get("toolTip") ?? "";
              if(this.prechargerSrvFn(mapVal.get("srvFnClass") ?? "", 0, paramWdx) ) {
                if(this.prechargerSrvFn(mapVal.get("srvFnClic") ?? "", 1, paramWdx) ) {
                  if(this.prechargerSrvFn(mapVal.get("srvFnSpecifique1") ?? "", 2, paramWdx) ) {
                    if(this.prechargerSrvFn(mapVal.get("srvFnSpecifique2") ?? "", 3, paramWdx) ) {
                     if(this.prechargerSrvFn(mapVal.get("srvFnSpecifique3") ?? "", 4, paramWdx) ) {
                      this.prechargerSrvFn(mapVal.get("srvFnSpecifique4") ?? "", 5, paramWdx);
                        mapParamWdx.set(nom, paramWdx);
                     }
                    }
                  }
                } 
              }
            } else {
              mapParamWdx.set(nom, paramWdx);
            }
          }
          this.mapParamWdx = mapParamWdx;
        }
      });      
    }
  }

  /**
   * Chargement des services et fonctions paramétrés pour recherchePopup
   * @param paramSrvFn Paramètre MonService.MaFonction
   * @param noService Index du service dans param
   * @param param Parametre contenant les infos
   * @returns boolean
   */
  prechargerSrvFn(paramSrvFn: string, noService: number, param: Parametre) {
    const mapVal = this.fonctionsService.str2Map(param.valeur);
    param.specifique = mapVal.get("libelle") ?? "";
    let { service: Service, fonction: fn } = this.fonctionsService.litParametreDeService(paramSrvFn, "rienAFaire");
    param.services[noService] = Service;
    param.fonctions[noService] = fn;
    const res = this.fonctionsService.testServiceFonction(
      "_Bouton" + (noService + 1) + "=" + paramSrvFn,
      param.services[noService], param.fonctions[noService], 
    );
    if(res.res) {
      return true;
    } else {
      this.onError.emit(res.err); 
      this.initOk= false;
      return false;
    }
  }

  setSaisie1(compte: string) {
    this.saisie1 = compte; 
  }

  /**
   * Fonction appelé lors de l'insertion dans l'historique
   * @returns Valeur saisie affiché dans l'historique
   */
  historique(): string {
    return this.saisie1;
  }

}
