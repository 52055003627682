/**
 * Fichier à importer dans tous les environnements 
 * pour une gestion centralisée de la version
 */
export const version = {
  version: '1.5.4'  // 09/01/2025 HYE : Bogue cmc7
  
  /*
  version: '1.5.3'  // 08/01/2025 HYE : Atl comptes inexistants + divers
  version: '1.5.2'  // 20/12/2024 HYE : Bogue Admin
  version: '1.5.1'  // 19/12/2024 HYE : Bogue init
  version: '1.5.0'  // 19/12/2024 HYE : Embryon admin
  version: '1.4.1'  // 13/12/2024 HYE : Bogue init Atl recherche de compte
  version: '1.4.0'  // 12/12/2024 HYE : Atl recherche de compte
  version: '1.3.3'  // 26/11/2024 HYE : Spécifique Cmc7 Lbp
  version: '1.3.2'  // 18/11/2024 HYE : Amélioration message d'erreur sur test fonctions paramétrée
  version: '1.3.1'  // 18/11/2024 HYE : Bogue fonction enreg standard non renommées
  version: '1.3.0'  // 18/11/2024 HYE : Atelier Lbp Compte
  version: '1.2.1'  // 08/11/2024 HYE : Deboguage centre
  version: '1.2.0'  // 08/11/2024 HYE : Atelier Lbp Zone2
  version: '1.1.5'  // 31/10/2024 HYE : Correction bogues paramétrage fonctions, + contrôles
  version: '1.1.4'  // 30/10/2024 HYE : Correction bogue atl cmc7, nouvelle gestion version
  */
};