import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Atelier } from '../../../../../models/Atelier.model';
import { BoutonComponent } from '../../../../bouton/bouton.component';

@Component({
  selector: 'app-explorer-popup',
  standalone: true,
  imports: [
    BoutonComponent
  ],
  templateUrl: './explorer-popup.component.html',
  styleUrl: './explorer-popup.component.scss'
})
export class ExplorerPopupComponent {
  @Input() objAtl: any;
  @Input() explorerSrv: any;
  @Input() explorerFn: string = "";
  @Input() face: string = "r";

  @Output() fermer: EventEmitter<any> = new EventEmitter();

  imageId: number = 0;
  image64: string = "";

  clsBtnFlecheHaut: string = "btn btn-secondary btn-mini";
  clsBtnFlecheBas: string = "btn btn-secondary btn-mini";

  onFermer() {
    this.fermer.emit();
  }

  onFlecheHaut() {
    this.explorerSrv[this.explorerFn](this.objAtl, this.imageId, -1, this.face, 
      (oAtl: any, imageId: number, img64: string) => {
        this.imageId = imageId;
        this.image64 = img64;
      },
      (err: any) => {
        setTimeout(() => {
          this.clsBtnFlecheHaut = "btn btn-secondary btn-mini btn-rouge";
          setTimeout(() => {
            this.clsBtnFlecheHaut = "btn btn-secondary btn-mini";  
          }, 1000);
        }, 0);
      }
    )
  }

  onFlecheBas() {
    const imgId = this.imageId;
    this.explorerSrv[this.explorerFn](this.objAtl, this.imageId, 1, this.face, 
      (oAtl: any, imageId: number, img64: string) => {
        this.imageId = imageId;
        this.image64 = img64;
      },
      (err: any) => {
        this.clsBtnFlecheBas = "btn btn-secondary btn-mini btn-rouge";
        setTimeout(() => {
          this.clsBtnFlecheBas = "btn btn-secondary btn-mini";          
        }, 1000);
      }
    )
  }

  onRectoVerso() {
    if(this.face == "r") {
      this.face = "v";
    } else {
      this.face = "r"
    }
    this.explorerSrv[this.explorerFn](this.objAtl, this.imageId, 0, this.face, 
      (oAtl: any, imageId: number, img64: string) => {
        this.imageId = imageId;
        this.image64 = img64;
      }
    )

  }

  ouvrir(imageEncoursId: number) {
    this.explorerSrv[this.explorerFn](this.objAtl, imageEncoursId, 1, this.face, 
      (oAtl: any, imageId: number, img64: string) => {
        this.imageId = imageId;
        this.image64 = img64;
      }
    )

  }

}
