<button 
  *ngIf="sansToolTip()"
  type="button" 
  class="btn {{btnClass}}"
  (click)="onClick(null)"
>
  <i [class]="iconClass"></i>
    {{libelle}}
</button>

<button 
  #t="ngbTooltip"
  type="button" 
  class="btn {{btnClass}}"
  (click)="onClick(t)"
  [ngbTooltip]="tplToolTip"
  tooltip-trigger="hover"
  [placement]="tooltipPlacement"
  *ngIf="!sansToolTip()"
>
  <i [class]="iconClass"></i>
    {{libelle}}
</button>
<ng-template #tplToolTip>
  <span [innerHtml]="tooltip"></span>
</ng-template>
