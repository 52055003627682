<div ngbDropdownMenu 
  [ngClass]=" afficher ? 'show' : 'hide'" 
  [ngStyle]="{'top.px': posY, 'left.px': posX, 'position': 'absolute'}"
>
  <div *ngFor="let option of mapOptions | keyvalue">
    <button ngbDropdownItem 
      class="taille"
      (click)="optionClic(option.key)"
    >
    {{ option.value }}</button>
  </div>
</div>
