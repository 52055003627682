import { Injectable } from '@angular/core';
import { Logger } from '../../../models/Logger.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIKEY } from '../../../constantes';
import { FonctionsService } from '../../../services/fonctions.service';
import { ApiUrlService } from '../../../services/api-url.service';
import { Backends } from '../../../models/BackendsModel';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  logger: Logger = new Logger(this);
  urlAdmin: string;

  constructor(
    private httpClient: HttpClient,   
    private fonctionsService: FonctionsService,
    private apiUrlService: ApiUrlService, 
  ) {
    this.urlAdmin = fonctionsService.urlBuild(apiUrlService.urlDomaine, "admin");
  }

  /** Recharger les paramètres de backends */
  rechargerParamBacks() {
    let headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
    })
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlAdmin, "rechargerBacks"), { headers });
  }

  /**
   * Liste des backends paramétrés
   * @returns 
   */
  getBackendsExt() {
    this.logger.info("getBackendsExt");
    let headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
    })
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlAdmin,"backends"), { headers });
  }

  /**
   * Lit la version d'un backend externe
   * @param nomAtelier Nom de l'atelier XXX_XXXXXXX_XXXXXXXXXXX
   * @returns 
   */
  getBackendStatut(nomAtelier: string) {
    this.logger.info("getBackendsExt");
    const atlInfos = nomAtelier.split("_");
    let headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
      'siteId': atlInfos[0],
      'traitementId': atlInfos[1],
      'atelierId': atlInfos[2]
    })
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlAdmin, "backends", "statut"), { headers });
  }

  /**
   * Demande de rechargement des paramètres d'un backend externe
   * @param back 
   * @returns 
   */
  rechargerBackendExt(back: Backends) {
    const atlInfos = back.lstAteliers[0].split("_");
    let headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
      'siteId': atlInfos[0],
      'traitementId': atlInfos[1],
      'atelierId': atlInfos[2]
    })
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlAdmin, "backends", "recharger"), { headers });
  }
}
