<div class="modal-backdrop" [ngClass]="{'show': estAffiche}" ></div>
<div 
  [ngClass]="{'row': true, 'recherche': true, 'zone-cache': !estAffiche  }"
  [ngStyle]="styleFenetre"
>
  <div class="row row-externe">
    <div class="col-md-8 d-flex justify-content-center align-items-center">
      <input type="text"         
        *ngIf="sansToolTip()"
        #inputRecherche
        id="recherche" 
        name="recherche" 
        placeholder="Texte à rechercher"
        class="form-control alignement espacement largeur" 
        autofocus="true"
        tabindex="0"
        inputMaskDirective
        [(ngModel)]="recherche"
        (valider)="onRechercherClic()"
        (echappe)="onFermerClic()"
        (flecheBas)="onFlecheBas()"
        (flecheHaut)="onFlecheHaut()"
      />
      <input type="text" 
        #inputRecherche
        id="recherche" 
        name="recherche"
        #tooltip="ngbTooltip" 
        placeholder="Texte à rechercher"
        class="form-control alignement espacement largeur" 
        autofocus="true"
        tabindex="0"
        [(ngModel)]="recherche"
        (keypress)="toolTipFermer(tooltip)"
        inputMaskDirective
        (valider)="onRechercherClic()"
        (echappe)="onFermerClic()"
        (flecheBas)="onFlecheBas()"
        (flecheHaut)="onFlecheHaut()"
        [ngbTooltip]="rechToolTip"
        tooltipClass="tooltip-saisie"
        placement="right"
        triggers="hover"
        *ngIf="!sansToolTip()"
      />
    </div>
    <ng-template #rechToolTip>
      <span *ngIf="texteAide.length > 0" [innerHtml]="texteAide"></span>
    </ng-template>
    <div class="col-md-4 padding-0">
      <div class="row hauteur">
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <app-bouton 
            [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini'" 
            [iconClass]="'d-inline'" 
            [libelle]="'Rechercher'" 
            (clic)="onRechercherClic()" 
          ></app-bouton>
        </div>
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <app-bouton 
            [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini'" 
            [iconClass]="'d-inline'" 
            [libelle]="'Fermer'" 
            (clic)="onFermerClic()" 
          ></app-bouton>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-interne">
    <div class="col-md-9 hauteur">
      <ng-template #rechercheTpl />
    </div>
    <div #divBoutons class="col-md-3 d-flex flex-column justify-content-center">
      <div #bouton1>
        <app-bouton 
          *ngIf="paramBoutons[1]?.libelle?.length > 0"
          [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini btn-largeur100 btn-m5'" 
          [iconClass]="'d-inline'" 
          [libelle]="paramBoutons[1].libelle" 
          [tooltip]="paramBoutons[1].tooltip" 
          (clic)="onBoutonClic(1)" 
        />
      </div>  
      <div #bouton2>
        <app-bouton 
          *ngIf="paramBoutons[2]?.libelle?.length > 0"
          [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini btn-largeur100 btn-m5'" 
          [iconClass]="'d-inline'" 
          [libelle]="paramBoutons[2].libelle" 
          [tooltip]="paramBoutons[2].tooltip" 
          (clic)="onBoutonClic(2)" 
        />
      </div>
      <div #bouton3>
        <app-bouton 
          *ngIf="paramBoutons[3]?.libelle?.length > 0"
          [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini btn-largeur100 btn-m5'" 
          [iconClass]="'d-inline'" 
          [libelle]="paramBoutons[3].libelle" 
          [tooltip]="paramBoutons[3].tooltip" 
          (clic)="onBoutonClic(3)" 
        />
      </div>
      <div #bouton4>
        <app-bouton 
          *ngIf="paramBoutons[4]?.libelle?.length > 0"
          [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini btn-largeur100 btn-m5'" 
          [iconClass]="'d-inline'" 
          [libelle]="paramBoutons[4].libelle" 
          [tooltip]="paramBoutons[4].tooltip" 
          (clic)="onBoutonClic(4)" 
        />
      </div>
      <div #bouton5>
        <app-bouton 
          *ngIf="paramBoutons[5]?.libelle?.length > 0"
          [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini btn-largeur100 btn-m5'" 
          [iconClass]="'d-inline'" 
          [libelle]="paramBoutons[5].libelle" 
          [tooltip]="paramBoutons[5].tooltip" 
          (clic)="onBoutonClic(5)" 
        />
      </div>
      <div #bouton6>
        <app-bouton 
          *ngIf="paramBoutons[6]?.libelle?.length > 0"
          [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini btn-largeur100 btn-m5'" 
          [iconClass]="'d-inline'" 
          [libelle]="paramBoutons[6].libelle" 
          [tooltip]="paramBoutons[6].tooltip" 
          (clic)="onBoutonClic(6)" 
        />
        </div>
    </div>
  </div>
  <div class="row row-externe d-flex justify-content-center align-items-center">
    <div class="col-md-9">
      <input type="text" 
        #inputResultat
        name="resultat" 
        class="form-control alignement espacement largeur" 
        autofocus="true"
        tabindex="0"
        [disabled]="true"
      />
    </div>
    <div class="col-md-3">
      <div class="row  d-flex justify-content-center align-items-center hauteur">
        <app-bouton 
          [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini btn-largeur100'" 
          [iconClass]="'d-inline'" 
          [libelle]="'[+] Sélectionner'" 
          (clic)="onSelectionClic()" 
        ></app-bouton>
      </div>
    </div>
  </div>
</div>
<div #divInput2 class="input2 zone-cache">
  <input type="text" 
    #input2
    id="input2"
    data-id=""
    placeholder="Texte à localiser"
    class="form-control alignement espacement largeur" 
    tabindex="0"
    [(ngModel)]="recherche2"
    inputMaskDirective
    (valider)="onInput2()"
    (echappe)="onInput2Fermer()"
  />
</div>
<app-menu-popup
  [posX]="lstPosX"
  [posY]="lstPosY"
  [mapOptions]="mapListe"
  [afficher]="afficherListe"
  (select)="onListeSelect($event)"
></app-menu-popup>
