import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, inject, Input, NgModule, OnChanges, Output, QueryList, Renderer2, SimpleChanges, ViewChild, viewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BoutonComponent } from '../../../../bouton/bouton.component';
import { WebIndexService } from '../../../../../services/web-index.service';
import { Atelier } from '../../../../../models/Atelier.model';
import { RechercheBase } from '../../../../../models/RechercheBase';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { Parametre } from '../../../../../models/Parametre.model';
import { StandardTaylorimService } from '../../standard-taylorim.service';
import { DynamiqueService } from '../../../../../services/dynamique.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { InputMaskDirective } from '../../input-mask.directive';
import { InputMaskDirectiveService } from '../../input-mask-directive.service';
import { FormsModule, NgModel } from '@angular/forms';
import { MenuPopupComponent } from '../menu-popup/menu-popup.component';

@Component({
  selector: 'app-recherche-popup',
  standalone: true,
  imports: [
    FormsModule, 
    NgClass, NgStyle, 
    NgIf, 
    NgbTooltip,
    MenuPopupComponent,
    BoutonComponent,
    InputMaskDirective,
  ],
  providers: [
    provideNgxMask(),
    WebIndexService
  ],

  templateUrl: './recherche-popup.component.html',
  styleUrl: './recherche-popup.component.scss'
})
export class RecherchePopupComponent implements OnChanges, AfterViewInit  {
  inputMaskDirService = inject(InputMaskDirectiveService);

  @Input() estAffiche: boolean = false;
  @Input({required: true}) siteId: string = "";
  @Input({required: true}) traitementId: string = "";
  @Input({required: true}) atelier: Atelier = {} as Atelier;
  /** Map de paramètres lus dans la table WebIndex.Parametres */
  @Input({required: true}) mapParamWdx!: Map<string, Parametre>;
  /** Valeur saisie dans le input de saisie */
  @Input() saisie: string = "";   // Valeur saisie dans le input
  /** Map pour choix de valeur dans la lsite si besoin */
  @Input({required: true}) mapListe: Map<string, any> = new Map();

  /** Evenement levée sur le choix d'un élément trouvé */
  @Output() selection: EventEmitter<any> = new EventEmitter();
  /** Evenement spécifique pour demander au parent une action (Changement d'image par exemple) */
  @Output() action: EventEmitter<string> = new EventEmitter();
  /** Evenement levée sur erreur de configuration */
  @Output() onError: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputRecherche') inputRecherche: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  @ViewChild('inputRecherche') inputRecherche2!: ElementRef;
  @ViewChildren(InputMaskDirective) inputMaskDirectives!: QueryList<InputMaskDirective>;
  @ViewChild('inputResultat') inputResultat: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  @ViewChild('input2') input2: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  @ViewChild('divInput2') divInput2!: ElementRef;
  @ViewChild('rechercheTpl', { read: ViewContainerRef, static: true }) rechercheTplView!: ViewContainerRef;
  rechercheTplRef!: ComponentRef<any> | null;
  @ViewChild('divBoutons') divBoutons!: ElementRef;
  @ViewChild('bouton1') bouton1!: ElementRef;
  @ViewChild('bouton2') bouton2!: ElementRef;
  @ViewChild('bouton3') bouton3!: ElementRef;
  @ViewChild('bouton4') bouton4!: ElementRef;
  @ViewChild('bouton5') bouton5!: ElementRef;
  @ViewChild('bouton6') bouton6!: ElementRef;

  texteAide: string = "a initialiser";  // !!! Doit être initialiser dès le départ pour que ce soit le composant avec tooltip soit initialisé 
                                        // sous peine de changement du input si le texte d'aide change.

  /** Stockage des paramètres sur les boutons facultatifs */
  paramBoutons: any[6] = [{}, {}, {}, {}, {}, {}];
  /** Pour fixer position et taille de la fenetre de recherche */
  styleFenetre: any = {};
  /** Paramètre pour liste déroulante facultatf */
  lstPosX: number = 0;
  lstPosY: number = 0
  afficherListe: boolean = false;
  /** Service et fonction d'initialisation de la liste déroulante s'il y a */
  lstServiceInit: any = null;
  lstFonctionInit: string = "";
  /** Service et fonction de traitement de la sélection dans la liste déroulante s'il y a */
  lstServiceClic: any = null;
  lstFonctionClic: string = "";

  recherche: string = "";    // ngModel obligatoire pour inputMaskSaisie, même si pas exploité
  recherche2: string = "";   // ngModel obligatoire pour inputMaskSaisie, même si pas exploité
  /** Liste des résultats renvoyé par l'api de recherche */
  listeRes: RechercheBase[] = [];
  selectIndex: number = -1;
  /** Service et fonction de réponse au clic Rechercher */
  rechercheSrv: any;
  rechercheFn: string = "";

  constructor(
    private renderer: Renderer2,  // Utilisé dans fonctions dynamiques
    private fonctionsService: FonctionsService,
    private dynamiqueService: DynamiqueService,
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["mapParamWdx"]) { // Les paramètres sont chargés tardivement
      let styleFenetre = {
        top: "120px",
        left: "20px",
        height: "500px",
        width: "620px"
      };
      // Traitement des paramètres
      for( let [nom, param] of this.mapParamWdx) {
        if(nom.indexOf("_RECHERCHER") > -1 && param.valeur) {
          const { service: rechercheSrv, fonction: rechercheFn } = this.fonctionsService.litParametreDeService(param.valeur, "rienAFaire");
          this.rechercheSrv = rechercheSrv;
          this.rechercheFn = rechercheFn;
          const res = this.fonctionsService.testServiceFonction(
            nom + "=" + param.valeur,
            rechercheSrv, rechercheFn
          )
          if(!res.res) {
            setTimeout(() => { 
              this.onError.emit(res.err);
            }, 0);
            return
          }
        }
        if(nom.indexOf("_TOP") > -1 && param.valeur) {
          styleFenetre.top = param.valeur;
        }
        if(nom.indexOf("_LEFT") > -1 && param.valeur) {
          styleFenetre.left = param.valeur;
        }
        if(nom.indexOf("_HEIGHT") > -1 && param.valeur) {
          styleFenetre.height = param.valeur;
        }
        if(nom.indexOf("_WIDTH") > -1 && param.valeur) {
          styleFenetre.width = param.valeur;
        }
        if(nom.indexOf("_AIDE") > -1 && param.valeur) {
          this.texteAide = param.valeur;  // Modifie le dom !
        }        
        // Paramètrage des boutons
        if(nom.indexOf("_BOUTON1") > -1 && param.valeur) {
          this.paramBoutons[1] = this.paramBouton(param);
        }
        if(nom.indexOf("_BOUTON2") > -1 && param.valeur) {
          this.paramBoutons[2] = this.paramBouton(param);
        }
        if(nom.indexOf("_BOUTON3") > -1 && param.valeur) {
          this.paramBoutons[3] = this.paramBouton(param);
        }
        if(nom.indexOf("_BOUTON4") > -1 && param.valeur) {
          this.paramBoutons[4] = this.paramBouton(param);
        }
        if(nom.indexOf("_BOUTON5") > -1 && param.valeur) {
          this.paramBoutons[5] = this.paramBouton(param);
        }
        if(nom.indexOf("_BOUTON6") > -1 && param.valeur) {
          this.paramBoutons[6] = this.paramBouton(param);
        }
        
        if(nom.indexOf("_INIT") > -1 && param.valeur) {
          const mapVal = this.fonctionsService.str2Map(param.valeur);
          const lpx = mapVal.get("lstPosX") ?? "";
          if(lpx.length > 0) {
            this.lstPosX = parseInt(lpx);
          }
          const lpy = mapVal.get("lstPosY") ?? "";
          if(lpy.length > 0) {
            this.lstPosY = parseInt(lpy);
          }

          let srvFn = mapVal.get("srvFnInit") ?? "";
          if(srvFn) {
            const resSf = this.prechargerSrvFn("srvFnInit", mapVal.get("srvFnInit") ?? "", "rienAFaire");
            this.lstServiceInit = resSf.service;
            this.lstFonctionInit = resSf.fonction;
          }
          srvFn = mapVal.get("srvFnClic") ?? "";
          if(srvFn) {
            const resSf = this.prechargerSrvFn("srvFnClic", mapVal.get("srvFnClic") ?? "", "rienAFaire");
            this.lstServiceClic = resSf.service;
            this.lstFonctionClic = resSf.fonction;
          }

        }
      }
      this.styleFenetre = styleFenetre;
      
      // Traitement touches de raccourcis
      if(this.inputMaskDirectives) {
        const inputDirective = this.inputMaskDirectives.find(directive => directive.nativeElement.id === 'recherche');

        if(inputDirective) {
          if (inputDirective.plus) {
            inputDirective.plusObserved(true)
            inputDirective.plus.subscribe(
              () => {
                setTimeout(() => {
                  this.onSelectionClic();                    
                }, 0);
              }
            );
          }
          for(let i = 1; i <= 6; i++ ) {
            switch(this.paramBoutons[i]?.touche?.toLowerCase() ?? "") {
              case "f4":
                if (inputDirective.f4) {
                  inputDirective.f4Observed(true)
                  inputDirective.f4.subscribe(
                    () => {
                      setTimeout(() => {
                        this.onBoutonClic(i);                    
                      }, 0);
                    }
                  );
                }
                break;
              case "f5":
                if (inputDirective.f5) {
                  inputDirective.f5Observed(true)
                  inputDirective.f5.subscribe(
                    () => {
                      setTimeout(() => {
                        this.onBoutonClic(i);                    
                      }, 0);
                    }
                  );
                }
                break;
              case "f6":
                if (inputDirective.f6) {
                  inputDirective.f6Observed(true)
                  inputDirective.f6.subscribe(
                    () => {
                      setTimeout(() => {
                        this.onBoutonClic(i);                    
                      }, 0);
                    }
                  );
                }
                break;
              case "f7":
                if (inputDirective.f7) {
                  inputDirective.f7Observed(true)
                  inputDirective.f7.subscribe(
                    () => {
                      setTimeout(() => {
                        this.onBoutonClic(i);                    
                      }, 0);
                    }
                  );
                }
                break;
              case "f8":
                if (inputDirective.f8) {
                  inputDirective.f8Observed(true)
                  inputDirective.f8.subscribe(
                    () => {
                      setTimeout(() => {
                        this.onBoutonClic(i);                    
                      }, 0);
                    }
                  );
                }
                break;
            }
          }
        }
      }
   
      // Initialisation liste si y a
      if(this.lstFonctionInit) {
        this.lstServiceInit[this.lstFonctionInit](this);
      }
    }

  }

  ngAfterViewInit(): void {
    setTimeout(() => {  // Eviter ng0100
      this.rechercheTplRef = this.dynamiqueService.chargerComposant( 
        this.mapParamWdx.get(this.atelier.id + "_TRTEMPLATE")?.valeur ?? "RechTplStd" , this.rechercheTplView
      );
      this.rechercheTplRef!.instance.clic.subscribe((rechBase: RechercheBase) => {
        this.inputResultat.nativeElement.value = rechBase.valeur;
        this.inputRecherche.nativeElement.focus();
      });
    }, 0);
  }

  /** Calcul si affichage tooltip sur input de recherche */
  sansToolTip() {
    return this.texteAide.length == 0;
  }

  /** Fermeture forcée du tooltip*/
  toolTipFermer(tooltip: NgbTooltip) {
    tooltip.close();
  }

  /** Abandon de la recherche */
  onFermerClic() {
    this.estAffiche = false;
    this.selection.emit(null);
  }

  /** Lancement de la recherche */
  onRechercherClic() {
    this.rechercheSrv[this.rechercheFn](this);
  }

  /** Action sur touche fleche bas */
  onFlecheBas() {
    this.rechercheTplRef?.instance.selectSuivant()
  }

  /** Action sur touche fleche haut */
  onFlecheHaut() {
    this.rechercheTplRef?.instance.selectPrecedent()
  }

  /** Action sur sélection d'un résultat */
  onSelectionClic() {
    this.selection.emit(this.inputResultat.nativeElement.value);
    this.estAffiche = false;
  }

  /** Action sur clic sur boutons spécifique optionnels */
  onBoutonClic(no: number) {
    setTimeout( () => {
      this.paramBoutons[no].srvClic[this.paramBoutons[no].fnClic](this);
    }, 0)
  }

  /** Validation de la saisie de l'input secondaire */
  onInput2() {
    if(this.input2.nativeElement.getAttribute("data-id") == "1") {
      this.paramBoutons[1].srvSpecif1[this.paramBoutons[1].fnSpecif1](this);
    }
  }
    
  /** Fermeture de l'input secondaire (localisation) */
  onInput2Fermer() {
    this.divInput2.nativeElement.classList.add("zone-cache");
    this.inputRecherche.nativeElement.focus();
  }

  /** Réponse au clic sur un élément de la liste secondaire */
  onListeSelect(select: string) {
    if(this.lstFonctionClic) {
      this.lstServiceClic[this.lstFonctionClic](this, select);
    }
  }

  /** Affichage du popup de recherche */
  afficher() {
    this.estAffiche = true;
    setTimeout(() => {
      this.inputRecherche.nativeElement.focus();
    }, 0);
  }

  /**
   * Chargment de service et fonction paramétrés
   * @param srvFnNom Nom du paramètre affiché dans le message d'erreur
   * @param srvFn Paramètre sous la forme NomService.NomFonction
   * @param fnDefaut Fonction par défaut
   * @returns { Service, fonction }
   */
  prechargerSrvFn(srvFnNom: string, srvFn: string, fnDefaut: string) {
    const { service: service, fonction: fonction } = this.fonctionsService.litParametreDeService(srvFn, fnDefaut);
    const res = this.fonctionsService.testServiceFonction(
        srvFnNom + "=" + srvFn,
        service, fonction
    );
    if(!res.res) {
      setTimeout(() => { 
        this.onError.emit(res.err);
      }, 0 );
      return {
        service: null,
        fonction: ""
      };
    }
    return { 
      service: service,
      fonction: fonction
    }
  }

  /**
   * Convertion Parametres en paramBouton
   * @param param Parametre
   * @returns objet paramBouton
   */
  paramBouton(param: Parametre) {
    return  {
      libelle: param.touche.length > 0 ? `[${param.touche}] ${param.specifique}` : param.specifique,
      touche: param.touche,
      tooltip: param.tooltip,
      srvClass: param.services[0],
      fnClass: param.fonctions[0],
      srvClic: param.services[1],
      fnClic: param.fonctions[1],
      srvSpecif1: param.services[2],
      fnSpecif1: param.fonctions[2],
      srvSpecif2: param.services[3],
      fnSpecif2: param.fonctions[3],
      srvSpevif3: param.services[4],
      fnSpecif3: param.fonctions[4],
    };
  }


}

