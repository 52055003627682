import { KeyValuePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bouton-menu',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgIf,
    KeyValuePipe,
    NgbDropdown,
    NgbDropdownMenu, 
    NgbDropdownItem,
    NgbDropdownToggle,
    NgbTooltip,
  ],
  templateUrl: './bouton-menu.component.html',
  styleUrl: './bouton-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoutonMenuComponent {
  @Input() clsBouton: string = "";
  @Input() clsMenu: string = "";
  @Input() clsOption: string = "";
  @Input() libelle: string = "";
  @Input() selected: string = "";
  @Input() lstDesactives: string = "";  // Liste des options desactivés séparés par #
  @Input() tooltip: string = "";
  @Input() tooltipPlacement: string = "";
  @Input({ required: true }) mapOptions: Map<string, string> = new Map();
  
  @Output() select: EventEmitter<string[]> = new EventEmitter<string[]>(); 

  estOuvert: boolean = false;

  sansToolTip() {
    return this.tooltip.length == 0;
  }

  dropdownChange(estOuvert: boolean) {
    this.estOuvert = estOuvert;  
  }

  optionClass(enCours: any) {
    if(this.selected == enCours) {
      return this.clsOption + " selected";
    } else {
      return this.clsOption;
    }
  }

  optionDesactiver(enCours: any) {
    if((`#${ this.lstDesactives }#`).indexOf(`#${ enCours }#`) > -1) {
        return true;
      } else {
        return false;
    }

  }

  optionClic(option: string, libelle: string) {
    this.select.emit([option, libelle]);
  }

  cacherTooltip(toolTip: NgbTooltip) {
    toolTip.close();
  }

}
