<div class="">
  <div class="row zones-saisies">
    <div [classList]="atelier.style2.length == 0 ? 'col-md-3' : atelier.style2"></div>
    <div class="col-md-4 champ-date">
      <label class="libelle">{{ champLib }}</label>
      <input type="text" 
        #inputSaisie1
        name="saisie1" 
        class="form-control espacementinput-date" 
        autofocus
        tabindex="0"
        [mask]="champMask"
        [showMaskTyped] = "champMaskShow"
        [(ngModel)]="saisie1"
        [value]="saisie1 | dynamicPipe: nomPipe()"
        [maxLength]="champLength"
        inputMaskDirective
        (valider)="onTraiterEntree($event)"
        (echappe)="onTraiterEchappe($event)"
        (slash)="onTraiterSlash($event)"
        />
    </div>
  </div>
</div>