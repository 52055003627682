import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { BoutonComponent } from '../../bouton/bouton.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-titre-std',
  standalone: true,
  imports: [
    NgClass,
  ],
  templateUrl: './titre-std.component.html',
  styleUrl: './titre-std.component.scss'
})
export class TitreStdComponent implements OnInit {
  @Input() titre: string = "";
  @Input() classe: string = "";

  constructor(
    private chgDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.chgDetector.detectChanges();
  }

  clsTitre() {
    return "titre " + this.classe;
  }
}
