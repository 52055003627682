import { Component, ElementRef, EventEmitter, inject, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputMaskDirectiveService } from '../../input-mask-directive.service';
import { Atelier } from '../../../../../models/Atelier.model';
import { InputMaskDirective } from '../../input-mask.directive';
import { DynamiqueService } from '../../../../../services/dynamique.service';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { tap, withLatestFrom } from 'rxjs';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { WebIndexService } from '../../../../../services/web-index.service';

@Component({
  selector: 'app-saisie-date-heure-std',
  standalone: true,
  imports: [
    NgxMaskDirective,
    FormsModule,
    //NgbTooltip,
    InputMaskDirective
  ],
  providers: [
    provideNgxMask(),
    WebIndexService,
  ],
  templateUrl: './saisie-date-heure-std.component.html',
  styleUrl: './saisie-date-heure-std.component.scss'
})
export class SaisieDateHeureStdComponent implements OnInit {
  inputMaskDirService = inject(InputMaskDirectiveService);

  @Input({required: true}) siteId: string = "";
  @Input({required: true}) traitementId: string = "";
  @Input({required: true}) atelier: Atelier = {} as Atelier;
  @Input({required: false}) objAtl: Map<string, any> = new Map();
  @Output() onImageChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onValidation: EventEmitter<boolean> = new EventEmitter();
  @Output() onMessage: EventEmitter<string> = new EventEmitter();
  @Output() onEchappe: EventEmitter<string> = new EventEmitter();
  @Output() onSlash: EventEmitter<string> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputMaskDirective') inputMaskDirective!: InputMaskDirective;
  @ViewChild('inputSaisied', { static: true }) inputSaisied: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  @ViewChild('inputSaisieh', { static: true }) inputSaisieh: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;
  
  dateSaisie: string = "";
  heureSaisie: string = "";
//  initService: any = null;
//  initFn: string = "";
  controleService: any = null;
  controleFn: string = "";
  
  constructor(
    private injector: Injector,
    private dynamiqueServices: DynamiqueService,
    private fonctionsService: FonctionsService,
  ) {
   }

   ngOnInit(): void {
    // Préchargement controle
    const { service: controleService, fonction: controleFn } = this.fonctionsService.litParametreDeService(this.atelier.fonctionCtrl, "rienAFaireStr");
    this.controleService = controleService;
    this.controleFn = controleFn;
   }
   
  /**
   * Traitement modification d'image affiché
   * @param bSuivante True pour afficher l'image suivante, false pour afficher l'image courante
   */
  onImageSuivante(bSuivante: boolean) {
    this.onImageChange.emit(bSuivante);
  }

  /**
   * Traitement touche Esc 
   * @param event (pas utilisé)
   */
  onTraiterEchappe(event: string) {
    this.onEchappe.emit(event);
  }

  /**
   * Traitement après appui sur Entrée
   * @param valeur Valeur saisie 
   */
  onTraiterEntreed(valeur: string) {
    this.inputSaisieh.nativeElement.focus();
  }

  /**
   * Traitement touche vers le haut
   * @param event 
   */
  onFlecheHautd(event: any) {
    this.inputSaisieh.nativeElement.focus();
  }

  /**
   * Traitement touche vers le base
   * @param event 
   */
  onFlecheBasd(event: any) {
    this.inputSaisieh.nativeElement.focus();
  }

  /**
   * Action quand un caractère est saisi
   */
  yaSaisied() {
    // On efface le message d'erreur
    this.onMessage.emit("");
  }

  

  /**
   * Traitement après appui sur Entrée
   * @param valeur Valeur saisie 
   */
  onTraiterEntreeh(valeur: string) {
    if(this.controleFn.length > 0) {
      const msg: string = this.controleService[this.controleFn](this);
      if(msg.length == 0) {
        this.onValidation.emit(true);
      } else {
        this.onImageChange.emit(false); // On réaffiche l'image
        this.onMessage.emit(msg);
      }  
    }
  }

  /**
   * Traitement touche vers le haut
   * @param event 
   */
  onFlecheHauth(event: any) {
    this.inputSaisied.nativeElement.focus();
  }

  /**
   * Traitement touche vers le base
   * @param event 
   */
  onFlecheBash(event: any) {
    this.inputSaisied.nativeElement.focus();
  }

  /**
   * Traitement après appui sur / pour revenir au document précédent
   */
  onTraiterSlash(event: any) {
    this.onSlash.emit("");
  }
  
  /**
   * Action quand un caractère est saisi
   */
  yaSaisieh() {
    // On efface le message d'erreur
    this.onMessage.emit("");
  }

  focus() {
    this.inputSaisied.nativeElement.focus();
  }

  /**
   * Mise à jour des objets de paramétrage par le parent
   * @param objAtl 
   */
  setObjAtl(objAtl: Map<string, any>) {
    this.objAtl = objAtl;
  }

  razSaisie() {
    this.inputMaskDirService?.inputMaskDir$.pipe(
      withLatestFrom(this.inputMaskDirService?.inputMaskDir$),
      tap(([_, inpDir]) => {
        inpDir?.razValeur();
      })
    ).subscribe();    
  }

  /**
   * Fonction appelé lors de l'insertion dans l'historique
   * @returns Valeur saisie affiché dans l'historique
 */
  historique(): string {
    return this.dateSaisie + " " + this.heureSaisie;
  }
  

}
