<div>
  <div id="entete" class="row">
    <app-entete 
      [titreComponent]="titreComponent"
      [titreParams]="{'titre': 'Administration', 'classe': 'titre-admin'}"
      [identifie]="true"></app-entete>
  </div>
  <div id="corps" class="row corps text-center">
    <h2>Backend WebIndex</h2>
    <div class="row backend">
      <div class="col-md-3 gras">
        <h5>{{ urlBack }}</h5>
      </div>
      <div class="col-md-6">
        <app-api-statut
          [version]="apiVersion"
        />
      </div>
      <div class="col-md-3">
        <app-bouton
          [btnClass]="'btn-primary'"
          [iconClass]="''"
          [libelle]="'Recharger Backends'"
          [tooltip]="toolTipApi"
          (clic)="onRechargerBack()"
        ></app-bouton>
      </div>
    </div>
    <h2 class="marge-haut">Backends Externes</h2>
    <div *ngFor="let back of lstBackends; let i = index"
      class="row backend"
    >
    <div class="col-md-3">
      <h5>{{ back.url }}</h5>
    </div>
    <div class="col-md-3">
      <app-api-statut
        #apiStatut
        [version]="lstVersions[i]"
        [toolTip]="''"
        [attr.data-index]="i"
      />
    </div>
    <div class="col-md-3">
      <div ngbDropdown>
        <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>Ateliers</button>
        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBasic1">
          <div ngbDropdownItem
            *ngFor="let atl of back.lstAteliers; let j = index"
          >
            {{ atl }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <app-bouton
        #btnBackExtRecharge
        [attr.data-index]="i"
        [btnClass]="'btn-primary'"
        [iconClass]="''"
        [libelle]="'Recharger Ateliers'"
        (clic)="onBackExtRecharger( i )"
        [tooltip]="'Demande au backend externe de recharger ses paramètres'"
      ></app-bouton>
    </div>
  </div>
  <div class="row marge-haut">
    <app-bouton
      [btnClass]="'btn-primary'"
      [iconClass]="''"
      [libelle]="'Fermer la page'"
      (clic)="onFermer()"
    ></app-bouton>
  </div>
</div>
