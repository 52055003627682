import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

export class Logger {
  public static ALL_LEVELS = "INFO, WARN, DEBUG,";
  public static MINIMAL = "INFO, WARN";
  public static PROD = "WARN";

  private levels: string = "INFO, WARN, DEBUG,";
  private composant: any;

  constructor(
      composant : any
  ) {
    if(composant?.selector) {
      const nomParam = "logLevel-" + composant?.selector;
      let param = environment[nomParam as keyof typeof environment]; 
      if (typeof param === 'string') {
        if(param) {
          this.levels = param;
        } else {
          this.levels = environment.logLevel;
        }
      }
    }
    this.composant = composant;
    //this.levels = levels;
  }

  info(message: string, objet?: any) {
    if(this.levels.indexOf("INFO") > -1) {
      console.log("INFO: " + this.composant.constructor.name + " -> " + message, objet != undefined ? objet : " ");
    }
  }

  warn(message: string, objet?: any) {
    if(this.levels.indexOf("WARN") > -1) {
      console.warn("WARNING: " + this.composant.constructor.name + " -> " +message, objet != undefined ? objet : " ");
    }
  }
  
  error(message: string, objet?: any) {
    console.error("ERROR: " + this.composant.constructor.name + " -> " +message, objet != undefined ? objet : " ");
  }
  
  debug(message: string, objet?: any) {
    if(this.levels.indexOf("DEBUG") > -1) {
      console.debug("DEBUG: " + this.composant.constructor.name + " -> " +message, objet != undefined ? objet : " ");
    }
  }
  
}