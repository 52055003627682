import { AuthConfig } from 'angular-oauth2-oidc';
import { version } from './version';

declare const require: any; // Perso, je ne comprend pas ce code

export const environment = {
  ...version,
  nom: "Recette",
  webindexApiUrlBases: {
    // Url dev
    "http://localhost:4200": "http://localhost:8080/webindex-api/",
    // Url ineterne local directe
    "http://webindxapp1r.zone.local:9001": "http://webindxapp1r.zone.local:9002/webindex-api/",
    // Url par front interne
    "https://webindex-rct.docapost.tech": "https://webindex-rct.docapost.tech/webindex-api/",
    // Url par front externe ??
    "https://webindex.by-docapost.com": "https://webindex.by-docapost.com/webindex-api/",
  },
  apiTimeout: 2000,
  logLevel: "INFO, WARN, DEBUG,",
  'trois-zones-logLevel': "INFO, WARN, DEBUG,",
  
  
};

// Paramètres keycloak
export const oAuthConfig: AuthConfig = {
  issuer: "https://hub.eco6.tech/auth/realms/RCT_DEMO",
  clientId: "RCT_WEBINDEX",
  scope: "openid email",
  responseType: "code",
  logoutUrl: "https://hub.eco6.tech/auth/realms/RCT_DEMO/protocol/openid-connect/logout",
  redirectUri: "keycloakToken",     // Domaine complété à l'initialisation
  showDebugInformation: false,
};

/*
// Keycloak prod pour test pre prod
export const oAuthConfig: AuthConfig = {
  issuer: "https://idp.docapost.io/auth/realms/APP_WEBINDEX",
  clientId: "WEBINDEX",
  scope: "openid email roles",
  responseType: "code",
  logoutUrl: "https://idp.docapost.io/auth/realms/APP_WEBINDEX/protocol/openid-connect/logout",
  redirectUri: "keycloakToken",     // Domaine complété à l'initialisation
  showDebugInformation: true,
};
*/

