import { AfterViewInit, Component, ComponentRef, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AtlTroisZonesComponent } from '../../ateliers/standard/ateliers/atl-trois-zones/atl-trois-zones.component';
import { EnteteComponent } from '../../entetes/entete/entete.component';
import { Atelier } from '../../../models/Atelier.model';
import { Logger } from '../../../models/Logger.model';
import { DynamiqueService } from '../../../services/dynamique.service';
import { filter, take } from 'rxjs';
import { WebIndexService } from '../../../services/web-index.service';
import { DialoguesService } from '../../dialogues/dialogues.service';
import { ATELIER_ID, DATE_TRAITEMENT, SITE_ID, TRAITEMENT_ID } from '../../../constantes';
import { FonctionsService } from '../../../services/fonctions.service';
import { TitreStdComponent } from '../../entetes/titre-std/titre-std.component';
import { TitreAtelierComponent } from '../../entetes/titre-atelier/titre-atelier.component';

/**
 * Page de saisie standard
 */
@Component({
  selector: 'app-page-saisie-std',
  standalone: true,
  templateUrl: './page-saisie-std.component.html',
  styleUrl: './page-saisie-std.component.scss',
  imports: [
    //RouterOutlet, 
    //AtlTroisZonesComponent, 
    EnteteComponent,
    //TitreAtelierComponent
  ]
})
export class PageSaisieStdComponent {
  siteId: string = "";
  traitementId: string = "";
  atelier: Atelier = {} as Atelier;
  dateTraitement: string = "";  // yyyyMMjj
  titrePage: string = "";
  nbImages: number = 0;
  nbImagesMax: number = 0;
  lstBoutons: any = [];
  titreParams: Record<string, unknown> = {};
  majTitre: Record<string, unknown> = {};
  
  @ViewChild('atelierTpl', { read: ViewContainerRef, static: true})
  //** vue sur le template atelier */
  atelierView!: ViewContainerRef;
  //** référence sur le composant atelier */
  atelierRef!: ComponentRef<any> | null;
  titreComponent = TitreAtelierComponent;
  logger: Logger = new Logger(this);

  constructor( 
    private router: Router,
    private dynamiqueService: DynamiqueService,
    private dialoguesServiceService: DialoguesService,
    private webIndexService: WebIndexService,
    private fonctionsService: FonctionsService
  ) { 
    this.lstBoutons = [
      { libelle: "Changer d'atelier",
        id: "chgAtelier"
      }
    ];
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1) // Only take the first event
    )
    .subscribe((event) => {
      const navigation = this.router.getCurrentNavigation();
      if (navigation && navigation.extras.state) {
        this.logger.info("naviagation extras state", navigation.extras.state);
        this.siteId = navigation.extras.state["siteId"];
        this.traitementId = navigation.extras.state["traitementId"];
        this.atelier = navigation.extras.state["atelier"];
        this.dateTraitement = navigation.extras.state["dateTraitement"];
        sessionStorage.setItem(SITE_ID, this.siteId);
        sessionStorage.setItem(TRAITEMENT_ID, this.traitementId);
        sessionStorage.setItem(ATELIER_ID, JSON.stringify(this.atelier));
        sessionStorage.setItem(DATE_TRAITEMENT, this.dateTraitement);
      } else {  // cas refresh
        this.siteId = sessionStorage.getItem(SITE_ID) ?? "";
        this.traitementId = sessionStorage.getItem(TRAITEMENT_ID) ?? "";
        this.atelier = JSON.parse(sessionStorage.getItem(ATELIER_ID) ?? "{}") as Atelier;
        this.dateTraitement = sessionStorage.getItem(DATE_TRAITEMENT) ?? "";
      }
    });
  
  }

  ngOnInit() {
    this.logger.info("saisiepage ngOnInit", [this.siteId, this.traitementId, this.atelier])
    this.titreParams = {
      ...this.titreParams,  // Pour changer la référence de l'objet
      'siteId': this.siteId, 
      'traitementId': this.traitementId, 
      'atelierId': this.atelier.id, 
      'atelierLib': this.atelier.libelle, 
      'dateTraitement': this.dateTraitement, 
      'nbASaisir': this.nbImages, 
      'nbASaisirMax': this.nbImagesMax 
    };

    const cpm = this.atelier.composant.split("#");
    this.atelierRef = this.dynamiqueService.chargerComposant(cpm[1], this.atelierView);
    this.atelierRef!.instance.siteId = this.siteId;
    this.atelierRef!.instance.traitementId = this.traitementId;
    this.atelierRef!.instance.atelier = this.atelier;
    this.atelierRef!.instance.dateTraitement = this.dateTraitement;

    this.atelierRef!.instance.changerAtelier.subscribe((majStock: boolean) => {
      this.onMenuOptionClick("chgAtelier");
    });

    this.atelierRef!.instance.afficherStock.subscribe(() => {
      this.webIndexService.getStock(this.siteId, this.traitementId, this.atelier, this.dateTraitement).subscribe({
        error: (err) => {
          this.logger.debug("getStock err", err);
          this.majTitrePage(-1);
        },
        next: (res: any) => {
          if(res != undefined) {
            this.nbImages = res.nbASaisir;
            this.nbImagesMax = Math.max(this.nbImages, this.nbImagesMax);
            this.majTitrePage(this.nbImages);
          } else {
          }
        },
        complete: () => {},
      });
    });

    this.atelierRef!.instance.getStockReel.subscribe(() => {
      this.webIndexService.getStockReel(this.siteId, this.traitementId, this.atelier, this.dateTraitement).subscribe({
        error: (err) => {
          this.logger.debug("getStockReel err", err);
          this.majTitrePage(-1);
        },
        next: (res: any) => {          
          if(res != undefined) {
            this.nbImages = res.nbASaisir;
            this.nbImagesMax = Math.max(this.nbImages, this.nbImagesMax);
            this.majTitrePage(this.nbImages);
          } else {
          }
        },
        complete: () => {},
      });
    });

    // Réponse à demande de décrémentation du stock (pour mise à jour affichage immédiate après saisie)
    // TODO Faut aller en base ????
    this.atelierRef!.instance.decStock.subscribe(() => {
      if(this.nbImages > 0) {
        this.nbImages --;
        this.majTitrePage(this.nbImages);
      }
    });
    
  }

  /**
   * Réponse à un click sur un bouton
   * @param id id du bouton
   */
  onMenuOptionClick(id: string) {
    if(id == "chgAtelier") {
      // liberer images avant de quitter
      this.atelierRef!.instance.imagesLiberer();
      this.webIndexService.majStock(this.siteId, this.traitementId, this.atelier, this.dateTraitement).subscribe({
        error: (err) => {
          this.logger.debug("majStock err", err);
          this.dialoguesServiceService.messageBox(
            `Saisie standard ${this.atelier.libelle}`, 
            "Oups, une erreur est survenue dans la mise à jour du stock", 
            () => {
            }, 2000
          );
        },
        next: (res: any) => {
          this.logger.debug("majStock res", res);
        },
        complete: () => {},
      });

      this.router.navigate(["choix"]);
    }
  }

  /**
   * Modifie les Input avec titreParams 
   * @param nbImages nouvelle valeur de nbASaisir
   */
  majTitrePage(nbImages: number) {
    this.titreParams = {
      ...this.titreParams,  // Pour changer la référence de l'objet
      'nbASaisir': nbImages, 
      'nbASaisirMax': this.nbImagesMax 
    };
  }
}
