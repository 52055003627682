<div class="explorer">
  <div class="row div-image">
      <img #imgView
        class="image"
        [alt]="imageId"
        [src]="image64"
      />
  </div>
  <div class="row child-div">
    <div class="d-flex boutons">
      <app-bouton 
        #btnFermer
        [btnClass]="'btn btn-secondary btn-mini'" 
        [iconClass]="'d-inline, icon-x-circle'" 
        [libelle]="'Fermer'" 
        (clic)="onFermer()" 
        [tooltip]="'Fermer la fenêtre'"
      ></app-bouton>
      <app-bouton 
        #btnFlecheHaut
        [btnClass]="clsBtnFlecheHaut" 
        [iconClass]="'d-inline, icon-arrow-up-circle'" 
        [libelle]="'Précédente'" 
        (clic)="onFlecheHaut()" 
        [tooltip]="'Visualiser l\'image précédente'"
      ></app-bouton>
      <app-bouton 
        #btnRecto
        [btnClass]="'btn btn-secondary btn-mini'" 
        [iconClass]="'d-inline, icon-refresh-cw'" 
        [libelle]="'Recto/Verso'" 
        (clic)="onRectoVerso()" 
        [tooltip]="'Visualiser l\'autre face'"
      ></app-bouton>
      <app-bouton
        #btnFlecheBas 
        [btnClass]="clsBtnFlecheBas" 
        [iconClass]="'d-inline, icon-arrow-down-circle'" 
        [libelle]="'Suivante'" 
        (clic)="onFlecheBas()" 
        [tooltip]="'Visualiser l\'image suivante'"
      ></app-bouton>
    </div>
  </div>
</div>