import { Injectable, Type } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { fromEvent, Subscription } from 'rxjs';
import { DlgGenericModalComponent } from './dlg-generic-modal/dlg-generic-modal.component';
import { DlgTitreComponent } from './dlg-titre/dlg-titre.component';
import { DlgProfilCorpsComponent } from './dlg-profil-corps/dlg-profil-corps.component';
import { DlgFermerBoutonsComponent } from './dlg-fermer-boutons/dlg-fermer-boutons.component';
import { DlgParams } from './dlg-params';
import { WebIndexService } from '../../services/web-index.service';
import { DlgHtmlCorpsComponent } from './dlg-html-corps/dlg-html-corps.component';
import { DlgDeuxBoutonsComponent } from './dlg-deux-boutons/dlg-deux-boutons.component';

/**
 * Fonctions d'affichage de messageBox ou Dialogues
 */
@Injectable({
  providedIn: 'root'
})
export class DialoguesService {

  constructor(
    private modalService: NgbModal,
  ) { }

  /**
   * Ouverture d'un popup d'information
   * @param titre Titre de la fenetre
   * @param message message html à afficher
   * @param callBackClose Callback appelé à la fermeture
   * @param timeout pour fermeture automatique
   */
  messageBox(titre: string, message: string, callBackClose: Function, timeout: number, style?: string) {
    if(!style) {
      style = "modal-std";
    }
    // Instanciation des composants dynamiques
    DlgGenericModalComponent.prototype.titreComponentType = DlgTitreComponent;
    DlgGenericModalComponent.prototype.corpsComponentType = DlgHtmlCorpsComponent;
    DlgGenericModalComponent.prototype.btnsComponentType = DlgFermerBoutonsComponent;

    // Ouverture du dialogue
    const dlg = this.modalService.open(DlgGenericModalComponent, { centered: true, windowClass: style });
    // Passage des paramètres
    const dlgParams: DlgParams = new DlgParams()
      .setTitre(titre)
      .setTitreIconClass('icon-info')
      .setCloseCallback(callBackClose)
      .setParam("html", message)
      .setParam("timeout", timeout);
    dlg.componentInstance.dlgParams = dlgParams;
    
    return dlg;
  }

  /**
   * Affichage d'une boite de dialogue avec 2 boutons Oui/Non
   * @param titre Titre du popup
   * @param message Message à afficher
   * @param defaut Bouton sélectionné par défaut
   * @param callBackAction Fontion de traitement de la réponse fonction(event, dlg)
   * @param timeout Timeout s'il y a, 0 sinon 
   * @returns 
   */
  dialogBoxOuiNon(titre: string, message: string, defaut: number, callBackAction: Function, timeout: number, style?: string) {
    if(!style) {
      style = "modal-std";
    }
    return this.dialogBox(titre, message, 
      "1 - Oui", "3 - Non", defaut, 
      callBackAction, 
      timeout,
      style
    );
  }

  /**
   * Affichage d'une boite de dialogue avec 2 boutons Oui/Non, avec passage d'un paramètre
   * @param titre Titre du popup
   * @param message Message à afficher
   * @param defaut Bouton sélectionné par défaut
   * @param callBackAction Fontion de traitement de la réponse fonction(event, dlg)
   * @param param Paramètre qui sera envoyé au callback
   * @param timeout Timeout s'il y a, 0 sinon 
   * @returns 
   */
  dialogBoxOuiNon2(titre: string, message: string, 
    defaut: number, 
    callBackAction: Function, 
    param: any,
    timeout: number, style?: string
  ) {
    if(!style) {
      style = "modal-std";
    }
    return this.dialogBox2(titre, message, 
      "1 - Oui", "3 - Non", defaut, 
      callBackAction, 
      param,
      timeout,
      style
    );
  }

  /**
   * Affichage d'une boite de dialogue générique avec 2 boutons
   * @param titre Titre du popup
   * @param message Message à afficher
   * @param btnLib1 Libellé du premier bouton. Le premier caractère est la touche raccourci
   * @param btnLib2 Libellé du deuxième bouton. Le premier caractère est la touche raccourci
   * @param defaut Bouton sélectionné par défaut
   * @param callBackAction Fontion de traitement de la réponse fonction(event, dlg)
   * @param timeout Timeout s'il y a, 0 sinon //TODO
   * @returns 
   */
  dialogBox(titre: string, message: string, 
    btnLib1: string, btnLib2: string, defaut: number, 
    callBackAction: Function, 
    timeout: number,
    style?: string
  ) {
    if(!style) {
      style = "modal-std";
    }
    // Instanciation des composants dynamiques
    DlgGenericModalComponent.prototype.titreComponentType = DlgTitreComponent;
    DlgGenericModalComponent.prototype.corpsComponentType = DlgHtmlCorpsComponent;
    DlgGenericModalComponent.prototype.btnsComponentType = DlgDeuxBoutonsComponent;

    // Ouverture du dialogue
    const dlg = this.modalService.open(DlgGenericModalComponent, { centered: true, windowClass: style });
    /* {windowClass: 'custom-modal' } pour modifier postion
    .custom-modal {
      top: 20%;  Ajustez cette valeur pour changer la position verticale 
      left: 30%;  Ajustez cette valeur pour changer la position horizontale 
      transform: translate(-30%, -20%); Ajustez cette valeur pour centrer la fenêtre par rapport à la position définie 
    }
    */
    // Passage des paramètres
    const dlgParams: DlgParams = new DlgParams()
      .setTitre(titre)
      .setTitreIconClass('icon-help-circle')
      .setCloseCallback(callBackAction)
      .setParam("html", message)
      .setParam("timeout", timeout)
      .setParam("btnLib1", btnLib1)
      .setParam("btnLib2", btnLib2)
      .setParam("defaut", defaut)
      ;
    dlg.componentInstance.dlgParams = dlgParams;
    dlg.componentInstance.btnActionEventEmit.subscribe(
      (event: any) => {
        callBackAction(event, dlg);
      }
    );    
    return dlg;
  }

  /**
   * Affichage d'une boite de dialogue générique avec 2 boutons, avec passage d'un paramètre
   * @param titre Titre du popup
   * @param message Message à afficher
   * @param btnLib1 Libellé du premier bouton. Le premier caractère est la touche raccourci
   * @param btnLib2 Libellé du deuxième bouton. Le premier caractère est la touche raccourci
   * @param defaut Bouton sélectionné par défaut
   * @param callBackAction Fonction de traitement de la réponse fonction(event, dlg)
   * @param param Paramètre transmis au callback
   * @param timeout Timeout s'il y a, 0 sinon //TODO
   * @param style Style facultatif de la boite
   * @returns 
   */
  dialogBox2(titre: string, message: string, 
    btnLib1: string, btnLib2: string, defaut: number, 
    callBackAction: Function, 
    param: any,
    timeout: number,
    style?: string
  ) {
    if(!style) {
      style = "modal-std";
    }
    // Instanciation des composants dynamiques
    DlgGenericModalComponent.prototype.titreComponentType = DlgTitreComponent;
    DlgGenericModalComponent.prototype.corpsComponentType = DlgHtmlCorpsComponent;
    DlgGenericModalComponent.prototype.btnsComponentType = DlgDeuxBoutonsComponent;

    // Ouverture du dialogue
    const dlg = this.modalService.open(DlgGenericModalComponent, { centered: true, windowClass: style });
    // Passage des paramètres
    const dlgParams: DlgParams = new DlgParams()
      .setTitre(titre)
      .setTitreIconClass('icon-help-circle')
      .setCloseCallback(callBackAction)
      .setParam("html", message)
      .setParam("timeout", timeout)
      .setParam("btnLib1", btnLib1)
      .setParam("btnLib2", btnLib2)
      .setParam("defaut", defaut)
      ;
    dlg.componentInstance.dlgParams = dlgParams;
    dlg.componentInstance.btnActionEventEmit.subscribe(
      (event: any) => {
        callBackAction(event, dlg, param);
      }
    );    
    return dlg;
  }

  /**
   * Affichage du popup profil
   * @returns 
   */
  afficherProfil() {
    // Instanciation des composants dynamiques
    DlgGenericModalComponent.prototype.titreComponentType = DlgTitreComponent;
    DlgGenericModalComponent.prototype.corpsComponentType = DlgProfilCorpsComponent;
    DlgGenericModalComponent.prototype.btnsComponentType = DlgFermerBoutonsComponent;

    // Ouverture du dialogue
    const dlg = this.modalService.open(DlgGenericModalComponent, { centered: true, windowClass: "modal-profil" });
    // Passage des paramètres
    const dlgParams: DlgParams = new DlgParams()
      .setTitre('Mon profil')
      .setTitreIconClass('icon-user');
    dlg.componentInstance.dlgParams = dlgParams;

    return dlg;
  }


}
