<div ngbDropdown (openChange)="dropdownChange($event)">
  <button 
    *ngIf="sansToolTip()"
    [ngClass]="['custom-dropdown-toggle', clsBouton]"
    ngbDropdownToggle
  >
    {{ libelle }}
  </button>
  <button 
    [ngClass]="['custom-dropdown-toggle', clsBouton]"
    ngbDropdownToggle
    [ngbTooltip]="tooltip"
    #t="ngbTooltip"
    (click)="cacherTooltip(t)"
    [placement]="tooltipPlacement"
    *ngIf="!sansToolTip()"
  >
    {{ libelle }}
  </button>
  <div ngbDropdownMenu
    [ngClass]="clsMenu"
  >
    <div 
      #popup
      *ngFor="let option of mapOptions | keyvalue">
      <button ngbDropdownItem 
        [ngClass]="optionClass(option.key)"
        (click)="optionClic(option.key, option.value)"
        [disabled]="optionDesactiver(option.key)"
      >
      {{ option.value }}</button>
    </div>
  </div>
</div>
<ng-template #tplToolTip>
  <span [innerHtml]="tooltip"></span>
</ng-template>
