import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Injector, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { WebIndexService } from '../../../../../services/web-index.service';
import { Atelier } from '../../../../../models/Atelier.model';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { tap, withLatestFrom } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { InputMaskDirectiveService } from '../../../standard/input-mask-directive.service';
import { InputMaskDirective } from '../../../standard/input-mask.directive';
import { BoutonComponent } from '../../../../bouton/bouton.component';
import { MenuPopupComponent } from '../../../standard/autres/menu-popup/menu-popup.component';
import { CodeSuppression } from '../../../../../models/CodeSuppression';
import { BoutonMenuComponent } from '../../../standard/autres/bouton-menu/bouton-menu.component';
import { DialoguesService } from '../../../../dialogues/dialogues.service';

@Component({
  selector: 'app-lbp-saisie-cmc7',
  standalone: true,
  imports: [
    NgIf,
    NgxMaskDirective,
    FormsModule,
    NgbTooltip,
    NgClass,
    InputMaskDirective,
    BoutonComponent,
    BoutonMenuComponent

  ],
  providers: [
    provideNgxMask(),
    WebIndexService,
  ],
  templateUrl: './lbp-saisie-cmc7.component.html',
  styleUrl: './lbp-saisie-cmc7.component.scss'
})
export class LbpSaisieCmc7Component implements OnInit, AfterViewInit {
  inputMaskDirService = inject(InputMaskDirectiveService);

  @Input({required: true}) siteId: string = "";
  @Input({required: true}) traitementId: string = "";
  @Input({required: true}) atelier: Atelier = {} as Atelier;
  @Input({required: true}) dateTraitement: string = "";
  @Input({required: true}) objAtl: Map<string, any> = new Map();

  @Output() onImageChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onRectoVerso: EventEmitter<boolean> = new EventEmitter();
  @Output() onTeteBeche: EventEmitter<boolean> = new EventEmitter();
  @Output() onValidation: EventEmitter<boolean> = new EventEmitter();
  @Output() onMessage: EventEmitter<string> = new EventEmitter();
  @Output() onEchappe: EventEmitter<string> = new EventEmitter();
  @Output() onSlash: EventEmitter<string> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputMaskDirective') inputMaskDirective!: InputMaskDirective;
  @ViewChild('inputSaisie4', { static: true }) inputSaisie4!: ElementRef<HTMLInputElement>;
  @ViewChild('inputSaisie3', { static: true }) inputSaisie3!: ElementRef<HTMLInputElement>;
  @ViewChild('inputSaisie2', { static: true }) inputSaisie2!: ElementRef<HTMLInputElement>;
  @ViewChild('inputSaisie1', { static: true }) inputSaisie1!: ElementRef<HTMLInputElement>;
  @ViewChild('inputSaisie9', { static: true }) inputSaisie9!: ElementRef<HTMLInputElement>;
  @ViewChild('divSup', { static: false }) divSup!: ElementRef<HTMLDivElement>;
  @ViewChild('popSup', { static: false }) popSup!: MenuPopupComponent;

  saisie4: string = "";
  saisie3: string = "";
  saisie2: string = "";
  saisie1: string = "";
  saisie5: string = "";     // Code enregistrement
  saisie8: string = "";     // Top forçage
  saisie9: string = "";     // Clé rlmc
  saisie10: string = "";     // Tops
  saisie9Prec: string = ""; // Clé rlmc fausse précédamment saisie
  topFrm: boolean = false;
  topPelure: boolean = false;
  mapCodesSup: Map<string, string> = new Map();
  idCodeSuppression: string = "";
  lstIdCodeSupDesact: string = "#0#"; //liste des codes suppressions desactivés séparés par #

  toolTip: string = "";
  nbSaisieCle: number = 0;  // Nombre de saisie de la clé
  controleService: any = null;  // Fonction pour controle saisie
  controleFn: string = "";
  focusService: any = null; // Fonction pour calcul focus
  focusFn: string = "";
  padService: any = null; // Fonction pour complétion des zones
  padFn: string = "";
  historiqueService: any = null;  // Fonction pour libellé historique
  historiqueFn: string = "";
  typageService: any = null;  // Fonction pour typage document
  typageFn: string = "";
  
  cmc7Patterns = { 'C': { pattern: new RegExp('[0-9?]') } };

  maskTklZ4: string = ""; // Paramètres masques cmc7
  maskTklZ3: string = "";
  maskTklZ2: string = "";
  maskRemZ4: string = "";
  maskRemZ3: string = "";
  maskRemZ2: string = "";
  maskSepZ4: string = "";
  maskSepZ3: string = "";
  maskSepZ2: string = "";

  codeClientSup: string = "";

  /** Paramètres Atelier.param10 transformé en map */
  mapParamAtl: Map<string, string> = new Map();

  clsZone4: string = "";  // Classe dynamique pour zone 4
  clsZone3: string = "";  // Classe dynamique pour zone 3
  clsZone2: string = "";  // Classe dynamique pour zone 2
  clsZone1: string = "";  // Classe dynamique pour zone 1
  clsZoneRlmc: string = "";  // Classe dynamique pour zone clé

  btnSupprimer: boolean = false;  // Afficher ou non le bouton supprimer

  constructor(
    private dialoguesService: DialoguesService,
    private fonctionsService: FonctionsService,
  ) { }

  ngOnInit(): void {
    // Préchargement controle
    let srvFn = this.prechargerSrvFn("atelier.fonction_ctrl", this.atelier.fonctionCtrl, "rienAFaireStr");
    if(!srvFn.service) {
      return;
    } else {
      this.controleService = srvFn.service;
      this.controleFn = srvFn.fonction;
    }

    // Préchargement des service focus
    srvFn = this.prechargerSrvFn("atelier.fonction2", this.atelier.fonction2, "rienAFaire");
    if(!srvFn.service) {
      return;
    } else {
      this.focusService = srvFn.service;
      this.focusFn = srvFn.fonction;
    }

    // Préchargement des service complétion
    srvFn = this.prechargerSrvFn("atelier.fonction1", this.atelier.fonction1, "cmc7Pad");
    if(!srvFn.service) {
      return;
    } else {
      this.padService = srvFn.service;
      this.padFn = srvFn.fonction;
    }

    // Préchargement des service historique
    srvFn = this.prechargerSrvFn("atelier.fonction3", this.atelier.fonction3, "rienAFaire");
    if(!srvFn.service) {
      return;
    } else {
      this.historiqueService = srvFn.service;
      this.historiqueFn = srvFn.fonction;
    }

    // Préchargement des service typage (utilisé dans fonctions paramétrées)
    srvFn = this.prechargerSrvFn("atelier.fonction4", this.atelier.fonction4, "rienAFaireStr");
    if(!srvFn.service) {
      return;
    } else {
      this.typageService = srvFn.service;
      this.typageFn = srvFn.fonction;
    }

    this.initParams();

  }

  initParams() {
    this.mapParamAtl = this.fonctionsService.str2Map(this.atelier.param10);
    let param = this.mapParamAtl.get("z4");
    if(param == undefined) {
      this.clsZone4 = "";
    } else {
      if(param.toLowerCase() === "hidden") {
        this.clsZone4 = "zone-cache";
      } else {
        this.clsZone4 = param;
      }
    }
    param = this.mapParamAtl.get("z3");
    if(param == undefined) {
      this.clsZone3 = "";
    } else {
      if(param.toLowerCase() === "hidden") {
        this.clsZone3 = "zone-cache";
      } else {
        this.clsZone3 = param;
      }
    }
    param = this.mapParamAtl.get("z2");
    if(param == undefined) {
      this.clsZone2 = "";
    } else {
      if(param.toLowerCase() === "hidden") {
        this.clsZone2 = "zone-cache";
      } else {
        this.clsZone2 = param;
      }
    }
    param = this.mapParamAtl.get("z1");
    if(param == undefined) {
      this.clsZone1 = "";
    } else {
      if(param.toLowerCase() === "hidden") {
        this.clsZone1 = "zone-cache";
      } else {
        this.clsZone1 = param;
      }
    }
    param = this.mapParamAtl.get("zRlmc");
    if(param == undefined) {
      this.clsZoneRlmc = "";
    } else {
      if(param.toLowerCase() === "hidden") {
        this.clsZoneRlmc = "zone-cache";
      } else {
        this.clsZoneRlmc = param;
      }
    }

    param = this.mapParamAtl.get("RlmcAide");
    if(param == undefined) {
      this.toolTip = "Frm 98;Illisible 99";
    } else {
      this.toolTip = param;
    }

    param = this.mapParamAtl.get("supprimer");
    if(param == undefined) {
      this.btnSupprimer = false;
    } else {
      this.btnSupprimer = (param === "true");
    }
  }

  prechargerSrvFn(srvFnNom: string, srvFn: string, fnDefaut: string) {
    const { service: service, fonction: fonction } = this.fonctionsService.litParametreDeService(srvFn, fnDefaut);
    const res = this.fonctionsService.testServiceFonction(
        srvFnNom + "=" + srvFn,
        service, fonction
    );
    if(!res.res) {
      setTimeout(() => { 
        this.onError.emit(res.err);
      }, 0 );
      return {
        service: null,
        fonction: ""
      };
    }
    return { 
      service: service,
      fonction: fonction
    }
  }

  ngAfterViewInit(): void {
  }

  /**
   * Traitement modification d'image affiché
   * @param bSuivante True pour afficher l'image suivante, false pour afficher l'image courante
   */
  onImageSuivante(bSuivante: boolean) {
    this.onImageChange.emit(bSuivante);
  }

  /**
   * Quand une zone de saisie reçoit le focussaisieConfirmer
   * @param event 
   */
  onFocusCmc7(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    const pos = inputElement.value.indexOf("?");
    if(pos > -1) {
      inputElement.setSelectionRange(pos, pos + 1);
    } else {
      inputElement.setSelectionRange(0, inputElement.value.length);
    }
  }

  /**
   * Traitement touche Esc 
   * @param event (pas utilisé)
   */
  onTraiterEchappe(event: string) {
    this.onEchappe.emit(event);
  }

  /**
   * Traitement après appui sur Tab
   * @param valeur Valeur saisie 
   */
  onTraiterTab(valeur: string, noZone: number) {
    switch(noZone) {
      case 4:
        this.inputSaisie3.nativeElement.focus();
        break;
      case 3:
        this.inputSaisie2.nativeElement.focus();
        break;
      case 2:
        this.inputSaisie1.nativeElement.focus();
        break;
      case 1:
        this.inputSaisie9.nativeElement.focus();
        break;
      case 10:
        this.inputSaisie4.nativeElement.focus();
        break;
    }
  }
    
  /**
   * Traitement après appui sur Entrée
   * @param valeur Valeur saisie 
   */
  onTraiterEntree(valeur: string, noZone: number) {
    // Complétion automatique
    if(this.padFn.length > 0) {
      this.padService[this.padFn](this, noZone);
    }
    // Calcul du champ suivant
    if(this.focusFn.length > 0) {
      const valider = this.focusService[this.focusFn](this, noZone);
      if(valider) {
        if(this.controleFn.length > 0) {
          this.controleService[this.controleFn](this);
        } else {
          this.saisieOk();
        }    
      }
    }
  }

  onTraiterF4(event: any) {
    this.onRectoVerso.emit();
  }

  onTraiterF5(event: any) {
    this.onTeteBeche.emit();
  }
  /**
   * Nom de variable plus clair pour saisie5
   * @returns saisie5
   */
  typeDocument() {
    return this.saisie5;
  }

  /**
   * Fonction appelée dans la fonction de contrôle si la saisie est bonne
   */
  saisieOk() {
    this.onValidation.emit(true);
  }

  /**
   * Fonction appelée dans la fonction de contrôle quand la saisie est ko
   * @param msg Message de l'erreur
   */
  saisieKo(msg: string) {
    this.onImageChange.emit(false); // On réaffiche l'image
    this.onMessage.emit(msg);
  }

  /**
   * Fonction pour afficher un message de confirmation par le parent
   * @param titre 
   * @param message 
   * @param defaut 
   * @param callBackOui Action si réponse oui
   * @param callBackNon Action si réponse non
   * @param delai 
   */
  saisieConfirmer(titre: string, message: string, defaut: number, callBackOui: Function, callBackNon: Function, delai: number) {
    this.onConfirm.emit(
      {
        titre: titre,
        message: message,
        defaut: defaut,
        callBackOui: callBackOui,
        callBackNon: callBackNon,
        delai
      }
    );
  }

  /**
   * Traitement touche vers le haut
   * @param event 
   */
  onFlecheHaut(event: any, noZone: number) {
    switch(noZone) {
      case 4:
        if(this.mapParamAtl.get("zRlmc") !== "hidden") {
          this.inputSaisie9.nativeElement.focus();
        } else {
          this.onFlecheHaut(event, 9);
        }
        break;
      case 3:
        if(this.mapParamAtl.get("z4") !== "hidden") {
          this.inputSaisie4.nativeElement.focus();
        } else {
          this.onFlecheHaut(event, 4);
        }
        break;
      case 2:
        if(this.mapParamAtl.get("z3") !== "hidden") {
          this.inputSaisie3.nativeElement.focus();
        } else {
          this.onFlecheHaut(event, 3);
        }
        break;
      case 1:
        if(this.mapParamAtl.get("z2") !== "hidden") {
          this.inputSaisie2.nativeElement.focus();
        } else {
          this.onFlecheHaut(event, 2);
        }
        break;
      case 9:
        if(this.mapParamAtl.get("z1") !== "hidden") {
          this.inputSaisie1.nativeElement.focus();
        } else {
          this.onFlecheHaut(event, 1);
        }
        break;
    }
  }

  /**
   * Traitement touche vers le base
   * @param event 
   */
  onFlecheBas(event: any, noZone: number) {
    switch(noZone) {
      case 4:
        if(this.mapParamAtl.get("z3") !== "hidden") {
          this.inputSaisie3.nativeElement.focus();
        } else {
          this.onFlecheBas(event, 3);
        }
        break;
      case 3:
        if(this.mapParamAtl.get("z2") !== "hidden") {
          this.inputSaisie2.nativeElement.focus();
        } else {
          this.onFlecheBas(event, 2);
        }
        break;
      case 2:
        if(this.mapParamAtl.get("z1") !== "hidden") {
          this.inputSaisie1.nativeElement.focus();
        } else {
          this.onFlecheBas(event, 1);
        } 
        break;
      case 1:
        if(this.mapParamAtl.get("zRlmc") !== "hidden") {
          this.inputSaisie9.nativeElement.focus();
        } else {
          this.onFlecheBas(event, 9);
        }
        break;
      case 9:
        if(this.mapParamAtl.get("z4") !== "hidden") {
          this.inputSaisie4.nativeElement.focus();
        } else {
          this.onFlecheBas(event, 3);
        }
        break;
    }
  }

  /**
   * Action quand un caractère est saisi
   */
  yaSaisie() {
    // On efface le message d'erreur
    this.onMessage.emit("");
  }
  
  /**
   * Traitement après appui sur / pour revenir au document précédent
   */
  onTraiterSlash(event: any) {
    this.onSlash.emit("");
  }
  
  onRemClic(event: any) {
    if(this.saisie4.trim().length == 0) {
      this.saisie4 = "0000000";
      this.saisie3 = "999999999999";
      this.saisie2 = "";
      setTimeout( () => { this.inputSaisie2.nativeElement.focus() }, 0)
    } else {
      this.saisie3 = "999999999999";
      this.saisie2 = "";
      if(this.saisie4.indexOf("?") > -1) {
        setTimeout( () => { this.inputSaisie4.nativeElement.focus() }, 0)
      } else {
        setTimeout( () => { this.inputSaisie2.nativeElement.focus() }, 0)
      }
    }
  }
  
  onComClic(event: any) {
    this.saisie4 = this.maskSepZ4;
    this.saisie3 = this.maskSepZ3;
    setTimeout( () => { this.inputSaisie2.nativeElement.focus() }, 0)
  }
  
  onFrmClic(event: any) {
    this.saisie9 = "98";
    setTimeout(() => {
      this.focus();  
    }, 0);
  }
  
  /**
   * Sélection d'un code suppression dans la liste
   * @param codeCentre 
   */
  onCodesSupSelect(select: string[]) {
    if(select[0] == "0") {
      setTimeout(() => {
        this.idCodeSuppression = "0";
        let map = this.fonctionsService.str2Map(this.saisie10);
        map.set("idCodeSuppression", "0");    
        this.saisie10 = this.fonctionsService.map2Str(map);
        this.focus();
      }, 0);
    } else {
      this.dialoguesService.dialogBoxOuiNon2(
        "Correction Cmc7", 
        "Confirmer-vous la suppression du document pour la raison suivante: <br><b>" + select[1] + "</b>",
        2,
        (event: any, dlg: NgbModalRef, select: any) => {
          switch (event)  {
            case "close":
              dlg.dismiss();
              break;
            case "action1":
              dlg.dismiss();
              let map = this.fonctionsService.str2Map(this.saisie10);
              map.set("idCodeSuppression", select[0]);    
              this.saisie10 = this.fonctionsService.map2Str(map);
              setTimeout( () => {
                this.onValidation.emit(true);
              }, 0);
              break;
            case "action2":
                dlg.dismiss();      
                break;
          }
          dlg.dismiss();
        },      
        select,
        -1
      );
    }
  }

  /**
   * Changement clic topPelure
   */
  changementTopPelure() {
    let map = this.fonctionsService.str2Map(this.saisie10);
    map.set("pelure", this.topPelure.toString());    
    this.saisie10 = this.fonctionsService.map2Str(map);
    if(this.topPelure) {
      this.saisie4 = "0000000";
      this.saisie3 = "999999999999";
      this.saisie2 = "";
    }
    setTimeout(() => {
      this.focus();  
    }, 0);
  }

  /**
   * Changement clic Frm
   */
  changementTopFrm() {
    let map = this.fonctionsService.str2Map(this.saisie10);
    const phys = map.get("frm");
    map.set("frm", this.topFrm.toString());
    this.saisie10 = this.fonctionsService.map2Str(map);
    setTimeout(() => {
      this.focus();  
    }, 0);
  }

  clsBoutonSup(): string {
    if(this.atelier.param10.indexOf("supprimer=true") > -1) {
      const cls = "btn btn-secondary btn-mini btnpopup-dropdown-toggle"
      if(this.idCodeSuppression == "" || this.idCodeSuppression == "0") {
        return cls + " btn-fond-neutre";
      } else {
        return cls + "  btn-fond-select";
      }
    } else {
      return "btn-cache"
    }
  }

  /**
   * Mise à jour des objets de paramétrage par le parent
   * @param objAtl 
   */
  setObjAtl(objAtl: Map<string, any>) {
    this.objAtl = objAtl;
    this.objAtl.get("lstCodesSup").forEach(
      (code: CodeSuppression) => {
        this.mapCodesSup.set(code.idCodeSuppression.toString(), code.idCodeSuppression + " - " + code.libelle);      
    });
    this.mapCodesSup.set("0", "0 - Annuler la suppression");      
  }

  /**
   * Lecture des paramètres de l'atelier
   */
  setParametres(parametres: Map<string, string>) {
    parametres.forEach((value, key) => {
      if(key.toUpperCase().endsWith("TKLMASKZ4")) {
        this.maskTklZ4 = value ?? "";
      }
      if(key.toUpperCase().endsWith("TKLMASKZ3")) {
        this.maskTklZ3 = value ?? "";
      }
      if(key.toUpperCase().endsWith("TKLMASKZ2")) {
        this.maskTklZ2 = value ?? "";
      }
      if(key.toUpperCase().endsWith("REMMASKZ4")) {
        this.maskRemZ4 = value ?? "";
      }
      if(key.toUpperCase().endsWith("REMMASKZ3")) {
        this.maskRemZ3 = value ?? "";
      }
      if(key.toUpperCase().endsWith("REMMASKZ2")) {
        this.maskRemZ2 = value ?? "";
      }
      if(key.toUpperCase().endsWith("SEPMASKZ4")) {
        this.maskSepZ4 = value ?? "";
      }
      if(key.toUpperCase().endsWith("SEPMASKZ3")) {
        this.maskSepZ3 = value ?? "";
      }
      if(key.toUpperCase().endsWith("SEPMASKZ2")) {
        this.maskSepZ2 = value ?? "";
      }
      if(key.toUpperCase().endsWith("_ATLCMC7SEQCODECLIENTSUPPRESSION")) {
        this.codeClientSup = value ?? this.traitementId;
      }

    });
    
  }

  razSaisie() {
    this.inputMaskDirService?.inputMaskDir$.pipe(
      withLatestFrom(this.inputMaskDirService?.inputMaskDir$),
      tap(([_, inpDir]) => {
        inpDir?.razValeur();
      })
    ).subscribe();    
  }


  // Appelé par chargement image suivante
  focus() {
    if(this.focusFn.length > 0) {
      this.focusService[this.focusFn](this, 0);
    }
  }

  /**
   * Fonction appelé lors de l'insertion dans l'historique
   * @returns Valeur saisie affiché dans l'historique
   */
  historique(): string {
    if(this.historiqueFn.length > 0) {
      return this.historiqueService[this.historiqueFn](this);
    } else {
      return this.saisie4 + " " + this.saisie3 + " " + this.saisie2 + " [" + this.saisie9 + "]" ;
    }
  }
  
    
}
