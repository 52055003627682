import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login.service';
import { APIKEY, OPERATEUR, OPERATEURNOM, OPERATEURPRENOM } from '../../../../constantes';
import { Identification } from '../../../../models/Identification.model';
import { DialoguesService } from '../../../dialogues/dialogues.service';
import { EnteteComponent } from '../../../entetes/entete/entete.component';
import { CommonModule, Location } from '@angular/common';
import { OAuthService } from 'angular-oauth2-oidc';
import { TitreStdComponent } from '../../../entetes/titre-std/titre-std.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

/**
 * Composant pour réceptionner le token keycloak
 */
@Component({
  selector: 'app-token',
  standalone: true,
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss'],
  imports: [
    CommonModule,
    EnteteComponent,
  ],
  providers: [
    Location
  ]
})
export class TokenComponent implements OnInit {
  titreComponent = TitreStdComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private loginService: LoginService,
    private oauthService: OAuthService, 
    private dialoguesService: DialoguesService,
  ) {}

  ngOnInit(): void {
  
    // Ecoute cas identification reçue
    this.loginService.subIdentification$.subscribe({
      error: (err) => {
        if(err.code == 409) {  // Déjà connecté
          this.dialoguesService.dialogBoxOuiNon(
            "Webindexation: Identification",
            "Vous êtes déjà connecté sur un autre poste<br>" 
            + "Déconnecter l'autre poste ?",
            2,
            (event: any, dlg: NgbModalRef) => {
              switch (event)  {
                case "close":
                  dlg.dismiss();
                  this.router.navigate(["login"]);
                  break;
                case "action1":
                  dlg.dismiss();
                  this.loginService.traiterAccessToken(true);
                  break;
                case "action2":
                    dlg.dismiss();      
                    this.router.navigate(["login"]);
                    break;
              }
              dlg.dismiss();
            },
            0      
          )
        } else {
          this.dialoguesService.messageBox(
            "Webindexation: Identification", 
            "Oups! Une erreur est survenue: <br>" + err.message,
            () => {
              sessionStorage.clear();
              this.router.navigate(["login"]);
            }, -1
          );
        }
      },
      next: (ident) => {
        if (ident != null) {
          this.identificationApiRecue(ident);
        }
      },
      complete: () => { },    
    }); 

    // Ecoute cas identification reçue à la demande avec deconnexion
    this.loginService.subIdentification2$.subscribe({
      error: (err) => {
        this.dialoguesService.messageBox(
          "Webindexation: Identification", 
          "Oups! Une erreur est survenue: <br>" + err.message, 
          () => {
            this.loginService.subKeycloak$.next("OFF")
            this.router.navigate(["login"]);
          }, -1
        );
      },
      next: (ident) => {
        if (ident != null) {
          // identification reçue
          this.loginService.subKeycloak$.next("OFF")
          this.identificationApiRecue(ident);
        }
      },
      complete: () => {},    
    });     
  }

  /**
   * Traitement apiKey reçue
   * @param ident json renvoyé par l'api
   */
  identificationApiRecue(ident: any) {
    // identification reçue
    const idt = new Identification(ident);
    sessionStorage.setItem(APIKEY, idt.getApiKey());
    sessionStorage.setItem(OPERATEUR, idt.getLogin());
    sessionStorage.setItem(OPERATEURNOM, idt.getNom());
    sessionStorage.setItem(OPERATEURPRENOM, idt.getPrenom());
    this.router.navigate(["choix"]);
  }

}
