<div class="container-fluid">
  <div class="row align-items-start">
    <!-- Zone de la colonne à gauche -->
    <div class="col-md-3">
      <ng-template #historiqueTpl/>
    </div>
    <!-- Zone de l'image -->
    <div 
      [class]="['row', atelier.style1, 'zone'].join(' ')"
    >
      <div class="row id-image">Image {{ image?.id}}</div>

      <ng-template #imageTpl/>
      <!-- Zone de saisie -->
      <div class="row">
        <div class="col-md-12 zones-saisies">
          <ng-template #zoneSaisieTpl/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 message" [innerText]="message">
      </div>
      <div class="zone-btns col-md-2">
        <app-bouton [btnClass] = "'btn btn-secondary'" 
          [iconClass]="'d-inline'" 
          [libelle]="'Suivante'" 
          (clic)="onClic($event)" 
          [tooltip]="'Passer à l\'image suivante (N\'enregistre pas la saisie en cours)'"
        ></app-bouton>
      </div>
  </div>
</div>