<ul ngbNav class="navbar fixed-top navbar-expand-lg">
  <div class="col-md-12 d-flex sans-marge">
    <a
      class="navbar-brand nom-app"
      href="#"
      routerLink="/"
      routerLinkActive="active"
      tabindex="-1"
    >
      {{ nomAppli }}
    </a>
    <div class="navbar-brand d-flex align-items-center">
      <template #tplTitre></template>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="identifie"
      class="collapse navbar-collapse justify-content-end"
      id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item" *ngFor="let btn of lstBoutons">
          <app-bouton 
            [btnClass]="'btn btn-primary'"
            [iconClass]="''"
            [libelle]="btn.libelle"
            (clic)="menuOptionOnClick(btn.id)"
          ></app-bouton>
        </li>
        <li class="nav-item" ngbDropdown>
          <div class="prenom-nom">
          <span class="cercle-ab initiales" ngbDropdownToggle>{{initiales()}}</span>
          <span>
            {{ identification.getPrenom() }}
            &nbsp;
            {{ identification.getNom() }}
          </span>
          </div>
          <div class="infos">
            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="afficherProfil()">
                <span class="role">Mon profil</span>
              </button>
              <button ngbDropdownItem (click)="logout()">Me déconnecter</button>
              <button 
                *ngIf="estAdmin"
                ngbDropdownItem (click)="admin()"
              >
                <span class="role menu-adm">Administration</span>
              </button>

              <button ngbDropdownItem (click)="telechargerRgpd('cgu')">
                Conditions générales d'utilisation
              </button>
              <button ngbDropdownItem (click)="telechargerRgpd('pdc')">
                Politique de confidentialité
              </button>
              <button ngbDropdownItem (click)="telechargerRgpd('mle')">
                Mentions légales
              </button>
              <button ngbDropdownItem (click)="telechargerRgpd('cok')">
                  Cookies
              </button>
              <button ngbDropdownItem (click)="aPropos()">
                A propos...
            </button>
          </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ul>
  