import { AfterViewInit, Component, ElementRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { EnteteComponent } from '../../entetes/entete/entete.component';
import { TitreStdComponent } from '../../entetes/titre-std/titre-std.component';
import { AdminService } from './admin.service';
import { Backends } from '../../../models/BackendsModel';
import { NgFor, NgIf } from '@angular/common';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ApiStatutComponent } from "./api-statut/api-statut.component";
import { BoutonComponent } from "../../bouton/bouton.component";
import { ApiUrlService } from '../../../services/api-url.service';
import { WebIndexService } from '../../../services/web-index.service';
import { Router } from '@angular/router';
import { FonctionsService } from '../../../services/fonctions.service';
import { Parametre } from '../../../models/Parametre.model';
import { DialoguesService } from '../../dialogues/dialogues.service';
import { NOM_PROJET } from '../../../constantes';

const toolTipApiOrig = "Recharger les paramètres des backends en cas d'ajout ou de modifications";
@Component({
  selector: 'app-page-admin',
  standalone: true,
  imports: [
    NgFor,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    EnteteComponent,
    ApiStatutComponent,
    BoutonComponent
],
  providers: [
    NgbDropdown
  ],
  templateUrl: './page-admin.component.html',
  styleUrl: './page-admin.component.scss'
})
export class PageAdminComponent implements AfterViewInit, OnDestroy {
  urlBack: string = "";
  apiVersion: string = "??.??.??";
  titreComponent = TitreStdComponent;
  toolTipApi: string = toolTipApiOrig;
  lstBackends: Backends[] = [];
  lstVersions: string[] = [];    // Stockage des versions
  intervalId: any;
  erreur: string = "";

  @ViewChildren('apiStatut') apiStatuts!: QueryList<ApiStatutComponent>;
  @ViewChildren('btnBackExtRecharge') btnsBackExtRecharge!: QueryList<BoutonComponent>;

  constructor(
    private router: Router,
    private fonctionsService: FonctionsService,
    private adminService: AdminService,
    private apiUrlService: ApiUrlService,
    private webIndexService: WebIndexService,
    private dialoguesService: DialoguesService,
  ) {}

  ngOnInit() {
    this.urlBack = this.apiUrlService.urlDomaine;
    this.webIndexService.getApiInfo().subscribe({
      error: (err) => {
        this.apiVersion = "?.?.?"
      },
      next: (res: any) => {
        this.apiVersion = res.apiVersion;
      },
      complete: () => {},      
    })
    // Chargement des back externes
    this.adminService.getBackendsExt().subscribe({
      error: (err) => {
        this.dialoguesService.messageBox(
          NOM_PROJET + " Admin",
          "Oups ! Houston, on a un problème: <br>" + (err?.error?.message ?? err),
          () => { 
            this.router.navigate(["choix"]);    
          },
          -1
        );
      },
      next: (res: any) => {
        this.lstBackends = res;
        this.getBackExtVersion();
      }
    })
    this.dernierDateMajApi();
    
  }

  ngAfterViewInit(): void {
    this.intervalId = setInterval(() => {
      this.getBackExtVersion();        
    }, 60000);
    
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  /**
   * Clic sur Recharger Backends
   */
  onRechargerBack() {
    this.adminService.rechargerParamBacks().subscribe({
      error: (err) => {
        this.toolTipApi = err?.message ?? err;
      },
      next: (res: any) => {
        this.dernierDateMajApi();
      }
    })
  }

  /**
   * Clic sur un bouton Recharger Ateliers
   * @param index Index du bouton correspondant à index de lstBackends
   */
  onBackExtRecharger(index: number) {
    this.setBtnRechTooltip(index, "Rechargement en cours...")
    this.adminService.rechargerBackendExt(this.lstBackends[index]).subscribe({
      error: (err) => {
        this.setBtnRechTooltip(index, "Erreur: " + (err?.message ?? err))
      },
      next: (res: any) => {
        this.setBtnRechTooltip(index, "Rechargé à " + this.fonctionsService.maintenant2("HH:mm:ss"))
      }
    }); 
  }

  /**
   * Clic sur bouton fermer
   */
  onFermer() {
    this.router.navigate(["choix"]);
  }

  /**
   * Lecture du dernier chargement de paramètres par l'api
   */
  dernierDateMajApi() {
    this.webIndexService.getParametres(["DATE_CHARGEMENT_BACKENDS"]).subscribe({
      error: (err) => {
        console.error(err);
      },
      next: (res: any) => {
        const mpParam: Map<string, Parametre> = new Map(Object.entries(res));
        const param = mpParam.get("DATE_CHARGEMENT_BACKENDS")
        this.toolTipApi = param?.valeur ?? "";
      }
    });      
  }

  /**
   * Récupération des versions des api externes listés, lu = statut ok
   */
  getBackExtVersion() {
    this.lstVersions = new Array(this.lstBackends.length).fill("??.??.??");
    for(let i = 0; i < this.lstBackends.length; i++) {
      this.setStatutTooltip(i, "Interrogation en cours...")
      this.adminService.getBackendStatut(this.lstBackends[i].lstAteliers[0]).subscribe({
        error: (err) => {
          let lstV = this.lstVersions.slice();
          lstV[i] = "??.??.??";
          this.lstVersions = lstV;
          this.setStatutTooltip(i, "Erreur: " + err)
        },
        next: (res: any) => {
          let lstV = this.lstVersions.slice();
          lstV[i] = res.apiVersion;
          this.lstVersions = lstV;
          this.setStatutTooltip(i, "Mis à jour à " + this.fonctionsService.maintenant2("hh:mm:ss") )
        }
      });
    }
  }

  /**
   * Modification du tooltip d'un composant ApiSatut 
   * @param index Index du statut
   * @param toolTip Texte du tooltip
   */
  setStatutTooltip(index: number, toolTip: string) {
    const apiStatut = this.apiStatuts.find((component, i) => component.elementRef.nativeElement.getAttribute('data-index') == index.toString());
    if(apiStatut) {
      apiStatut.toolTip = toolTip;
    }
  }

  /**
   * Modification du tooltip d'un bouton Rrecharger
   * @param index Index du bouton
   * @param toolTip Texte du tooltip
   */
  setBtnRechTooltip(index: number, toolTip: string) {
    const btnRech = this.btnsBackExtRecharge.find((component, i) => component.elementRef.nativeElement.getAttribute('data-index') == index.toString());
    if(btnRech) {
      btnRech.tooltip = toolTip;
    }
  }
}
