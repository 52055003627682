import { ComponentRef, Injectable, Injector, Type, ViewContainerRef, ViewRef } from '@angular/core';
import { SaisieMontantStdComponent } from '../components/ateliers/standard/saisies/saisie-montant-std/saisie-montant-std.component';
import { AtlTroisZonesComponent } from '../components/ateliers/standard/ateliers/atl-trois-zones/atl-trois-zones.component';
import { HistoriqueComponent } from '../components/ateliers/standard/autres/historique/historique.component';
import { ImageStdComponent } from '../components/ateliers/standard/autres/image-std/image-std.component';
import { Logger } from '../models/Logger.model';
import { ControleSaisieStdService } from '../components/ateliers/standard/controle-saisie-std.service';
import { BnppSaisieCompteComponent } from '../components/ateliers/specifiques/bnpp/bnpp-saisie-compte/bnpp-saisie-compte.component';
import { BnppFonctionsService } from '../components/ateliers/specifiques/bnpp/bnpp-fonctions.service';
import { AtlTroisZonesBtnComponent } from '../components/ateliers/standard/ateliers/atl-trois-zones-btn/atl-trois-zones-btn.component';
import { LbpSaisiesService } from '../components/ateliers/specifiques/lbp/lbp-saisies.service';
import { LbpAtlChqMtBoutonComponent } from '../components/ateliers/specifiques/lbp/lbp-atl-chq-mt-bouton/lbp-atl-chq-mt-bouton.component';
import { StandardSaisiesService } from '../components/ateliers/standard/standard-saisies.service';
import { SaisieDateHeureStdComponent } from '../components/ateliers/standard/saisies/saisie-date-heure-std/saisie-date-heure-std.component';
import { SaisieStdComponent } from '../components/ateliers/standard/saisies/saisie-std/saisie-std.component';
import { AtlTaylorimComponent } from '../components/ateliers/standard/ateliers/atl-taylorim/atl-taylorim.component';
import { SaisieCmc7Component } from '../components/ateliers/standard/saisies/saisie-cmc7/saisie-cmc7.component';
import { LbpSaisieMontantComponent } from '../components/ateliers/specifiques/lbp/lbp-saisie-montant/lbp-saisie-montant.component';
import { StandardTaylorimService } from '../components/ateliers/standard/standard-taylorim.service';
import { DialoguesService } from '../components/dialogues/dialogues.service';
import { NOM_PROJET } from '../constantes';
import { Router } from '@angular/router';
import { LbpSaisiesCmc7Service } from '../components/ateliers/specifiques/lbp/lbp-saisies-cmc7.service';
import { LbpSaisieCptComponent } from '../components/ateliers/specifiques/lbp/lbp-saisie-cpt/lbp-saisie-cpt.component';
import { LbpSaisieCmc7Component } from '../components/ateliers/specifiques/lbp/lbp-saisie-cmc7/lbp-saisie-cmc7.component';
import { RechTplStandardComponent } from '../components/ateliers/standard/autres/recherche-popup/rech-tpl-standard/rech-tpl-standard.component';

@Injectable({
  providedIn: 'root'
})
/**
 * Classe de gestion des composants dynamiques
 */
export class DynamiqueService {
  logger: Logger = new Logger(this);
  /**
   * Il faut ajouter manuellement les composants et services dynamiques paramétrables
   * je n'ai pas trouvé comment faire autrement
   * componentFactoryResolver pour les composants ?
   */
  lstCompoDyn: { [key: string]: Type<any> } = {
    'AtlTroisZones': AtlTroisZonesComponent,
    'AtlTroisZonesBtn': AtlTroisZonesBtnComponent,
    'HistoriqueStd': HistoriqueComponent,
    'SaisieStd': SaisieStdComponent,
    'ImageStd': ImageStdComponent,
    'SaisieMontantStd': SaisieMontantStdComponent,
    'SaisieDateHeureStd': SaisieDateHeureStdComponent,
    'SaisieCmc7Std': SaisieCmc7Component,
    // Taylorim
    'AtlTaylorim': AtlTaylorimComponent,
    'RechTplStd': RechTplStandardComponent,
    // Spécifiques
    'LbpSaisieMontant': LbpSaisieMontantComponent,
    'LbpSaisieCpt': LbpSaisieCptComponent,
    'LbpSaisieCmc7': LbpSaisieCmc7Component,
    'BnppSaisieCompte': BnppSaisieCompteComponent,
  }

  lstService: { [key: string]: Type<any> } = {
    // Nom à paramétrer dans atelier: Service associé
    'ControleSaisieStdSrv': ControleSaisieStdService,
    'StdSaisiesSrv': StandardSaisiesService,
    'StdTaylorimSrv': StandardTaylorimService,
    'LbpSaisiesSrv': LbpSaisiesService,
    'LbpSaisiesCmc7Srv': LbpSaisiesCmc7Service,
    'BnppFonctionsSrv': BnppFonctionsService,
  }

  constructor(
    private router: Router,
    private injector: Injector,
    private dialoguesService: DialoguesService
  ) { }

  /**
   * Chargement dynamique d'un composant
   * @param nomComp Nom du composant à charger 
   * @param conteneur ViewContainer accueillant le composant
   * @returns 
   */
  chargerComposant(nomComp: string, conteneur: ViewContainerRef): ComponentRef<any> | null {
    const compoType = this.lstCompoDyn[nomComp];
    if (compoType) {
      conteneur.clear;
      return conteneur.createComponent(compoType)
      //compoRef.instance.propriete = "valeur"
    } else {
      this.logger.error("Composant introuvable ", nomComp);
      return null;
    }
  }

  /**
   * Chargement dynamique d'un service par son nom
   * @param nomService Nom du service, si pas trouvé: StandardSaisieService
   * @returns 
   */
  chargerService(nomService: string | null) {
    if(nomService == null || nomService == "") {
      return this.injector.get(StandardSaisiesService);
    } else {
      try {
        const serviceType = this.lstService[nomService];
        return this.injector.get(serviceType);
      } catch (error) {
        this.dialoguesService.messageBox(
          NOM_PROJET,
          "Oups ! Il y a une erreur de configuration pour " + nomService + "<br>" + error,
          () => {
            this.router.navigate(["choix"]);      
          },
          -1
        )
      }
    }
  }

}
