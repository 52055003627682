import { Injectable } from '@angular/core';
import { FonctionsService } from '../../../../services/fonctions.service';
import { WebIndexService } from '../../../../services/web-index.service';
import { StandardTaylorimService } from '../../standard/standard-taylorim.service';
import { ControleSaisieStdService } from '../../standard/controle-saisie-std.service';
import { TYPECHQ, TYPEREM, TYPETKL } from '../../standard/ateliers/atl-taylorim/TimCconstantes';
import { OPERATEUR } from '../../../../constantes';
import { LbpSaisiesService } from './lbp-saisies.service';
import { Compte } from '../../../../models/Compte';
import { DialoguesService } from '../../../dialogues/dialogues.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class LbpSaisiesCmc7Service {

  [key: string]: any; // Signature pour appel methode par tableau, pour paramétrage dans Atelier

  constructor(
    private fonctionsService: FonctionsService,
    private webIndexService: WebIndexService,
    private standardTaylorimService: StandardTaylorimService,
    private controleSaisieStdService: ControleSaisieStdService,
    private lbpSaisiesService: LbpSaisiesService,
    private dialoguesService: DialoguesService,
  ) { }

  /*===========================================================================
    Fonctions pour ateliers saisie cmc7
  =============================================================================*/
  cmc7AtlInit(objAtlSaisie: any) {
    if(objAtlSaisie.atelier.param10.indexOf("lstCentres=true") > -1) {
      // Chargement codes centres Lbp pour atelier chq lbp
      let mapParam = new Map<string, string>();
      mapParam.set("codeClient", objAtlSaisie.atelier.param9);

      this.webIndexService.appelBackendExterne(
        "getAgences", 
        objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, 
        objAtlSaisie.dateTraitement, 
        mapParam
      ).subscribe({
          error: (err) => {
            objAtlSaisie.atlInitKo("Oups!  Erreur " + err.status + " dans l'appel de " + err.url);
          },
          next: (res: any) => {
            if(res.length == 0) {
              objAtlSaisie.atlInitKo("Oups!  Erreur de chargment des centres pour " + objAtlSaisie.atelier.param9);
            } else {
              objAtlSaisie.objAtl.set("lstCentres", res)
              objAtlSaisie.zoneSaisieRef!.instance.setObjAtl(objAtlSaisie.objAtl);
            }
          },
          complete: () => { }  
      });
    }
    if(objAtlSaisie.atelier.param10.indexOf("supprimer=true") > -1) {
      // Chargement codes suppression
      let mapParam2 = new Map<string, string>();
      mapParam2.set("codeClientSup", objAtlSaisie.zoneSaisieRef.instance.codeClientSup);
      mapParam2.set("info1", "COR");
      //mapParam2.set("info2", "");
      mapParam2.set("corbeille", "false");

      this.webIndexService.appelBackendExterne(
        "getCodesSupStd", 
        objAtlSaisie.siteId, objAtlSaisie.traitementId, objAtlSaisie.atelier, 
        objAtlSaisie.dateTraitement, 
        mapParam2
      ).subscribe({
          error: (err) => {
            objAtlSaisie.atlInitKo("Oups!  Erreur " + err.status + " dans l'appel de " + err.url);
          },
          next: (res: any) => {
            if(res.length == 0) {
              objAtlSaisie.atlInitKo("Oups!  Erreur de chargment des codes suppressions");
            } else {
              objAtlSaisie.objAtl.set("lstCodesSup", res)
              objAtlSaisie.zoneSaisieRef!.instance.setObjAtl(objAtlSaisie.objAtl);
            }
          },
          complete: () => { }  
      });
    }

  }

  /**
   * Initialisation des champs cmc7 standard
   * @param objAtlSaisie 
   */
  cmc7Init(objAtlSaisie: any) {
    objAtlSaisie.zoneSaisieRef!.instance.saisie4 = objAtlSaisie.image.saisie4;
    objAtlSaisie.zoneSaisieRef!.instance.saisie3 = objAtlSaisie.image.saisie3;
    objAtlSaisie.zoneSaisieRef!.instance.saisie2 = objAtlSaisie.image.saisie2;
    objAtlSaisie.zoneSaisieRef!.instance.saisie1 = objAtlSaisie.image.saisie1;
    objAtlSaisie.zoneSaisieRef!.instance.saisie9 = objAtlSaisie.image.saisie9;
    objAtlSaisie.zoneSaisieRef!.instance.saisie5 = objAtlSaisie.image.saisie5;
    objAtlSaisie.zoneSaisieRef!.instance.saisie8 = objAtlSaisie.image.saisie8;
    objAtlSaisie.zoneSaisieRef!.instance.saisie10 = objAtlSaisie.image.saisie10;
    objAtlSaisie.zoneSaisieRef!.instance.nbSaisieCle = 0;
    objAtlSaisie.zoneSaisieRef!.instance.saisie9Prc = "";
    const s10 = objAtlSaisie.image.saisie10 ?? "";
    if(s10.indexOf("pelure=true") > -1) {
      objAtlSaisie.zoneSaisieRef!.instance.topPelure = true;
    } else {
      objAtlSaisie.zoneSaisieRef!.instance.topPelure = false;
    }
    if(s10.indexOf("frm=true") > -1) {
      objAtlSaisie.zoneSaisieRef!.instance.topFrm = true;
    } else {
      objAtlSaisie.zoneSaisieRef!.instance.topFrm = false;
    }
    if(s10.indexOf("rlmc=") > -1) {
      objAtlSaisie.zoneSaisieRef!.instance.saisie9 = this.fonctionsService.extraitValeur("rlmc", s10, "=", ";");
    }
    if(s10.indexOf("idCodeSuppression=") > -1) {
      objAtlSaisie.zoneSaisieRef!.instance.idCodeSuppression = this.fonctionsService.extraitValeur("idCodeSuppression", s10, "=", ";");
      objAtlSaisie.zoneSaisieRef!.instance.lstIdCodeSupDesact = ""; // annulation disponible
    } else {
      objAtlSaisie.zoneSaisieRef!.instance.idCodeSuppression = "";
      objAtlSaisie.zoneSaisieRef!.instance.lstIdCodeSupDesact = "#0#"; // annulation indisponible
    }
    setTimeout(
      () => { 
        objAtlSaisie.zoneSaisieRef!.instance.focusService[objAtlSaisie.zoneSaisieRef!.instance.focusFn](objAtlSaisie.zoneSaisieRef!.instance, 0); 
      }, 0
    );
    
  }

  /**
   * Calcul du focus suivant
   * @param objZoneSaisieRef Objet ZoneSaisieRef
   * @param noZone Numéro de la zone qui a le focus
   * @returns 
   */
  cmc7Focus(objZoneSaisieRef: any, noZone: number): boolean {
    objZoneSaisieRef.saisie5 = this.standardTaylorimService.typeDocument(objZoneSaisieRef);
    switch(noZone) {
      case 0: // Entrée en saisie
        if(objZoneSaisieRef.saisie4.indexOf("?") > -1) {
          setTimeout( () => { objZoneSaisieRef.inputSaisie4.nativeElement.focus() }, 0);
        } else {
          if(objZoneSaisieRef.saisie4.length == 7) {
            return this.cmc7Focus(objZoneSaisieRef, 4);
          } else {
            setTimeout( () => { objZoneSaisieRef.inputSaisie4.nativeElement.focus() }, 0);
          }
        }
        return false;

      case 4:
        if(objZoneSaisieRef.saisie4.indexOf("?") > -1) {
          setTimeout( () => {
            objZoneSaisieRef.inputSaisie4.nativeElement.blur();
            objZoneSaisieRef.inputSaisie4.nativeElement.focus();  
          }, 0)
          return false;
        }
        if(objZoneSaisieRef.saisie3.indexOf("?") > -1) {
          setTimeout( () => { objZoneSaisieRef.inputSaisie3.nativeElement.focus() }, 0);
        } else {
          if(objZoneSaisieRef.saisie3.length == 12) {
            return this.cmc7Focus(objZoneSaisieRef, 3);
          } else {
            setTimeout( () => { objZoneSaisieRef.inputSaisie2.nativeElement.focus() }, 0);
          }
        }
        return false;

      case 3:
        if(objZoneSaisieRef.saisie3.indexOf("?") > -1 || objZoneSaisieRef.saisie3.trim().length == 0) {
          setTimeout(() => {
            objZoneSaisieRef.inputSaisie3.nativeElement.blur();
            objZoneSaisieRef.inputSaisie3.nativeElement.focus();              
          }, 0);
          return false;
        }
        if(objZoneSaisieRef.typeDocument() == TYPEREM && this.typeRem(objZoneSaisieRef) == "B") {
          objZoneSaisieRef.saisie2 = "";
          objZoneSaisieRef.inputSaisie2.nativeElement.focus();              
          return true;
        } else {
          if(objZoneSaisieRef.saisie2.indexOf("?") > -1) {
            setTimeout( () => { objZoneSaisieRef.inputSaisie2.nativeElement.focus(); }, 0)
          } else {
            if(objZoneSaisieRef.saisie2.length == 12) {
              return this.cmc7Focus(objZoneSaisieRef, 2);
            } else {
              setTimeout( () => { objZoneSaisieRef.inputSaisie2.nativeElement.focus() }, 0);
            }
          }
          return false;
        }

      case 2:
        if(objZoneSaisieRef.saisie2.indexOf("?") > -1 
          || (objZoneSaisieRef.typeDocument() == TYPECHQ && objZoneSaisieRef.saisie2.trim().length == 0)) {
          setTimeout( () => {
            objZoneSaisieRef.inputSaisie2.nativeElement.blur();
            objZoneSaisieRef.inputSaisie2.nativeElement.focus();  
          }, 0)
          return false;
        }
        if(objZoneSaisieRef.typeDocument() == TYPETKL || objZoneSaisieRef.typeDocument() == TYPEREM) {
          return true;
        } else {
          if(objZoneSaisieRef.saisie1 && objZoneSaisieRef.saisie1.length > 0) {
            setTimeout( () => { objZoneSaisieRef.inputSaisie1.nativeElement.focus() }, 0);
          } else {            
            setTimeout( () => { objZoneSaisieRef.inputSaisie9.nativeElement.focus() }, 0);
          }
          return false;
        }

      case 1:
        if(objZoneSaisieRef.saisie1.indexOf("?") > -1) {
          setTimeout( () => {
            objZoneSaisieRef.inputSaisie1.nativeElement.blur();
            objZoneSaisieRef.inputSaisie1.nativeElement.focus();  
          }, 0)
          return false;
        }
        setTimeout( () => { objZoneSaisieRef.inputSaisie9.nativeElement.focus() });
        return false;
      case 9:
        return true;
    }
    return false;
  }

  /**
   * Contrôle de la cmc7 saisie
   * @param objZoneSaisieRef Objet saisie
   */
  ctrlCmc7(objZoneSaisieRef: any) {
    const cmc7 = this.fonctionsService.padL(objZoneSaisieRef.saisie4, 7, "0")
          + this.fonctionsService.padL(objZoneSaisieRef.saisie3, 12, "0")
          + this.fonctionsService.padL(objZoneSaisieRef.saisie2, 12, "0");
          
    if((cmc7 + objZoneSaisieRef.saisie1).indexOf("?") > -1) {
      objZoneSaisieRef.focusService[objZoneSaisieRef.focusFn](this, 0);
      objZoneSaisieRef.saisieKo("Caractère non corrigé");
    }
    if(objZoneSaisieRef.typeDocument() == TYPECHQ) {
      if(this.fonctionsService.cleRLMC(cmc7) != this.fonctionsService.padL(objZoneSaisieRef.saisie9, 2, "0") ) {
        if(objZoneSaisieRef.saisie9 == "98") {  // Doit être confirmé plus loin
          objZoneSaisieRef.saisie8 = "1";
          this["ctrlCmc7Frm"](objZoneSaisieRef);
        } else {
          objZoneSaisieRef.nbSaisieCle ++;
          if(objZoneSaisieRef.nbSaisieCle < 3) {
            objZoneSaisieRef.inputSaisie9.nativeElement.setSelectionRange(0, 2);
            objZoneSaisieRef.saisieKo("La clé Rlmc ne correspond pas");
          } else {
            if(objZoneSaisieRef.saisie9Prec != objZoneSaisieRef.saisie9) {
              objZoneSaisieRef.saisie9Prec = objZoneSaisieRef.saisie9;
              objZoneSaisieRef.saisie9 = ""; // Vidage pour resaisie
              objZoneSaisieRef.saisieKo("Veuillez saisir la clé fausse pour confirmer");
            } else {
              objZoneSaisieRef.saisie8 = "1";
              this["ctrlCmc7Frm"](objZoneSaisieRef);
            }
          }
        }
      } else {
        objZoneSaisieRef.saisie8 = "0";
        this["ctrlCmc7Frm"](objZoneSaisieRef);
      }
    } else {
      objZoneSaisieRef.saisie8 = "";
      this["ctrlCmc7Frm"](objZoneSaisieRef);
    }
  }

  ctrlCmc7Frm(objZoneSaisieRef: any) {
    if(
      objZoneSaisieRef.typeDocument() == TYPECHQ 
      && objZoneSaisieRef.saisie9 == "98")  
    {
      setTimeout( () => {
      objZoneSaisieRef.saisieConfirmer( 
        "Correction cmc7",
        "Confirmez-vous que le document est une FRM",
        2,
        () => { this["ctrlCmc7Lbp"](objZoneSaisieRef) },
        () => { objZoneSaisieRef.saisieKo("Le statut FRM n'est pas confirmé") },
        0
      )
    }, 0);
    } else {
      this['ctrlCmc7Lbp'](objZoneSaisieRef);
    }
    
    
  }

  /**
   * Controle des chèques Lbp
   * @param objZoneSaisieRef 
   */
  ctrlCmc7Lbp(objZoneSaisieRef: any) {
    if(
      objZoneSaisieRef.typeDocument() == TYPECHQ 
      && objZoneSaisieRef.saisie3.substring(5, 9) == "0041" 
      && objZoneSaisieRef.saisie8 == "1")  
    {
      objZoneSaisieRef.saisieKo("La cmc7 des chèques Lbp ne peuvent pas être forcée. A supprimer.");
    } else {
      objZoneSaisieRef.saisieOk();
    }
  }

  /**
   * Enregistrment de la saisie cmc7
   * @param objAtlSaisie Composant atelier de saisie
   * @returns 
   */
  cmc7Enregistrer(objAtlSaisie: any) {
    let message = "";
    if(objAtlSaisie.image != null) {
      if(objAtlSaisie.zoneSaisieRef.instance.saisie10 == "atlSuivant") {
        objAtlSaisie.image.saisie10 = objAtlSaisie.zoneSaisieRef!.instance.saisie10;
      } else {
        objAtlSaisie.image.saisie4 = objAtlSaisie.zoneSaisieRef!.instance.saisie4;
        objAtlSaisie.image.saisie3 = objAtlSaisie.zoneSaisieRef!.instance.saisie3;
        objAtlSaisie.image.saisie2 = objAtlSaisie.zoneSaisieRef!.instance.saisie2;
        objAtlSaisie.image.saisie1 = objAtlSaisie.zoneSaisieRef!.instance.saisie1;
        objAtlSaisie.image.saisie5 = objAtlSaisie.zoneSaisieRef!.instance.typeDocument();
        objAtlSaisie.image.saisie8 = objAtlSaisie.zoneSaisieRef!.instance.saisie8;
        objAtlSaisie.image.saisie9 = objAtlSaisie.zoneSaisieRef!.instance.saisie9;

        let map = this.fonctionsService.str2Map(objAtlSaisie.zoneSaisieRef!.instance.saisie10);
        map.set("pelure", objAtlSaisie.zoneSaisieRef!.instance.topPelure.toString());
        if(objAtlSaisie.zoneSaisieRef!.instance.topFrm) {
          map.set("rlmc", objAtlSaisie.zoneSaisieRef!.instance.saisie9);  // on sauvegarde la clé
          objAtlSaisie.image.saisie9 = "98";  // On force la clé à 98
        }
        objAtlSaisie.image.saisie10 = this.fonctionsService.map2Str(map);  
      }
      objAtlSaisie.image.operateur = sessionStorage.getItem(OPERATEUR) ?? "";
      objAtlSaisie.image.saisieEnregDate = this.fonctionsService.maintenant();
      this.webIndexService["enregistrer"](objAtlSaisie, objAtlSaisie.modeSaisie);
    } else {
      message = "Image à enregister null ?";
    }
    return message;
  }

  /**
   * Calcule le libéllé affiché dans historique
   * @param objZoneSaisieRef 
   * @returns Libellé affiché
   */
  cmc7Historique(objZoneSaisieRef: any): string {
    let balD = "";
    let balF = ""
    const idCode = this.fonctionsService.extraitValeur("idCodeSuppression", objZoneSaisieRef.saisie10, "=", ";");

    if(idCode && idCode != "0") {
      balD = "<del>";
      balF = "</del>";
    }
    return "<br>" + balD + this.standardTaylorimService.libCodeEnreg(objZoneSaisieRef.typeDocument()) + "  "
      + objZoneSaisieRef.saisie4 + " " + objZoneSaisieRef.saisie3 + " " 
      + objZoneSaisieRef.saisie2 + " (" + objZoneSaisieRef.saisie9 + ") " 
      + (objZoneSaisieRef.saisie8 == "1" ? " F" : " ")
      + balF;
  }


  typeRem(objZoneSaisieRef: any) {
    // On suppose que c'est typé remise
    if(objZoneSaisieRef.saisie3.substring(0, 6) == "999999") {
      return "B";
    } else {
      return "P";
    }
  }
  
  /*===========================================================================
    Ateliers Zone2 inconnu
  =============================================================================*/
  /**
   * Initialisation des champs chqLbp
   * @param objAtlSaisie 
   */
  chqLbpInit(objAtlSaisie: any) {
    objAtlSaisie.zoneSaisieRef!.instance.saisie4 = objAtlSaisie.image.saisie4;
    objAtlSaisie.zoneSaisieRef!.instance.saisie3 = objAtlSaisie.image.saisie3;
    objAtlSaisie.zoneSaisieRef!.instance.saisie2 = objAtlSaisie.image.saisie2;
    objAtlSaisie.zoneSaisieRef!.instance.saisie1 = objAtlSaisie.image.saisie1;
    objAtlSaisie.zoneSaisieRef!.instance.saisie9 = objAtlSaisie.image.saisie9;
    objAtlSaisie.zoneSaisieRef!.instance.saisie5 = objAtlSaisie.image.saisie5;
    objAtlSaisie.zoneSaisieRef!.instance.saisie8 = objAtlSaisie.image.saisie8;
    objAtlSaisie.zoneSaisieRef!.instance.nbSaisieCle = 0;
    objAtlSaisie.zoneSaisieRef!.instance.saisie9Prc = "";

    this.chqLbpFocus(objAtlSaisie.zoneSaisieRef.instance, 0);
  }

  /**
   * Calcul du focus suivant
   * @param objZoneSaisieRef Objet ZoneSaisieRef
   * @param noZone Numéro de la zone qui a le focus
   * @returns 
   */
  chqLbpFocus(objZoneSaisieRef: any, noZone: number): boolean {
    switch(noZone) {
    case 0: // Entrée en saisie
      setTimeout(() => {
        objZoneSaisieRef.inputSaisie2.nativeElement.select();
        objZoneSaisieRef.inputSaisie2.nativeElement.focus();        
      }, 0);
      return false;

    case 2:
      this.ctrlChqLbp(objZoneSaisieRef);
      return false;
  
    case 9:
      if((objZoneSaisieRef.saisie9 ?? "").length == 2) {
        return true;
      }
    }
    return false;
  }

  /**
   * Calcule le libéllé affiché dans historique
   * @param objZoneSaisieRef 
   * @returns Libellé affiché
   */
  chqLbpHistorique(objZoneSaisieRef: any): string {
    return objZoneSaisieRef.saisie2 + " (" + objZoneSaisieRef.saisie9 + ") " 
      + (objZoneSaisieRef.saisie8 == "1" ? " F" : " ");
  }

  /**
   * Contrôle la zone2 saisie
   * @param objZoneSaisieRef Composant contenant les champs de saisie
   * @returns 
   */
  ctrlChqLbp(objZoneSaisieRef: any) {
    this.lbpSaisiesService.lbpZone2EstValide(
      objZoneSaisieRef, 
      objZoneSaisieRef.saisie2,
      objZoneSaisieRef.objAtl.get("lstCentres"),
      (compte: Compte) => { 
        if(objZoneSaisieRef.saisie9 != null && objZoneSaisieRef.saisie9.length == 2) {
          this.ctrlCmc7(objZoneSaisieRef);
        } else {
          setTimeout( () => { 
            objZoneSaisieRef.inputSaisie9.nativeElement.focus() }, 0);
        }
      },
      (msg: string) => { objZoneSaisieRef.saisieConfirmer(msg); },
      (msg: string) => { objZoneSaisieRef.saisieKo(msg); }
    );
  }

}

