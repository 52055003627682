<div *ngIf="requeteEnCours"
  class="d-flex justify-content-center align-items-center hauteur"
>
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
</div>
<div *ngIf="!requeteEnCours && listRechBase.length == 0"
  class="hauteur"
>
  Pas de correspondance
</div>
<div *ngIf="!requeteEnCours && listRechBase.length > 0"
  class="hauteur"
>
  <table #tableRes>
    <thead>
      <th>Intitulé</th>
      <th>Nom, Raison Sociale...</th>
    </thead>
    <tbody>
      <tr *ngFor="let rechBase of listRechBase; let i = index"
        [attr.data-index]="i"
        class="cliquable"
        (click)="onClic(i)"
      >
        <td>
          {{ rechBase.libelle }}
        </td>
        <td>
          {{ rechBase.libelle2 }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

