import { Component, Input, input, OnInit, OnChanges, SimpleChanges, viewChild, ViewChild, ElementRef } from '@angular/core';
import { Atelier } from '../../../../../models/Atelier.model';
import { ApiImage } from '../../../../../models/ApiImage.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-image-std',
  standalone: true,
  imports: [ 
    NgbTooltip,
    NgClass,
  ],
  templateUrl: './image-std.component.html',
  styleUrl: './image-std.component.scss'
})
export class ImageStdComponent {
  @Input() atelier: Atelier | null = null;
  @Input() image!: ApiImage | null;
  
  @ViewChild('imgView') imgView!: ElementRef<HTMLImageElement>;

  /** Classe de l'image (pour rotation) */
  clsImage: string = "";

  constructor( ) { 
  }

  OnInit(): void {
  }

  changerImage(imgBase64: string) {
    if(imgBase64.length > 0) {
      this.imgView.nativeElement.src = imgBase64;
    }
  }

  teteBeche() {
    if(this.clsImage) {
      this.teteBecheOff();
    } else {
      this.teteBecheOn();
    }
  }

  teteBecheOn() {
    this.clsImage = "rotation-180";
  }

  teteBecheOff() {
      this.clsImage = "";
  }
}
