import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { RechercheBase } from '../../../../../../models/RechercheBase';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-rech-tpl-standard',
  standalone: true,
  imports: [
    NgFor, NgIf
  ],
  templateUrl: './rech-tpl-standard.component.html',
  styleUrl: './rech-tpl-standard.component.scss'
})
export class RechTplStandardComponent {
  @Input() listRechBase: RechercheBase[] = [];
  @Input() requeteEnCours: boolean = false;

  @Output() clic: EventEmitter<RechercheBase> = new EventEmitter();

  @ViewChild('tableRes') tableRes: ElementRef<HTMLInputElement>= {} as ElementRef<HTMLInputElement>;

  selectIndex: number = -1;

  constructor(
    private renderer: Renderer2, 
    private elementRef: ElementRef,
  ) {}

  onClic(index: number) {
    this.select(index);
    this.clic.emit(this.listRechBase[index]);
  }

  getTr(index: string): HTMLElement {
    return this.elementRef.nativeElement.querySelector(`tr[data-index="${index}"]`);
  }

  select(index: number) {
    if(this.selectIndex >= 0) {
      const tr = this.getTr(this.selectIndex.toString());
      if(tr) {
        tr.classList.remove("tr-selected");
      }
    }
    this.selectIndex = index;
    const tr = this.getTr(this.selectIndex.toString());
    if(tr) {
      tr.classList.add("tr-selected");
      tr.scrollIntoView({ block: "nearest"})
      this.clic.emit(this.listRechBase[this.selectIndex]);
    }
  }

  selectSuivant() {
    if(this.selectIndex < this.listRechBase.length) {
      if(this.selectIndex >= 0) {
        const tr = this.getTr(this.selectIndex.toString());
        if(tr) {
          tr.classList.remove("tr-selected");
        }
      }
      this.selectIndex ++;
      const tr = this.getTr(this.selectIndex.toString());
      if(tr) {
        tr.classList.add("tr-selected");
        tr.scrollIntoView({ block: "nearest"})
        this.clic.emit(this.listRechBase[this.selectIndex]);
      }
    }
  }

  selectPrecedent() {
    if(this.selectIndex > 0) {
      let tr = this.getTr(this.selectIndex.toString());
      if(tr) {
        tr.classList.remove("tr-selected");
      }
      this.selectIndex --;
      tr = this.getTr(this.selectIndex.toString());
      if(tr) {
        tr.classList.add("tr-selected");
        tr.scrollIntoView({ block: "nearest"})
        this.clic.emit(this.listRechBase[this.selectIndex]);
      }
    }
  }


}
