import { KeyValuePipe, NgClass, NgFor, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu-popup',
  standalone: true,
  imports: [
    NgFor,
    KeyValuePipe,
    NgStyle,
    NgClass,
    NgbDropdownMenu, 
    NgbDropdownItem
  ],
  providers: [
    NgbDropdown
  ],
  templateUrl: './menu-popup.component.html',
  styleUrl: './menu-popup.component.scss'
})
export class MenuPopupComponent implements OnInit {
  @Input() afficher: boolean = false;
  @Input() posX: number = 0;
  @Input() posY: number = 0;
  @Input({ required: true }) mapOptions: Map<string, string> = new Map();
  @Output() select: EventEmitter<string> = new EventEmitter<string>(); 
  ngOnInit(): void {
    
  }

  toggle() {
    this.afficher = !this.afficher;
  }

  optionClic(option: string) {
    this.select.emit(option);
  }


}
