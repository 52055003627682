export const NOM_PROJET = "Chèque+ Indexation";
export const SESSION_ID = "sessionId";    // SessionStorage: Id de la session cliente
export const APIKEY = "apiKey";           // SessionStorage: Id de la session Serveur
export const OPERATEUR = "operateur";     // SessionStorage: clé pour operateur
export const OPERATEURNOM = "operateurNom";     // SessionStorage: clé pour operateur nom
export const OPERATEURPRENOM = "operateurPrenom";     // SessionStorage: clé pour operateur prenom

export const SITE_ID = "siteId";                  // SessionStorage: clé pour siteId en cours
export const TRAITEMENT_ID = "traitementId";      // SessionStorage: clé pour siteId en cours
export const ATELIER_ID = "atelierId";            // SessionStorage: clé pour siteId en cours
export const DATE_TRAITEMENT = "dateTraitement";  // SessionStorage: clé pour siteId en cours

// Gestion images dans un atelier
export const IMAGE_COURANTE = 0;  // L'image courante est demandée
export const IMAGE_SUIVANTE = 1;  // L'image suivante est demandée
export const IMAGE_ANNULE = 2;    // La saisie courante est anuulée

export const HISTO_MAX = 30;  // Nombre maximum d'éléments afficher dans l'historique