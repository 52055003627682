import { NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bouton',
  standalone: true,
  imports: [
    NgIf,
    NgbTooltip, 
  ],
  templateUrl: './bouton.component.html',
  styleUrls: ['./bouton.component.scss']
})
export class BoutonComponent implements OnInit {
  @Input({required: true}) btnClass: string = "";
  @Input({required: true}) iconClass: string = "";
  @Input({required: true}) libelle: string = "Mon Bouton";
  @Input() tooltip: string = "";
  @Input() tooltipPlacement: string ="bottom";
  @Output() clic = new EventEmitter();

  constructor(
    public elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
  }

  onClick(tt: any) {
    if(tt) {
      tt.close();
    }
    this.clic.emit();
  }

  sansToolTip() {
    return this.tooltip.length == 0;
  }

}
