<div class="panneau">
  <div class="row li-titre"><h5>Historique {{ nbSaisi > 0 ? ' - ' + nbSaisi : ''}}</h5></div>
  <ul class="ul-panneau">
    <li *ngIf="lstImages.length == 0">
    Aucune saisie dans cette session
    </li>
    <li class="li-saisie"
      *ngFor="let image of lstImages"
      (click)="elementClick($event, image.id)"
      [class.selected]="image.id === imgSelectionId"
      [innerHtml]="libelle(image)"
    >
    </li>
  </ul>
</div>