  <div class="row zones-saisies">
    <div class="row zone-cmc7">
      <div class="zone4" [ngClass]="clsZone4">
        <label class="libelle">Zone 4</label>
        <input type="text" 
          #inputSaisie4
          name="saisie4" 
          class="form-control espacement" 
          tabindex="1"
          mask="CCCCCCC"
          [patterns]="cmc7Patterns"
          [showMaskTyped] = "true"
          [(ngModel)]="saisie4"
          [maxLength]="7"
          inputMaskDirective
          (ngModelChange)="yaSaisie()"
          (valider)="onTraiterEntree($event, 4)"
          (echappe)="onTraiterEchappe($event)"
          (slash)="onTraiterSlash($event)"
          (flecheBas)="onFlecheBas($event, 4)"
          (flecheHaut)="onFlecheHaut($event, 4)"
          (tabulation)="onTraiterTab($event, 4)"
          (f4)="onTraiterF4($event)"
          (f5)="onTraiterF5($event)"
          (focus)="onFocusCmc7($event)"
        />
      </div>
      <div class="zone3" [ngClass]="clsZone3">
        <label class="libelle">Zone 3</label>
        <input type="text" 
          #inputSaisie3
          name="saisie3" 
          class="form-control espacement" 
          tabindex="2"
          mask="CCCCCCCCCCCC"
          [patterns]="cmc7Patterns"
          [showMaskTyped] = "true"
          [(ngModel)]="saisie3"
          [maxLength]="12"
          inputMaskDirective
          (ngModelChange)="yaSaisie()"
          (imageSuivante)="onImageSuivante($event)"
          (valider)="onTraiterEntree($event, 3)"
          (echappe)="onTraiterEchappe($event)"
          (slash)="onTraiterSlash($event)"
          (flecheBas)="onFlecheBas($event, 3)"
          (flecheHaut)="onFlecheHaut($event, 3)"
          (tabulation)="onTraiterTab($event, 3)"
          (f4)="onTraiterF4($event)"
          (f5)="onTraiterF5($event)"
          (focus)="onFocusCmc7($event)"
        />
      </div>
      <div class="zone2" [ngClass]="clsZone2">
        <label class="libelle">Zone 2</label>
        <input type="text" 
          #inputSaisie2
          name="saisie2" 
          class="form-control espacement" 
          tabindex="3"
          mask="CCCCCCCCCCCC"
          [patterns]="cmc7Patterns"
          [showMaskTyped] = "true"
          [(ngModel)]="saisie2"
          [maxLength]="12"
          inputMaskDirective
          (ngModelChange)="yaSaisie()"
          (imageSuivante)="onImageSuivante($event)"
          (valider)="onTraiterEntree($event, 2)"
          (echappe)="onTraiterEchappe($event)"
          (slash)="onTraiterSlash($event)"
          (flecheBas)="onFlecheBas($event, 2)"
          (flecheHaut)="onFlecheHaut($event, 2)"
          (tabulation)="onTraiterTab($event, 2)"
          (f4)="onTraiterF4($event)"
          (f5)="onTraiterF5($event)"
          (focus)="onFocusCmc7($event)"
        />
      </div>
      <div class="zone1" [ngClass]="clsZone1">
        <label class="libelle">Zone 1</label>
        <input type="text" 
          #inputSaisie1
          name="saisie1" 
          class="form-control espacement " 
          tabindex="4"
          mask="CCCCCCCCCCCC"
          [patterns]="cmc7Patterns"
          [showMaskTyped] = "true"
          [(ngModel)]="saisie1"
          [maxLength]="12"
          inputMaskDirective
          (ngModelChange)="yaSaisie()"
          (imageSuivante)="onImageSuivante($event)"
          (valider)="onTraiterEntree($event, 1)"
          (echappe)="onTraiterEchappe($event)"
          (slash)="onTraiterSlash($event)"
          (flecheBas)="onFlecheBas($event, 1)"
          (flecheHaut)="onFlecheHaut($event, 1)"
          (tabulation)="onTraiterTab($event, 1)"
          (f4)="onTraiterF4($event)"
          (f5)="onTraiterF5($event)"
          (focus)="onFocusCmc7($event)"
        />
      </div>
      <div class="zoneCle" [ngClass]="clsZoneRlmc">
        <label class="libelle">Rlmc</label>
        <input type="text" 
          #inputSaisie9
          name="saisie9" 
          class="form-control espacement " 
          tabindex="5"
          mask="00"
          [showMaskTyped] = "true"
          [(ngModel)]="saisie9"
          [maxLength]="2"
          inputMaskDirective
          (ngModelChange)="yaSaisie()"
          (imageSuivante)="onImageSuivante($event)"
          (valider)="onTraiterEntree($event, 9)"
          (echappe)="onTraiterEchappe($event)"
          (slash)="onTraiterSlash($event)"
          (flecheBas)="onFlecheBas($event, 9)"
          (flecheHaut)="onFlecheHaut($event, 9)"
          (tabulation)="onTraiterTab($event, 9)"
          (f4)="onTraiterF4($event)"
          (f5)="onTraiterF5($event)"
          [ngbTooltip]="toolTip"
          triggers="hover"
        />
      </div>
  </div>

  <div class="row d-flex justify-content-center sep-btns">
    <div class="col-bouton">
      <app-bouton 
        [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini'" 
        [iconClass]="'d-inline'" 
        [libelle]="'Remise'" 
        [tooltip]="'Le document est une remise banalisée'"
        (clic)="onRemClic($event)"
      ></app-bouton>
    </div>
    <div class="col-bouton">
      <app-bouton 
        [btnClass]="'btn btn-secondary btn-fond-neutre btn-mini'" 
        [iconClass]="'d-inline'" 
        [libelle]="'Commande'" 
        [tooltip]="'Le document est une commande'"
        (clic)="onComClic($event)"
      ></app-bouton>
    </div>
    <div class="col-bouton">
      <div 
        class="form-check form-switch  custom-switch"
        [ngbTooltip]="'Cocher pour marquer le document comme pelure <b>avant la saisie</b>'"
      >
        <input class="form-check-input" type="checkbox" id="switchPelure" 
          [(ngModel)]="topPelure"
          (ngModelChange)="changementTopPelure()"
        >
        <label class="form-check-label" for="switchPelure">Pelure</label>
      </div>
    </div>
    <div class="col-bouton">
      <div 
        class="form-check form-switch  custom-switch"
        [ngbTooltip]="frmToolTip"
        placement="bottom"
      >
        <input class="form-check-input" type="checkbox" id="switchFrm" 
          [(ngModel)]="topFrm"
          (ngModelChange)="changementTopFrm()"
        >
        <label class="form-check-label" for="switchFrm">FRM</label>
      </div>
      <ng-template #frmToolTip>
        Cocher pour marquer le document comme FRM <b>avant la saisie</b> et contrôler la clé Rlmc (La saisie directe de 98 ne le permettant pas)
      </ng-template>
    </div>
    <div 
      #divSup
      *ngIf="true"
      class="col-bouton"
    >
      <app-bouton-menu
        [libelle]="'Supprimer'"
        [clsBouton]="clsBoutonSup()" 
        [clsMenu]="'dropdown-menu-haut'"
        [clsOption]="'texte-mini'"
        [mapOptions]="mapCodesSup"
        [selected]="idCodeSuppression"
        [lstDesactives]="lstIdCodeSupDesact"
        [tooltip]="'Supprimer le document du traitement'"
        [tooltipPlacement]="'bottom'"
        (select)="onCodesSupSelect($event)"
      ></app-bouton-menu>

    </div>

  </div>
</div>
