<div class="col-md-12 zones-saisies">
  <div class="row">
    <div class="col-md-6">
      <app-bouton 
        [btnClass]="clsBouton" 
        [iconClass]="'d-inline'" 
        [libelle]="'[F10] Fraudé'" 
        (clic)="onFraudeClic($event)" 
        [tooltip]="'Marquer le chèque en suspicion de fraude'"
      ></app-bouton>
    </div>
    <div class="col-md-4 champ-montant">
      <p class="libelle">Montant</p>
      <input type="text" 
        #inputSaisie
        name="saisie1" 
        class="form-control alignement" 
        autofocus
        tabindex="0"
        montantDirective
        [maxLength]="12"
        [anticipation]="atelier.anticipation"
        (yaSaisie)="yaSaisie()"
        (imageSuivante)="onImageSuivante($event)"
        (valider)="onTraiterEntree($event)"
        (echappe)="onTraiterEchappe($event)"
        (slash)="onTraiterSlash($event)"
        (f4)="onTraiterF4($event)"
        (f5)="onTraiterF5($event)"
        (f10)="onTraiterF10($event)"
        (f12)="onTraiterF12($event)"
      />
    </div>

  </div>

</div>
