import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Atelier } from '../models/Atelier.model';
import { ApiImage } from '../models/ApiImage.model';
import { FonctionsService } from './fonctions.service';
import { Logger } from '../models/Logger.model';
import { environment } from '../../environments/environment';
import { APIKEY, SITE_ID, TRAITEMENT_ID } from '../constantes';
import { ApiUrlService } from './api-url.service';
import { timeout } from 'rxjs';

/**
 * Service pour API WebIndex
 */
@Injectable({
  providedIn: 'root'
})
export class WebIndexService {
  logger: Logger = new Logger(this);
  urlFront: string = "";

  constructor(
    private httpClient: HttpClient,
    private fonctionsService: FonctionsService,
    private apiUrlService: ApiUrlService,
  ) { 
    this.urlFront = fonctionsService.urlBuild(apiUrlService.urlDomaine, "front");
  }

  [key: string]: any; // Signature pour appel methode par tableau, pour paramétrage dans Atelier

  httpOptions(siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string, exclusion: number) {
    return {
      headers: new HttpHeaders({
        'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
        'siteId': siteId,
        'traitementId': traitementId,
        'atelierId': atelier.id,
        'dateTraitement': dateTraitement,
        'exclusion': exclusion
      })
    }
  }

  /**
   * Récupération de paramètres webindex
   * @param nomParams Tableau des noms de paramètres à récupérer ou chhiane de noms séparés par #
   * @returns HttpClient
   */
  getParametres(nomParams: any) {
    this.logger.info("getParametres", nomParams);
    let headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
    })
    if(typeof nomParams === "string") {
      headers = headers.set("x-noms-param", nomParams)
    } else {
      headers = headers.set("x-noms-param", nomParams.join("#"));
    }
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront,"getParametres"), { headers })
  }
  
  getImageModifiable(siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string, id: number) {
    this.logger.info("getImageModifiable", id);
    const headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
      'siteId': siteId,
      'traitementId': traitementId,
      'dateTraitement': dateTraitement,
      'atelierId': atelier.id,
      'imageId': id,
      'option': "M",
    })
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront,"image"),  { headers })
    .pipe(
      timeout(environment.apiTimeout)
    );
  }

  imageSuivante(siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string, exclusion: number) {
    this.logger.info("imageSuivante", [siteId, traitementId, atelier, exclusion]);
    const hOptions = this.httpOptions(siteId, traitementId, atelier, dateTraitement, exclusion);
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront,"imageSuivante"), hOptions)
    .pipe(
      timeout(environment.apiTimeout)
    );
  }

  imageLiberer(siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string, imageId: number) {
    this.logger.info("imageLiberer", [atelier, imageId]);
    const headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
      'siteId': siteId,
      'traitementId': traitementId,
      'dateTraitement': dateTraitement,
      'atelierId': atelier.id,
      'imageId': imageId
    })
    return this.httpClient.put(this.fonctionsService.urlBuild(this.urlFront,"imageLiberer"), null, { headers })
  }

  /**
   * Enregistrement d'une saisie
   * @param objetAppelant Composant qui a appelé cette methode. Il doit fournir atelier, image, callBackError()
   * @returns 
   */
  enregistrer(objetAppelant: any, modeSaisie: string) {
    this.logger.info("enregistrer", objetAppelant.constructor.name);
    const hOptions = this.httpOptions(objetAppelant.siteId, objetAppelant.traitementId, objetAppelant.atelier, objetAppelant.dateTraitement, 0);
/*
    const headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
      'atelierId': objetAppelant.atelier.id
    })
    */  
    this.httpClient.post(
        this.fonctionsService.urlBuild(this.urlFront,"saisieEnregistrer"), 
        this.fonctionsService.apiImage2Form(objetAppelant.image, modeSaisie),
        hOptions
    )
    .pipe(
      timeout(environment.apiTimeout * 2)
    ).subscribe({
      error: (err) => {
        this.logger.debug("webIndexService.enregistrer res", err);
        objetAppelant.callBackEnregKo(err);
      },
      next: (res: any) => {
        this.logger.debug("webIndexService.enregistrer res", res);
        objetAppelant.callBackEnregOk(res);
      },
      complete: () => {},

    });
  }

  /**
   * Demande au back de recalculer le stock
   * @param siteId site concerné
   * @param traitementId traitement concerné
   * @param atelier atelier concerné
   * @param dateTraitement date de traitement concerné
   * @returns 
   */
  majStock(siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string) {
    this.logger.info("majStock", [siteId, traitementId, atelier, dateTraitement]);
    const hOptions = this.httpOptions(siteId, traitementId, atelier, dateTraitement, 0);
    return this.httpClient.put(this.fonctionsService.urlBuild(this.urlFront,"majStock"), null, hOptions );
  }

  /**
   * Récupère le stock d'un atelier
   * @param siteId 
   * @param traitementId 
   * @param atelier 
   * @param dateTraitement 
   * @returns 
   */
  getStock(siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string) {
    this.logger.info("getStock", [siteId, traitementId, atelier, dateTraitement]);
    const hOptions = this.httpOptions(siteId, traitementId, atelier, dateTraitement, 0);
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront,"getStock"), hOptions);
  }

  /**
   * Récupère le stock réel d'un atelier
   * @param siteId 
   * @param traitementId 
   * @param atelier 
   * @param dateTraitement 
   * @returns 
   */
  getStockReel(siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string) {
    this.logger.info("getStockReel", [siteId, traitementId, atelier, dateTraitement]);
    const hOptions = this.httpOptions(siteId, traitementId, atelier, dateTraitement, 0);
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront,"getStockReel"), hOptions);
  }

  /**
   * Appel backend non normalisé
   * @param endpoint nom du endpoint externe à appeler
   * @param siteId 
   * @param traitementId 
   * @param atelier 
   * @param dateTraitement 
   * @param mapParams Paramètres en header supplémenataire
   * @returns 
   */
  appelBackendExterne(endpoint: string, 
    siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string,
    mapParamsHeaders: Map<string, string>
  ) {
    /*
    this.logger.info("appelBackendExterne " + endpoint, [siteId, traitementId, atelier, dateTraitement]);
    const hOptions = this.httpOptions(siteId, traitementId, atelier, dateTraitement, 0);
    mapParamsHeaders.forEach((value, key) => {
      hOptions.headers = hOptions.headers.set(key, value ?? "");
    });
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront, "externe", endpoint), hOptions)
    .pipe(
      timeout(environment.apiTimeout)
    );
    */
    return this.appelBackendExterneTm(endpoint, 
      siteId, traitementId, atelier, dateTraitement,
      mapParamsHeaders,
      environment.apiTimeout
    )

  }

  /**
   * Appel backend non normalisé
   * @param endpoint nom du endpoint externe à appeler
   * @param siteId 
   * @param traitementId 
   * @param atelier 
   * @param dateTraitement 
   * @param mapParams Paramètres en header supplémenataire
   * @param timeOut Time sur le réponse
   * @returns 
   */
  appelBackendExterneTm(endpoint: string, 
    siteId: string, traitementId: string, atelier: Atelier, dateTraitement: string,
    mapParamsHeaders: Map<string, string>,
    timeOut: number
  ) {
    this.logger.info("appelBackendExterne " + endpoint, [siteId, traitementId, atelier, dateTraitement]);
    const hOptions = this.httpOptions(siteId, traitementId, atelier, dateTraitement, 0);
    mapParamsHeaders.forEach((value, key) => {
      hOptions.headers = hOptions.headers.set(key, value ?? "");
    });
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront, "externe", endpoint), hOptions)
    .pipe(
      timeout(timeOut)
    );
  }

  /**
   * Récupération des infos sur opérateur
   * @returns 
   */
  getUserInfos() {
    this.logger.info("getUserInfos");
    const headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
    });
    return this.httpClient.get(this.fonctionsService.urlBuild(this.apiUrlService.urlDomaine,"userInfos"), { headers });
  }

  /**
   * Récupère des informations sur le front déployé
   * @returns Version, front actif, back prod actif
   */
  getApiInfo() {
    return this.httpClient.get(this.fonctionsService.urlBuild(this.apiUrlService.urlDomaine, "apiInfo"));
  }

  /**
   * Verifie si opérateur est admin
   * @returns 
   */
  estAdmin() {
    const headers = new HttpHeaders({
      'x-api-key': sessionStorage.getItem(APIKEY) ?? '',
    });
    return this.httpClient.get(this.fonctionsService.urlBuild(this.urlFront,"/estAdmin"), { headers });
  }

}
