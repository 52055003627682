import { Component, ComponentRef, ElementRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { HistoriqueComponent } from '../../autres/historique/historique.component';
import { ImageStdComponent } from '../../autres/image-std/image-std.component';
import { SaisieMontantStdComponent } from '../../saisies/saisie-montant-std/saisie-montant-std.component';
import { BoutonComponent } from '../../../../bouton/bouton.component';
import { Atelier } from '../../../../../models/Atelier.model';
import { Logger } from '../../../../../models/Logger.model';
import { ApiImage } from '../../../../../models/ApiImage.model';
import { Router } from '@angular/router';
import { DialoguesService } from '../../../../dialogues/dialogues.service';
import { DynamiqueService } from '../../../../../services/dynamique.service';
import { WebIndexService } from '../../../../../services/web-index.service';
import { FonctionsService } from '../../../../../services/fonctions.service';
import { StandardSaisiesService } from '../../standard-saisies.service';
import { ATELIER_ID, IMAGE_COURANTE, IMAGE_SUIVANTE, NOM_PROJET } from '../../../../../constantes';
import { BoutonInfoModel } from '../../../../../models/BoutonInfo.model';
import { NgClass, NgIf } from '@angular/common';
import { Parametre } from '../../../../../models/Parametre.model';
import { FormsModule, NgModel } from '@angular/forms';
import { StandardTaylorimService } from '../../standard-taylorim.service';
import { ExplorerPopupComponent } from "../../autres/explorer-popup/explorer-popup.component";

@Component({
  selector: 'app-atl-taylorim',
  standalone: true,
  imports: [
    NgbTooltip,
    NgClass,
    FormsModule,
    BoutonComponent,
    ExplorerPopupComponent
],
  templateUrl: './atl-taylorim.component.html',
  styleUrl: './atl-taylorim.component.scss'
})
export class AtlTaylorimComponent {
  /** id du site choisi */
  @Input({required: true}) siteId: string = "";
  /** id du traitement choisi */
  @Input({required: true}) traitementId: string = "";
  /** id de l'atelier choisi */
  @Input({required: true}) atelier!: Atelier;
  /** Date de traitement choisi yyyyMMdd */
  @Input({required: true}) dateTraitement: string = "";   
  /** Evenement changemnet d'atelier */
  @Output() changerAtelier: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour mise à jour du stock */
  @Output() afficherStock: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour decrementer le stock */
  @Output() decStock: EventEmitter<boolean> = new EventEmitter();
  /** Evenement pour demander le stock réel restant */
  @Output() getStockReel: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('explorerPopup') explorerPopup!: ExplorerPopupComponent;

  @ViewChild('historiqueTpl', { read: ViewContainerRef, static: true})
  historiqueView!: ViewContainerRef;
  historiqueRef!: ComponentRef<any> | null;
  @ViewChild('imageTpl', { read: ViewContainerRef, static: true})
  imageView!: ViewContainerRef;
  imageRef!: ComponentRef<any> | null;
  @ViewChild('zoneSaisieTpl', { read: ViewContainerRef, static: true})
  zoneSaisieView!: ViewContainerRef;
  zoneSaisieRef!: ComponentRef<any> | null;

  clsExplorer: string = "zone-cache";

  logger: Logger = new Logger(this);
  image: ApiImage | null = null;  // Image en cours de saisie
  imageSuivante: ApiImage | null = null;  // Image suivante préchargée
  modeSaisie: string = "";  // M pour modification
  message: string = "";
  /** Compteur d'image saisi avant de lancer requete stock */
  cptSaisie: number = 0;

  /** Service chargé dynamiquement pour initialisation de l'atelier */
  atlInitService: any;
  /** Fonction de traitement d'initialisation de l'atelier */
  atlInitFn: string = "";
  /** Service chargé dynamiquement pour initialisation boutons */
  initService: any;
  /** Fonction de traitement d'initialisation des boutons */
  initFn: string = "";
  /** Service chargé dynamique pour gestion des boutons */
  boutonService: any;
  /** Fonction de traitement des clic sur boutons */
  boutonFn: string = "";
  /** Service chargé dynamiquement pour gestion enregistrement */
  enregService: any;
  /** Fonction de traitement de l'enregiqtrement de la saisie */
  enregFn: string = "";
  /** Service chargé dynamiquement pour gestion explorateur */
  explorerService: any;
  /** Fonction de traitement de l'exploration */
  explorerFn: string = "";

  /** Paramètres Talorim utilisé dans l'atelier */
  parametres: Map<string, string> = new Map();

  /** Objets Paramètres d'atelier chargé dans la fonction d'initialisation de l'atelier */
  objAtl: Map<string, any> = new Map();

  btnPhysique: boolean = true;
  /** Model pour switch */
  topPhysique: boolean = false;

  btnAtlSuivant: boolean = false;
  btnRectoVerso: boolean = false;
  btnTeteBeche: boolean = false;
  btnExplorer: boolean = false;
  faceAff: string = "";
  clsZone4: string = "";
  clsZone3: string = "";
  clsZone2: string = "";
  clsZone1: string = "";
  clsZoneRlmc: string = "";

  constructor(
    private router: Router,
    private dialoguesService: DialoguesService,
    private element: ElementRef,
    private dynamiqueService: DynamiqueService,
    private webIndexService: WebIndexService,
    private fonctionsService: FonctionsService,
    private stdSaisieService: StandardSaisiesService,
    private stdTaylorimService: StandardTaylorimService,
  ) { }

  ngOnInit() {
    this.logger.info("ngOnInit");
    // Préchargement du service init de l'atelier
    let srvFn = this.prechargerSrvFn("atelier.fonction_atl_init", this.atelier.fonctionAtlInit, "rienAFaire");
    if(!srvFn.service) {
      return;
    } else {
      this.atlInitService = srvFn.service;
      this.atlInitFn = srvFn.fonction;
    }

    // Préchargement du service init avant saisie
    srvFn = this.prechargerSrvFn("atelier.fonction_init", this.atelier.fonctionInit, "initStd");
    if(!srvFn.service) {
      return;
    } else {
      this.initService = srvFn.service;
      this.initFn = srvFn.fonction;
    }

    // Préchargement du service bouton clic
    srvFn = this.prechargerSrvFn("atelier.fonction1", this.atelier.fonction1, "rienAFaire");
    if(!srvFn.service) {
      return;
    } else {
      this.boutonService = srvFn.service;
      this.boutonFn = srvFn.fonction;
    }

    // Préchargement du service d'enregistrement
    srvFn = this.prechargerSrvFn("atelier.fonction_enreg", this.atelier.fonctionEnreg, "EnregistrerSaisie1");
    if(!srvFn.service) {
      return;
    } else {
      this.enregService = srvFn.service;
      this.enregFn = srvFn.fonction;
    }

    srvFn = this.prechargerSrvFn("atelier.fonction5", this.atelier.fonction5, "rienAFaire");
    if(!srvFn.service) {
      return;
    } else {
      this.explorerService = srvFn.service;
      this.explorerFn = srvFn.fonction;
    }

    this.historiqueRef = this.dynamiqueService.chargerComposant(this.atelier.param1, this.historiqueView);
    this.imageRef = this.dynamiqueService.chargerComposant(this.atelier.param2, this.imageView);
    this.imageRef!.instance.atelier = this.atelier;

    this.zoneSaisieRef = this.dynamiqueService.chargerComposant(this.atelier.param3, this.zoneSaisieView);
    if(this.zoneSaisieRef != null) {
      this.zoneSaisieRef.instance.siteId = this.siteId
      this.zoneSaisieRef.instance.traitementId = this.traitementId
      this.zoneSaisieRef.instance.atelier = this.atelier;      
      this.zoneSaisieRef.instance.dateTraitement = this.dateTraitement;      
    }

    if(this.atelier.param10.toLowerCase().indexOf("physique=false") > -1) {
      this.btnPhysique = false;
    } else {
      this.btnPhysique = true;
    }
    if(this.atelier.param10.toLowerCase().indexOf("atlsuivant=true") > -1) {
      this.btnAtlSuivant = true;
    } else {
      this.btnAtlSuivant = false;
    }
    if(this.atelier.param10.toLowerCase().indexOf("rectoverso=true") > -1) {
      this.btnRectoVerso = true;
    } else {
      this.btnRectoVerso = false;
    }
    if(this.atelier.param10.toLowerCase().indexOf("tetebeche=true") > -1) {
      this.btnTeteBeche = true;
    } else {
      this.btnTeteBeche = false;
    }
    if(this.btnTeteBeche) {
      if(this.atelier.param10.toLowerCase().indexOf("tetebecheOn=true") > -1) {
        this.imageRef!.instance.teteBecheOn();
      } else {
        this.imageRef!.instance.teteBecheOff();
      }
    }
    if(this.atelier.param10.toLowerCase().indexOf("explorer=true") > -1) {
      this.btnExplorer = true;
    } else {
      this.btnExplorer = false;
    }
    if(this.atelier.param10.toLowerCase().indexOf("face=") > -1) {
      this.faceAff = this.fonctionsService.extraitValeur("face", this.atelier.param10, "=", ";") ?? "";
    }

    // appel api pour recevoir les paramètres taylorim
    if(this.atelier.param10.indexOf("nomParam") > -1) {
      // Il y a des paramètres à charger
      const mParam: Map<string, string> = this.fonctionsService.str2Map(this.atelier.param10);
      let nomsParam = "";
      let sep = "";
      mParam.forEach((value, key) => {
        if(key.substring(0,8) == "nomParam") {
          let np = value.replace("#traitementid", this.traitementId);
          nomsParam += sep + value;
          sep = "|"
        }
      });
      const mph: Map<string, string> = new Map();
      mph.set("nomsParams", nomsParam);
      this.webIndexService.appelBackendExterne(
        "getParametres", 
        this.siteId, this.traitementId, this.atelier, this.dateTraitement, 
        mph
      ).subscribe({
          error: (err) => {
            this.logger.debug("webIndexService.appelBackendExterne res", err);
            this.affErreur("Il y a eu une erreur de chargements des paramètres<br>" + err.toString());
          },
          next: (res: any) => {
            this.logger.debug("webIndexService.appelBackendExterne Parametres res", res);
            res.lstParametres.forEach((p: Parametre) => {
              this.parametres.set(p.nomParam, p.valeur);
            })    
            this.objAtl.set("Parametres", this.parametres);
            this.zoneSaisieRef!.instance.setParametres(this.parametres);
            if(this.atlInitFn.length > 0) {
              this.atlInitService[this.atlInitFn](this);
            }
          },
          complete: () => { }
    
      }) ;
    } else {
      if(this.atlInitFn.length > 0) {
        this.atlInitService[this.atlInitFn](this);
      }
    }

  }

  ngAfterViewInit(): void {
    this.logger.info("ngAfterViewInit()");
    this.afficherStock.emit(true);
    this.chargerImageSuivante(0, 
      (image: ApiImage) => {
        this.logger.info("ngAfterViewInit chargerImageSuivante callback", image);
        this.image = image;
        this.image!.saisieDate = this.fonctionsService.maintenant();
        this.imageRef!.instance.image = this.image;
        this.specifiqueApresChargement(IMAGE_COURANTE);
      },
      (error: any) => {
//        this.stdSaisieService.afficherPlusdImageStd(this);
        this.stdSaisieService.afficherErreurApiStd(this, error, "Aucune image à vidéocoder");
      }
    );   
    this.historiqueRef!.instance.onElementClick.subscribe((id: number) => {
      this.saisieModifier(id);
    });

    this.zoneSaisieRef!.instance.onImageChange.subscribe((bSuivante: boolean) => {
      if(bSuivante) {
        this.changerImage(IMAGE_SUIVANTE);
      } else {
        this.imageRef!.instance.image = this.image;
      }
    });
    if(this.btnRectoVerso) {  // Demande par enfant
      if (this.zoneSaisieRef && this.zoneSaisieRef.instance && this.zoneSaisieRef.instance.onRectoVerso) {
        this.zoneSaisieRef!.instance.onRectoVerso.subscribe(() => {
          if(this.explorerPopupEstVisible()) {
            this.explorerPopup.onRectoVerso();
          } else {
            this.onRectoVersoClic(null);
          }
        });
      }
    }
    if(this.btnTeteBeche) {  // Demande par enfant
      if (this.zoneSaisieRef && this.zoneSaisieRef.instance && this.zoneSaisieRef.instance.onTeteBeche) {
        this.zoneSaisieRef!.instance.onTeteBeche.subscribe(() => {
          this.onTeteBecheClic(null);
        });
      }
    }
    this.zoneSaisieRef!.instance.onValidation.subscribe((ok: boolean) => {
      this.enregistrerSaisie();
    });
    this.zoneSaisieRef!.instance.onMessage.subscribe((message: string) => {
      this.message = message;
    });
    this.zoneSaisieRef!.instance.onEchappe.subscribe((event: string) => {
      if(this.explorerPopupEstVisible()) {
        this.explorerPopup.onFermer();
      } else {
        this.changerAtelier.emit(true);
      }
    });
    this.zoneSaisieRef!.instance.onSlash.subscribe((event: string) => {
      this.historiqueRef?.instance.clicSurDernierElement();
    });
    this.zoneSaisieRef!.instance.onError.subscribe((error: string) => {
      setTimeout( () => { this.affErreur(error); }, 0)
    });
    if (this.zoneSaisieRef && this.zoneSaisieRef.instance && this.zoneSaisieRef.instance.traiterF1) {
      this.zoneSaisieRef!.instance.traiterF1.subscribe((event: string) => {
        this.onExplorerClic();
      });
    }
    if (this.zoneSaisieRef && this.zoneSaisieRef.instance && this.zoneSaisieRef.instance.traiterF12) {
      this.zoneSaisieRef!.instance.traiterF12.subscribe((event: string) => {
        if(this.btnPhysique) {
          this.topPhysique = !this.topPhysique;
        }
      });
    }
    if (this.zoneSaisieRef && this.zoneSaisieRef.instance && this.zoneSaisieRef.instance.traiterFlecheHaut) {
      this.zoneSaisieRef!.instance.traiterFlecheHaut.subscribe((event: string) => {
        if(this.explorerPopupEstVisible()) {
          this.explorerPopup.onFlecheHaut();
        }
      });
    }
    if (this.zoneSaisieRef && this.zoneSaisieRef.instance && this.zoneSaisieRef.instance.traiterFlecheBas) {
      this.zoneSaisieRef!.instance.traiterFlecheBas.subscribe((event: string) => {
        if(this.explorerPopupEstVisible()) {
          this.explorerPopup.onFlecheBas();
        }
      });
    }
    if (this.zoneSaisieRef && this.zoneSaisieRef.instance && this.zoneSaisieRef.instance.onConfirm) {
      this.zoneSaisieRef!.instance.onConfirm.subscribe((objet: any) => {
        setTimeout( () => { this.affDlgOuiNon(objet); }, 0)
      });
    }

  }

  /**
   * Appelé si erreur dans fonction d'initialisation
   */
  atlInitKo(msg: string) {
    this.affErreur(msg);
  }

  /**
   * Changement de l'état du top physique dans Saisie10
   */
  changementTopPhysique() {
    let map = this.fonctionsService.str2Map(this.image!.saisie10);
    map.set("physique", this.topPhysique.toString());
    this.image!.saisie10 = this.fonctionsService.map2Str(map);
    if(this.topPhysique) {
      this.enregistrerSaisie();
    }
    setTimeout(() => {
      this.zoneSaisieRef?.instance.focus();  
    }, 0);

  }

  /**
   * Clic sur bouton atelier suivant
   * @param event 
   */
  onAtlSuivantClic(event: any) {
    this.zoneSaisieRef!.instance.saisie10 = "atlSuivant"
    this.enregistrerSaisie();
  }

  /**
   * Clic sur bouton rect verso
   * @param event 
   */
  onRectoVersoClic(event: any) {
    // On ne changement pas le comportement avec le clic 
    // pour garder la possibilité d'afficher le verso même quand le popup Explorer est ouverte
    if(this.faceAff == "" || this.faceAff == "r") {
      this.faceAff = "v";
    } else {
      this.faceAff = "r";
    }
    this.stdTaylorimService.getImageFace(
      this,
      this.image!.id, 
      this.faceAff,
      this.changerImageFace
    ) 
    this.zoneSaisieRef!.instance.focus();
  }

  /**
   * Clic sur bouton Tete beche
   * @param event 
   */
  onTeteBecheClic(event: any) {
    this.imageRef!.instance.teteBeche();
    this.zoneSaisieRef!.instance.focus();
  }

  /**
   * Clic sur bouton explorer
   */
  onExplorerClic() {
    if(!this.explorerPopupEstVisible()) {
      this.clsExplorer = "";
      this.explorerPopup.ouvrir(this.image!.id);
    } else {
      this.explorerPopupFermer();
    }
    this.zoneSaisieRef!.instance.focus();
  }

  /**
   * Demande de fermeture par bouton popup
   */
  explorerPopupFermer() {
    this.clsExplorer = "zone-cache";
    this.zoneSaisieRef!.instance.focus();
  }

  /**
   * Callback de demande de changement d'image affichée
   * @param imgBase64 
   */
  changerImageFace(objAtl: AtlTaylorimComponent, imgBase64: string) {
    objAtl.imageRef!.instance.changerImage(imgBase64);
  }

  /**
   * Clic sur bouton Image suivante
   * @param event 
   */
  clicSuivante(event: MouseEvent) {
    this.stdSaisieService.imgSuivOnClicStd(this, event);
  }

  /**
   * Demande de la prochaine image libre
   * @param callBackImageOk Callback de traiement de l'image reçue
   */
  chargerImageSuivante(exclusion: number, callBackImageOk: Function, callBackImageKo: Function) {
    this.stdSaisieService.chargerImageSuivanteStd(this, exclusion, callBackImageOk, callBackImageKo);
  }

  /**
   * Changement de l'image
   * @param mode 0 = image courante, 1 = imageSuivante, 3 = abandon courante
   */
  changerImage(mode: number) {
    this.stdSaisieService.changerImageStd(this, mode);
  }

  /**
   * Enregistrement de la saisie
   */
  enregistrerSaisie() {
    this.logger.info("enregistrerSaisie");
    this.message = this.enregService[this.enregFn](this);
    this.cptSaisie ++;
    if(this.cptSaisie >= 10) {  // Rafraichissement du stock
      this.getStockReel.emit(true);
      this.cptSaisie = 0;
    }
  }

  /**
   * Callback appelé quand l'enregistrement échoue
   * @param err Callback en cas d'enregistrement ko
   */
  callBackEnregKo(err: any) {
    this.stdSaisieService.callBackEnregKoStd(this, err);
  }

  /**
   * Callback appelé quand l'enregistrement réussi
   * @param image 
   */
  callBackEnregOk(image: ApiImage) {  
    this.stdSaisieService.callBackEnregOkStd(this);
  }

  /**
   * Gestion d'une demande de modification d'une image saisie
   * @param id id de l'image à modifier
   */
  saisieModifier(id: number) {
    this.stdSaisieService.saisieModifierStd(this, id);
  }
  /** 
   * Action après libération de l'image préchargée
   * @param id id de l'image à modifier
  */
  saisieModifier2(id: number) {
    this.stdSaisieService.saisieModifier2Std(this, id);
  }

  /**
   * Action après libération de l'image en cours
   * @param id id de l'image à modifier
   */
  saisieModifier3(id: number) {
    this.stdSaisieService.saisieModifier3Std(this, id);
  }

  /**
   * Libération des images chargées
   */
  imagesLiberer() {
    this.stdSaisieService.imagesLibererStd(this);
  }

  /**
   * Calcule la visibilité du popup d'exploration
   * @returns 
   */
  explorerPopupEstVisible(): boolean {
    return this.clsExplorer.length == 0;
  }

  /**
   * Action après chargment de l'image: Maj boutons
   */
  specifiqueApresChargement(mode: number) {
    let map = this.fonctionsService.str2Map(this.image!.saisie10);
    let phys = map.get("physique");
    if(phys == undefined) {
      this.topPhysique = false;
    } else {
      if(phys.toLowerCase() === "false") {
        this.topPhysique = false;
      } else {
        this.topPhysique = true;
      }
    }
    try {
      this.initService[this.initFn](this, mode);      
    } catch(error) {
      this.affErreur("Il y a un choucis dans: " + this.initService.constructor.name + "." + this.initFn + "<br>" + error);
    }
  }

  /**
   * Calcul de la classe du bouton physique (visibilité)
   * @returns 
   */
  clsBtnPhysique() {
    return this.clsVisibilite(this.btnPhysique);
  }

  /**
   * Calcul de la classe du bouton AtlSuivant (visibilité)
   * @returns 
   */
  clsBtnAtlSuivant() {
    return this.clsVisibilite(this.btnAtlSuivant);
  }

  /**
   * Calcul de la classe du bouton RectoVerso (visibilité)
   * @returns 
   */
  clsBtnRectoVerso() {
    return this.clsVisibilite(this.btnRectoVerso);
  }

  /**
   * Calcul de la classe du bouton TeteBeche (visibilité)
   * @returns 
   */
  clsBtnTeteBeche() {
    return this.clsVisibilite(this.btnTeteBeche);
  }

  /**
   * Calcul de la classe du bouton Explorer (visibilité)
   * @returns 
   */
  clsBtnExplorer() {
    return this.clsVisibilite(this.btnExplorer);
  }

  /**
   * Calcule la classe de visibilité en fonction d'un top
   * @param top 
   * @returns 
   */
  clsVisibilite(top: boolean): string {
    if(top) {
      return "";
    } else {
      return " btn-cache";
    }
  }

/**
 * Préchargement des services et fonctions
 * @param srvFnNom Nom du paramètre pour affichage dans erreur
 * @param srvFn Paramètre UnService.UneFonction
 * @param fnDefaut Nom d'une fonction par défaut
 * @returns 
 */
  prechargerSrvFn(srvFnNom: string, srvFn: string, fnDefaut: string) {
    const { service: service, fonction: fonction } = this.fonctionsService.litParametreDeService(srvFn, fnDefaut);
    const res = this.fonctionsService.testServiceFonction(
        srvFnNom + "=" + srvFn,
        service, fonction
    );
    if(!res.res) {
      setTimeout(() => { 
        this.affErreur(res.err)
      }, 0 );
      return {
        service: null,
        fonction: ""
      };
    }
    return { 
      service: service,
      fonction: fonction
    }
  }

  /**
   * Affiche une erreur dans une boite de dialogue
   * @param erreur 
   */
  affErreur(erreur: string) {
    this.dialoguesService.messageBox(
      NOM_PROJET,
      "Oups ! Houston, on a un problème: <br>" + erreur,
      () => { 
        this.imagesLiberer();
        this.router.navigate(["choix"]);    
      },
      -1
    );
  }

  /**
   * Affichage d'une fenêtre de confirmation demandée par enfant
   * @param objet avec titre, message, defaut, callBackOui, callBackNon, delai
   */
  affDlgOuiNon(objet: any) {
    setTimeout( () => {
    this.dialoguesService.dialogBoxOuiNon(
      objet.titre,
      objet.message,
      objet.defaut,
      (event: any, dlg: NgbModalRef) => {
        switch (event)  {
          case "close":
            new Promise<void>((resolve, reject) => {
              objet.callBackNon();
              resolve();
            }).then(() => {
              dlg.dismiss();
            })
            break;
          case "action1":
            new Promise<void>((resolve, reject) => {
              objet.callBackOui();
              resolve();
            }).then(() => {
              dlg.dismiss();
            })
            break;
          case "action2":
            new Promise<void>((resolve, reject) => {
              objet.callBackNon();
              resolve();
            }).then(() => {
              dlg.dismiss();
            })
            break;
        }
      },
      objet.delai
    )
    }, 150)   // délai long pour laiiser le temps de vider les touches
  }

}
