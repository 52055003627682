import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, HostListener, inject, Input, Optional, Output } from '@angular/core';
import { Logger } from '../../../models/Logger.model';
import { FonctionsService } from '../../../services/fonctions.service';
import { InputMaskDirectiveService } from './input-mask-directive.service';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[inputMaskDirective]',
  standalone: true
})
export class InputMaskDirective implements AfterViewInit {
  inputMaskDirService = inject(InputMaskDirectiveService, {optional: true});

  @Input() anticipation: number = -1; // Nb de caractère avant chargement img suivante // TODO
  @Input() maxLength: number = 99; // valeur saisie
  @Input() majuscules: number = 0; // 0 = Pas de conversion, 1 = Minuscules, 2 = Majuscules 

  //@Output() yaSaisie: EventEmitter<boolean> = new EventEmitter();
  @Output() imageSuivante: EventEmitter<boolean> = new EventEmitter();
  @Output() valider: EventEmitter<string> = new EventEmitter();
  @Output() echappe: EventEmitter<string> = new EventEmitter();
  @Output() flecheHaut: EventEmitter<string> = new EventEmitter();
  @Output() flecheBas: EventEmitter<string> = new EventEmitter();
  @Output() slash: EventEmitter<string> = new EventEmitter();
  @Output() tabulation: EventEmitter<string> = new EventEmitter();
  @Output() espace: EventEmitter<string> = new EventEmitter();
  @Output() f1: EventEmitter<string> = new EventEmitter();
  @Output() f2: EventEmitter<string> = new EventEmitter();
  @Output() f3: EventEmitter<string> = new EventEmitter();
  @Output() f4: EventEmitter<string> = new EventEmitter();
  @Output() f5: EventEmitter<string> = new EventEmitter();
  @Output() f6: EventEmitter<string> = new EventEmitter();
  @Output() f7: EventEmitter<string> = new EventEmitter();
  @Output() f8: EventEmitter<string> = new EventEmitter();
  @Output() f9: EventEmitter<string> = new EventEmitter();
  @Output() f10: EventEmitter<string> = new EventEmitter();
  @Output() f11: EventEmitter<string> = new EventEmitter();
  @Output() f12: EventEmitter<string> = new EventEmitter();
  @Output() plus: EventEmitter<string> = new EventEmitter();

  toucheEspace: boolean = false;
  toucheF1: boolean = false;
  toucheF2: boolean = false;
  toucheF3: boolean = false;
  toucheF4: boolean = false;
  toucheF5: boolean = false;
  toucheF6: boolean = false;
  toucheF7: boolean = false;
  toucheF8: boolean = false;
  toucheF9: boolean = false;
  toucheF10: boolean = false;
  toucheF11: boolean = false;
  toucheF12: boolean = false;
  touchePlus: boolean = false;

  logger: Logger = new Logger(this);
  constructor(
    @Host() private ngModel: NgModel,
    private elementRef: ElementRef,
    private fonctionsService: FonctionsService
  ) {
    if(this.inputMaskDirService) {  // Enregistrement de la directive dans le service qui l'expose
      this.inputMaskDirService.setInputMaskDir(this);
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.espace.observed) {
      this.toucheEspace = true;
    }
    if (this.f1.observed) {
      this.toucheF1 = true;
    }
    if (this.f2.observed) {
      this.toucheF2 = true;
    }
    if (this.f3.observed) {
      this.toucheF3 = true;
    }
    if (this.f4.observed) {
      this.toucheF4 = true;
    }
    if (this.f5.observed) {
      this.toucheF5 = true;
    }
    if (this.f6.observed) {
      this.toucheF6 = true;
    }
    if (this.f7.observed) {
      this.toucheF7 = true;
    }
    if (this.f8.observed) {
      this.toucheF8 = true;
    }
    if (this.f9.observed) {
      this.toucheF9 = true;
    }
    if (this.f10.observed) {
      this.toucheF10 = true;
    }
    if (this.f11.observed) {
      this.toucheF11 = true;
    }
    if (this.f12.observed) {
      this.toucheF12 = true;
    }      
    if (this.plus.observed) {
      this.touchePlus = true;
    }      
  }

  @HostListener('keydown', ['$event']) 
  onkeyDown(event: KeyboardEvent) {
    this.logger.info("onkeyDown", event);
    if(event.key == "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.traiterEnter(event);
    } else if(event.key == "Escape") {
      event.preventDefault();
      event.stopPropagation();
      this.traiterEchap(event);
    } else if(event.key == "ArrowUp") {
      event.preventDefault();
      event.stopPropagation();
      this.traiterHaut(event);
    } else if(event.key == "ArrowDown") {
      event.preventDefault();
      event.stopPropagation();
      this.traiterBas(event);
    } else if(event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      this.traiterTab(event);
    } else if(event.code === 'NumpadDivide') {
      event.preventDefault();
      event.stopPropagation();
      this.traiterSlash(event);
    } else if(event.code === 'NumpadAdd' && this.touchePlus) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterPlus(event);
    } else if(event.key === ' ' && this.toucheEspace) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterEspace(event);
    } else if(event.key === 'F1' && this.toucheF1) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF1(event);
    } else if(event.key === 'F2' && this.toucheF2) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF2(event);
    } else if(event.key === 'F3' && this.toucheF3) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF3(event);
    } else if(event.key === 'F4' && this.toucheF4) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF4(event);
    } else if(event.key === 'F5' && this.toucheF5) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF5(event);
    } else if(event.key === 'F6' && this.toucheF6) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF6(event);
    } else if(event.key === 'F7' && this.toucheF7) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF7(event);
    } else if(event.key === 'F8' && this.toucheF8) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF8(event);
    } else if(event.key === 'F9' && this.toucheF9) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF9(event);
    } else if(event.key === 'F10' && this.toucheF10) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF10(event);
    } else if(event.key === 'F11' && this.toucheF11) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF11(event);
    } else if(event.key === 'F12' && this.toucheF12) {
      event.preventDefault();
      event.stopPropagation();
      this.traiterF12(event);
    }
  
  }

  @HostListener('keypress', ['$event']) 
  onKeyPress(event: KeyboardEvent) {
    this.logger.info("onKeyPress", event);
    if(event.key == "*" && event.code.startsWith('Numpad') ) {  
      this.ngModel.control.setValue("");
      this.elementRef.nativeElement.value = "";
      event.preventDefault();
      event.stopPropagation();
      this.imageSuivante.emit(false);
    } else {
    }
  }

  @HostListener('keyup', ['$event']) 
  onkeyUp(event: KeyboardEvent) {
    this.logger.info("onkeyUp", event);

    if(event.key.length === 1 && this.ngModel.model && this.ngModel.model.length == this.maxLength) {
      this.logger.info("traiterEnterAuto", event);
      this.traiterEnter(event);
    }
  }

  /**
   * Traitement de la touche Echap
   * @param event 
   */
  traiterEchap(event: KeyboardEvent) {
    this.logger.info("traiterEchap", event);
    event.preventDefault();
    event.stopPropagation();
    this.echappe.emit("");
  }

  /**
   * Traitement de la touche Entrée
   * @param event 
   */
  traiterEnter(event: KeyboardEvent) {
    this.logger.info("traiterEnter", event);
    this.valider.emit(this.ngModel.model);
  }

  /**
   * Traitement de la touche Flèche haut
   * @param event 
   */
  traiterHaut(event: KeyboardEvent) {
    this.logger.info("traiterHaut", event);
    event.preventDefault();
    event.stopPropagation();
    this.flecheHaut.emit("");
  }

  /**
   * Traitement de la touche Echap
   * @param event 
   */
  traiterBas(event: KeyboardEvent) {
    this.logger.info("traiterBas", event);
    event.preventDefault();
    event.stopPropagation();
    this.flecheBas.emit("");
  }

  /**
   * Traitement de la touche /
   * @param event 
   */
  traiterSlash(event: KeyboardEvent) {
    this.logger.info("traiterSlash", event);
    event.preventDefault();
    event.stopPropagation();
    this.slash.emit("");
  }

  /**
   * Traitement de tabulation
   * @param event 
   */
  traiterTab(event: KeyboardEvent) {
    this.logger.info("traiterTab", event);
    event.preventDefault();
    event.stopPropagation();
    this.tabulation.emit("");
  }

  /**
   * Traitement de l'espace
   * @param event 
   */
  traiterEspace(event: KeyboardEvent) {
    if(this.toucheEspace) {
      this.logger.info("traiterEspace", event);
      event.preventDefault();
      event.stopPropagation();
      this.espace.emit("");
    }
  }

  /**
   * Mise à jour f1.observed en cas de late binding
   * @param valeur
   */
  f1Observed(valeur: boolean) {
    this.toucheF1 = true;
  }

  /**
   * Mise à jour f2.observed en cas de late binding
   * @param valeur
   */
  f2Observed(valeur: boolean) {
    this.toucheF2 = true;
  }

  /**
   * Mise à jour f3.observed en cas de late binding
   * @param valeur
   */
  f3Observed(valeur: boolean) {
    this.toucheF3 = true;
  }

  /**
   * Mise à jour f4.observed en cas de late binding
   * @param valeur
   */
  f4Observed(valeur: boolean) {
    this.toucheF4 = true;
  }

  /**
   * Mise à jour f5.observed en cas de late binding
   * @param valeur
   */
  f5Observed(valeur: boolean) {
    this.toucheF5 = true;
  }

  /**
   * Mise à jour f5.observed en cas de late binding
   * @param valeur
   */
  f5bserved(valeur: boolean) {
    this.toucheF5 = true;
  }

  /**
   * Mise à jour f6.observed en cas de late binding
   * @param valeur
   */
  f6Observed(valeur: boolean) {
    this.toucheF6 = true;
  }

  /**
   * Mise à jour f7.observed en cas de late binding
   * @param valeur
   */
  f7Observed(valeur: boolean) {
    this.toucheF7 = true;
  }

  /**
   * Mise à jour f8.observed en cas de late binding
   * @param valeur
   */
  f8Observed(valeur: boolean) {
    this.toucheF8 = true;
  }

  /**
   * Mise à jour f9.observed en cas de late binding
   * @param valeur
   */
  f9Observed(valeur: boolean) {
    this.toucheF9 = true;
  }

  /**
   * Mise à jour f10.observed en cas de late binding
   * @param valeur
   */
  f10Observed(valeur: boolean) {
    this.toucheF10 = true;
  }

  /**
   * Mise à jour f11.observed en cas de late binding
   * @param valeur
   */
  f11Observed(valeur: boolean) {
    this.toucheF11 = true;
  }

  /**
   * Mise à jour f12.observed en cas de late binding
   * @param valeur
   */
  f12Observed(valeur: boolean) {
    this.toucheF12 = true;
  }

  /**
   * Mise à jour plus.observed en cas de late binding
   * @param valeur
   */
  plusObserved(valeur: boolean) {
    this.touchePlus = true;
  }

  /**
   * Traitement de F1
   * @param event 
   */
  traiterF1(event: KeyboardEvent) {
    if(this.toucheF1) {
      this.logger.info("traiterF1", event);
      event.preventDefault();
      event.stopPropagation();
      this.f1.emit("");
    }
  }

  /**
   * Traitement de F2
   * @param event 
   */
  traiterF2(event: KeyboardEvent) {
    if(this.toucheF2) {
      this.logger.info("traiterF2", event);
      event.preventDefault();
      event.stopPropagation();
      this.f2.emit("");
    }
  }

  /**
   * Traitement de F3
   * @param event 
   */
  traiterF3(event: KeyboardEvent) {
    if(this.toucheF3) {
      this.logger.info("traiterF3", event);
      event.preventDefault();
      event.stopPropagation();
      this.f3.emit("");
    }
  }

  /**
   * Traitement de F4
   * @param event 
   */
  traiterF4(event: KeyboardEvent) {
    if(this.toucheF4) {
      this.logger.info("traiterF4", event);
      event.preventDefault();
      event.stopPropagation();
      this.f4.emit("");
    }
  }

  /**
   * Traitement de F5
   * @param event 
   */
  traiterF5(event: KeyboardEvent) {
    if(this.toucheF5) {
      this.logger.info("traiterF5", event);
      event.preventDefault();
      event.stopPropagation();
      this.f5.emit("");
    }
  }

  /**
   * Traitement de F6
   * @param event 
   */
  traiterF6(event: KeyboardEvent) {
    if(this.toucheF6) {
      this.logger.info("traiterF6", event);
      event.preventDefault();
      event.stopPropagation();
      this.f6.emit("");
    }
  }

  /**
   * Traitement de F7
   * @param event 
   */
  traiterF7(event: KeyboardEvent) {
    if(this.toucheF7) {
      this.logger.info("traiterF7", event);
      event.preventDefault();
      event.stopPropagation();
      this.f7.emit("");
    }
  }

  /**
   * Traitement de F8
   * @param event 
   */
  traiterF8(event: KeyboardEvent) {
    if(this.toucheF8) {
      this.logger.info("traiterF8", event);
      event.preventDefault();
      event.stopPropagation();
      this.f8.emit("");
    }
  }

  /**
   * Traitement de F9
   * @param event 
   */
  traiterF9(event: KeyboardEvent) {
    if(this.toucheF9) {
      this.logger.info("traiterF9", event);
      event.preventDefault();
      event.stopPropagation();
      this.f9.emit("");
    }
  }

  /**
   * Traitement de F10
   * @param event 
   */
  traiterF10(event: KeyboardEvent) {
    if(this.toucheF10) {
      this.logger.info("traiterF10", event);
      event.preventDefault();
      event.stopPropagation();
      this.f10.emit("");
    }
  }

  /**
   * Traitement de F11
   * @param event 
   */
  traiterF11(event: KeyboardEvent) {
    if(this.toucheF11) {
      this.logger.info("traiterF11", event);
      event.preventDefault();
      event.stopPropagation();
      this.f11.emit("");
    }
  }

  /**
   * Traitement de F12
   * @param event 
   */
  traiterF12(event: KeyboardEvent) {
    if(this.toucheF12) {
      this.logger.info("traiterF12", event);
      event.preventDefault();
      event.stopPropagation();
      this.f12.emit("");
    }
  }

  /**
   * Traitement de plus numpad
   * @param event 
   */
  traiterPlus(event: KeyboardEvent) {
    if(this.touchePlus) {
      this.logger.info("traiterPlus", event);
      event.preventDefault();
      event.stopPropagation();
      this.plus.emit("");
    }
  }

  /**
   * Expose l'élément html
   */
  public get nativeElement() {
    return this.elementRef.nativeElement;
  }

  /**
   * Réinitialisation du input contenant la directive
   */
  razValeur() {
    this.ngModel.control.setValue("");
  }


}
